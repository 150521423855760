import { memo } from 'react';
import { Marker } from 'react-leaflet';
import type { LatLngTuple } from 'leaflet';
import { useAppStore } from '../../store/useAppStore';
import { getGeocodingIcon } from './markers';

interface GeocodingMarkersProps {
  searchMarkerPosition: LatLngTuple | null;
}

// Type guard to ensure we have exactly 2 coordinates
const isValidLatLngTuple = (pos: LatLngTuple): pos is [number, number] => {
  return pos.length === 2;
};

const geocodingIcon = getGeocodingIcon();

export const GeocodingMarkers = memo(({ searchMarkerPosition }: GeocodingMarkersProps) => {
  const geocodingLocation = useAppStore(state => state.geocodingLocation);
  const activeModule = useAppStore(state => state.activeModule);

  // Only show marker when in geocoding mode and we have a position
  if (activeModule !== null || !searchMarkerPosition || !geocodingLocation || !isValidLatLngTuple(searchMarkerPosition)) {
    return null;
  }

  return (
    <Marker
      position={searchMarkerPosition}
      icon={geocodingIcon}
      title={geocodingLocation.place}
    />
  );
});

GeocodingMarkers.displayName = 'GeocodingMarkers';
