/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Result<T = any> {
  status: number;
  data?: T;
}

export interface ApiError {
  code: string;
  message: string;
}

export enum ResultEnum {
  SUCCESS = 200,
  ERROR = -1,
  TIMEOUT = 401,
}

export enum StorageEnum {
  User = 'user',
  Token = 'token',
  Settings = 'settings',
  I18N = 'i18nextLng',
}