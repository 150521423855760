import { memo, useState, useCallback } from "react";
import { Icon } from "@iconify/react";
import { TruckProfileModal } from "./TruckProfileModal";
import { TransportMode, TruckSpecFormData } from "../routingUtilities/types";

const transportIcons: Record<TransportMode, { icon: string; tooltip: string }> = {
  CAR: {
    icon: "mdi:car",
    tooltip: "Voiture"
  },
  TRUCK: {
    icon: "mdi:truck",
    tooltip: "Camion"
  },
  PUBLIC_BUS: {
    icon: "mdi:bus",
    tooltip: "Bus"
  },
  TAXI: {
    icon: "mdi:taxi",
    tooltip: "Taxi"
  },
  BICYCLE: {
    icon: "mdi:bicycle",
    tooltip: "Vélo"
  }
};

// Default truck profile values (same as in TruckProfileModal)
const defaultTruckProfile: TruckSpecFormData = {
  height: 400,  // cm
  width: 255,   // cm
  length: 1200, // cm
  weight: 3.5,  // t
  maxSpeed: 90  // km/h
};

interface TransportOptionsProps {
  selectedMode: TransportMode;
  onModeChange: (mode: TransportMode) => void;
  truckProfile: TruckSpecFormData | null;
  onTruckProfileChange: (profile: TruckSpecFormData | null) => void;
}

export const TransportOptions = memo(function TransportOptions({
  selectedMode,
  onModeChange,
  truckProfile,
  onTruckProfileChange
}: TransportOptionsProps) {
  const [showTruckProfile, setShowTruckProfile] = useState(false);

  const handleModeChange = useCallback((mode: TransportMode) => {
    onModeChange(mode);
    // Don't reset truck profile when switching away from truck mode
    // We want to keep it for when user switches back to TRUCK
  }, [onModeChange]);

  const handleTruckProfileClose = useCallback(() => {
    setShowTruckProfile(false);
    // Don't change the mode when closing the modal
  }, []);

  const handleTruckProfileSave = useCallback((profile: TruckSpecFormData | null) => {
    onTruckProfileChange(profile);
    setShowTruckProfile(false);
  }, [onTruckProfileChange]);

  // Get the current truck profile to display (either saved or default)
  const currentTruckProfile = truckProfile || defaultTruckProfile;

  return (
    <div>
      <h4 className="text-xs font-medium text-gray-500 mb-2">Mode de transport</h4>
      <div className="flex gap-2">
        {(Object.entries(transportIcons) as [TransportMode, { icon: string; tooltip: string }][]).map(
          ([mode, { icon, tooltip }]) => (
            <div key={mode}>
              {mode === 'TRUCK' ? (
                <div className="flex gap-1">
                  <button
                    title={tooltip}
                    onClick={() => handleModeChange(mode)}
                    className={`p-3 rounded-md transition-all hover:scale-110 ${
                      selectedMode === mode
                        ? "bg-blue-600 text-white shadow-md shadow-blue-500/20"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    }`}
                  >
                    <Icon icon={icon} className="text-2xl" />
                  </button>
                  {selectedMode === 'TRUCK' && (
                    <button
                      onClick={() => setShowTruckProfile(true)}
                      className="p-2 rounded-md transition-all hover:scale-110 bg-gray-100 text-gray-700 hover:bg-gray-200"
                      title={`Hauteur: ${currentTruckProfile.height}cm, Largeur: ${currentTruckProfile.width}cm, Longueur: ${currentTruckProfile.length}cm, Poids: ${currentTruckProfile.weight}t${currentTruckProfile.maxSpeed ? `, Vitesse: ${currentTruckProfile.maxSpeed}km/h` : ''}`}
                    >
                      <Icon icon="mdi:cog" className="text-lg" />
                    </button>
                  )}
                </div>
              ) : (
                <button
                  title={tooltip}
                  onClick={() => handleModeChange(mode)}
                  className={`p-3 rounded-md transition-all hover:scale-110 ${
                    selectedMode === mode
                      ? "bg-blue-600 text-white shadow-md shadow-blue-500/20"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                >
                  <Icon icon={icon} className="text-2xl" />
                </button>
              )}
            </div>
          )
        )}
      </div>

      {showTruckProfile && (
        <TruckProfileModal
          initialProfile={truckProfile}
          onSave={handleTruckProfileSave}
          onClose={handleTruckProfileClose}
        />
      )}
    </div>
  );
});
