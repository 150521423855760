import { memo, useCallback } from 'react';
import { useRouteStore } from '../../../store/useRouteStore';
import { DraggablePoint } from '../drag/DraggablePoint';
import type { AutocompleteElem } from '../../../services/geocoding-autocomplete/response-types';
import type { Coordinate } from '../../../services/common/types';

interface ViaPointsProps {
  mapCenter: Coordinate;
  isCalculating?: boolean;
  onValidationError?: (message: string) => void;
}

export const ViaPoints = memo(({ mapCenter, isCalculating = false, onValidationError }: ViaPointsProps) => {
  const {
    getViaPoints,
    setPointLocation,
    removePoint
  } = useRouteStore();

  const viaPoints = getViaPoints();

  const handleLocationSelect = useCallback((id: string, location: AutocompleteElem | null) => {
    if (!location) {
      // When clearing a location:
      // 1. First set the location to null (triggers status update)
      // 2. Then if it was an empty via point, remove it
      setPointLocation(id, null);
      const point = viaPoints.find(p => p.id === id);
      if (point?.type === 'via' && point.status === 'empty') {
        removePoint(id);
      }
    } else {
      setPointLocation(id, location);
    }
  }, [setPointLocation, removePoint, viaPoints]);

  const handleDelete = useCallback((id: string) => {
    // Always allow removing via points
    removePoint(id);
  }, [removePoint]);

  const handleBlur = useCallback((point: AutocompleteElem | null) => {
    if (point && !point.coordinate) {
      onValidationError?.('Please select valid locations for all points');
    }
  }, [onValidationError]);

  if (!viaPoints.length) return null;

  return (
    <div className="space-y-4">
      {viaPoints.map((point, idx) => (
        <DraggablePoint
          key={point.id}
          point={point}
          pointText="V"
          label=""
          onLocationSelect={(location) => handleLocationSelect(point.id, location)}
          onDelete={() => handleDelete(point.id)}
          onBlur={() => handleBlur(point.location)}
          mapCenter={mapCenter}
          pointNumber={idx + 1}
          isCalculating={isCalculating}
        />
      ))}
    </div>
  );
});

ViaPoints.displayName = 'ViaPoints';
