import { create } from 'zustand';
import type { AutocompleteElem } from '../services/geocoding-autocomplete/response-types';
import type { Coordinate } from '../services/common/types';
import type { PointType, RouteConfig, RoutePoint, RouteResults, TruckSpecFormData } from '../components/routing/routingUtilities/types';
import { POINT_COLORS } from '../components/routing/routingUtilities/colors';

// Helper functions
const getPointColor = (type: PointType, order: number): string =>
{
  if (type === 'start') return POINT_COLORS.start;
  if (type === 'end') return POINT_COLORS.end;
  const viaIndex = (order - 1) % POINT_COLORS.via.length;
  return POINT_COLORS.via[viaIndex];
};

const updatePointOnReorder = (point: RoutePoint, newOrder: number, totalPoints: number): RoutePoint =>
{
  let newType: PointType = point.type;
  let newId = point.id;

  if (newOrder === 0)
  {
    newType = 'start';
    newId = 'start';  // Ensure ID matches type for start point
  }
  else if (newOrder === totalPoints - 1)
  {
    newType = 'end';
    newId = 'end';    // Ensure ID matches type for end point
  }
  else
  {
    newType = 'via';
    // If this point was previously start or end, give it a new via ID
    if (point.type === 'start' || point.type === 'end')
    {
      newId = `via-${Date.now()}-${newOrder}`;
    }
  }

  return {
    ...point,
    id: newId,
    type: newType,
    order: newOrder,
    color: getPointColor(newType, newOrder)
  };
};

interface RouteState
{
  points: RoutePoint[];
  routeConfig: RouteConfig;
  routeResults: RouteResults | null;
  selectedLocation: AutocompleteElem | null;
  isSimulating: boolean;
  isFormVisible: boolean;
  isResultsVisible: boolean;
  isCalculating: boolean;
  isOptionsExpanded: boolean;
  isWaypointsExpanded: boolean;
  setIsCalculating: (value: boolean) => void;
  handleComputeRoute?: () => void;
  playSimulation: () => void;
  stopSimulation: () => void;
  toggleOptionsExpanded: () => void;
  toggleWaypointsExpanded: () => void;

  // Point operations
  getStartPoint: () => RoutePoint | undefined;
  getEndPoint: () => RoutePoint | undefined;
  getViaPoints: () => RoutePoint[];
  setPointLocation: (id: string, location: AutocompleteElem | null) => void;
  addViaPoint: () => void;
  removePoint: (id: string) => void;
  reorderPoints: (orderedIds: string[]) => void;
  setRouteConfig: (config: Partial<RouteConfig>) => void;
  setSelectedLocation: (location: AutocompleteElem | null) => void;
  setRouteResults: (results: RouteResults | null) => void;
  setSelectedPolyline: (polyline: Coordinate[] | undefined) => void;
  hasEmptyPoints: () => boolean;
  canSimulate: () => boolean;
  setHandleComputeRoute: (computeFunction: (() => void) | undefined) => void;
  toggleFormVisibility: () => void;
  toggleResultsVisibility: () => void;
  reset: () => void;
}

const initialRouteConfig: RouteConfig = {
  isReturnTrip: false,
  transportMode: 'CAR',
  routingMethod: 'FASTEST',
  optimizationType: 'none',
  trafficMode: 'none',
  trafficHistoryDate: null,
  truckProfile: null as TruckSpecFormData | null,
  showRoutesheet: true,
  avoidFeatures: {
    tolls: false,
    motorways: false,
    ferries: false,
    unpavedRoads: false,
    borderCrossings: false
  }
};

const initialPoints: RoutePoint[] = [
  {
    id: 'start',
    type: 'start',
    status: 'empty',
    location: null,
    order: 0,
    color: POINT_COLORS.start,
    address: null,
    lat: null,
    lng: null
  },
  {
    id: 'end',
    type: 'end',
    status: 'empty',
    location: null,
    order: 1,
    color: POINT_COLORS.end,
    address: null,
    lat: null,
    lng: null
  }
];

export const useRouteStore = create<RouteState>((set, get) => ({
  selectedLocation: null,
  points: initialPoints,
  routeConfig: initialRouteConfig,
  routeResults: null,
  isSimulating: false,
  isCalculating: false,
  isFormVisible: true,
  isResultsVisible: true,
  isOptionsExpanded: false,
  isWaypointsExpanded: false,
  handleComputeRoute: undefined,

  getStartPoint: () => get().points.find(p => p.type === 'start'),
  getEndPoint: () => get().points.find(p => p.type === 'end'),
  getViaPoints: () => get().points.filter(p => p.type === 'via'),
  hasEmptyPoints: () => get().points.some(p => p.status === 'empty'),
  canSimulate: () => get().routeResults?.selectedPolyline !== undefined,

  playSimulation: () =>
    set(state =>
    {
      if (!state.routeResults?.selectedPolyline) return state;
      return { ...state, isSimulating: true };
    }),
  setIsCalculating: (value: boolean) =>
    set(state => ({
      ...state,
      isCalculating: value
    })),

  stopSimulation: () =>
    set(state => ({ ...state, isSimulating: false })),

  setPointLocation: (id, location) =>
    set(state => ({
      ...state,
      points: state.points.map(point =>
        point.id === id ? {
          ...point,
          location,
          status: location ? 'set' : 'empty',
          address: location?.place || null,
          lat: location?.coordinate?.latitude || null,
          lng: location?.coordinate?.longitude || null
        } : point
      ),
      routeResults: null,
      isSimulating: false
    })),

  addViaPoint: () =>
    set(state =>
    {
      const currentPoints = state.points;
      const viaPointsCount = currentPoints.filter(p => p.type === 'via').length;
      const newOrder = viaPointsCount + 1;
      const newPoint: RoutePoint = {
        id: `via-${Date.now()}`,
        type: 'via',
        status: 'empty',
        location: null,
        order: newOrder,
        color: getPointColor('via', newOrder),
        address: null,
        lat: null,
        lng: null
      };

      const newPoints = [...currentPoints];
      newPoints.splice(newPoints.length - 1, 0, newPoint);

      return {
        ...state,
        points: newPoints.map((p, idx) => ({
          ...p,
          order: idx,
          color: getPointColor(p.type, idx)
        })),
        routeResults: null,
        isSimulating: false
      };
    }),

  removePoint: (id) =>
    set(state =>
    {
      const point = state.points.find(p => p.id === id);
      if (!point || point.type !== 'via') return state;

      const newPoints = state.points.filter(p => p.id !== id);

      return {
        ...state,
        points: newPoints.map((p, idx) => ({
          ...p,
          order: idx,
          color: getPointColor(p.type, idx)
        })),
        routeResults: null,
        isSimulating: false
      };
    }),

  reorderPoints: (orderedIds) =>
    set(state =>
    {
      const pointMap = new Map(state.points.map(p => [p.id, p]));
      const totalPoints = orderedIds.length;
      const newPoints = orderedIds
        .map(id => pointMap.get(id))
        .filter((p): p is RoutePoint => p !== undefined)
        .map((point, idx) => updatePointOnReorder(point, idx, totalPoints));

      return {
        ...state,
        points: newPoints,
        routeResults: null,
        isSimulating: false
      };
    }),

  setRouteConfig: (config) =>
    set(state => ({
      ...state,
      routeConfig: {
        ...state.routeConfig,
        ...config
      },
      isSimulating: false
    })),

  setRouteResults: (results) =>
    set(state => ({
      ...state,
      routeResults: results,
      isSimulating: false
    })),

  setSelectedLocation: (location) =>
    set(state => ({
      ...state,
      selectedLocation: location
    })),

  setHandleComputeRoute: (computeFunction) =>
    set(state => ({
      ...state,
      handleComputeRoute: computeFunction
    })),
  setSelectedPolyline: (polyline) =>
    set(state =>
    {
      if (!state.routeResults) return state;
      return {
        ...state,
        routeResults: {
          ...state.routeResults,
          selectedPolyline: polyline
        },
        isSimulating: false
      };
    }),

  toggleFormVisibility: () =>
    set(state => ({
      ...state,
      isFormVisible: !state.isFormVisible
    })),

  toggleResultsVisibility: () =>
    set(state => ({
      ...state,
      isResultsVisible: !state.isResultsVisible
    })),

  toggleOptionsExpanded: () =>
    set(state => ({ ...state, isOptionsExpanded: !state.isOptionsExpanded })),

  toggleWaypointsExpanded: () =>
    set(state => ({ ...state, isWaypointsExpanded: !state.isWaypointsExpanded })),

  reset: () => set({
    points: initialPoints,
    routeConfig: initialRouteConfig,
    routeResults: null,
    selectedLocation: null,
    isSimulating: false,
    isFormVisible: true,
    isResultsVisible: true,
    isCalculating: false,
    isOptionsExpanded: false,
    isWaypointsExpanded: false,
    handleComputeRoute: undefined
  })
}));
