import { useState, useCallback, useEffect } from 'react';
import type { AutocompleteElem } from '../../../../services/geocoding-autocomplete/response-types';
import { useRouteValidation } from './useRouteValidation';
import { useRouteCalculation } from './useRouteCalculation';
import { useRouteOptions } from './useRouteOptions';
import { useRoutePoints } from './useRoutePoints';
import { useRouteStore } from '../../../../store/useRouteStore';

export interface RouteFormState {
  error: string | null;
  isOptimizing: boolean;
  isRouteCalculating: boolean;
  hasInvalidPoint: boolean;
}

export function useRouteFormBase() {
  const [state, setState] = useState<RouteFormState>({
    error: null,
    isOptimizing: false,
    isRouteCalculating: false,
    hasInvalidPoint: false
  });

  const { validatePoints } = useRouteValidation();
  const { handleComputeRoute, isPending, state: calculationState } = useRouteCalculation();
  const isOptionsExpanded = useRouteStore(state => state.isOptionsExpanded);
  const toggleOptionsExpanded = useRouteStore(state => state.toggleOptionsExpanded);

  // Auto-clear error messages after 5 seconds
  useEffect(() =>
  {
    if (state.error || calculationState.error)
    {
      const timer = setTimeout(() =>
      {
        // Clear form state error
        setState(prev => ({ ...prev, error: null }));
      }, 5000); // 5 seconds delay

      // Cleanup function to prevent memory leaks
      return () => clearTimeout(timer);
    }
  }, [state.error, calculationState.error]);

  // Register compute function with the store
  useEffect(() =>
  {
    useRouteStore.getState().setHandleComputeRoute(handleComputeRoute);

    return () =>
    {
      useRouteStore.getState().setHandleComputeRoute(undefined);
    };
  }, [handleComputeRoute]);

  const {
    handleOptimizeTypeChange,
    handleAvoidFeatureChange,
    handleToggleReturnTrip,
    handleRoutingMethodChange,
    handleTrafficModeChange,
    handleTrafficHistoryDateChange,
    handleTransportModeChange,
    handleTruckProfileChange,
    optimizationType,
    avoidFeatures,
    isReturnTrip,
    routingMethod,
    trafficMode,
    trafficHistoryDate,
    transportMode,
    truckProfile
  } = useRouteOptions();

  const {
    handleRouteStart,
    handleRouteStop,
    handleLocationDelete,
    handlePointClear,
    handleAddViaPoint
  } = useRoutePoints();

  const handleToggleOptions = useCallback(() => {
    toggleOptionsExpanded();
  }, [toggleOptionsExpanded]);

  // Event handlers from optimization
  const handleRouteStartWithValidation = useCallback((location: AutocompleteElem | null) => {
    handleRouteStart(location);
    validatePoints();
  }, [handleRouteStart, validatePoints]);

  const handleRouteStopWithValidation = useCallback((location: AutocompleteElem | null) => {
    handleRouteStop(location);
    validatePoints();
  }, [handleRouteStop, validatePoints]);

  return {
    // State
    state: {
      ...state,
      showOptions: isOptionsExpanded
    },
    setState,

    // Point operations
    handleRouteStart: handleRouteStartWithValidation,
    handleRouteStop: handleRouteStopWithValidation,
    handleLocationDelete,
    handleAddViaPoint,
    handlePointClear,

    // Route computation
    handleComputeRoute,
    isPending,

    // Route configuration
    handleAvoidFeatureChange,
    handleOptimizeTypeChange,
    handleToggleReturnTrip,
    handleToggleOptions,
    handleRoutingMethodChange,
    handleTrafficModeChange,
    handleTrafficHistoryDateChange,
    handleTransportModeChange,
    handleTruckProfileChange,

    // Configuration state
    optimizationType,
    avoidFeatures,
    isReturnTrip,
    routingMethod,
    trafficMode,
    trafficHistoryDate,
    transportMode,
    truckProfile
  };
}

export type UseRouteFormBase = ReturnType<typeof useRouteFormBase>;
