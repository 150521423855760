import { Polyline, Marker } from 'react-leaflet';
import type { LatLngTuple, LeafletMouseEvent } from 'leaflet';
import L from 'leaflet';
import { useRouteStore } from '../../store/useRouteStore';
import { useAppStore } from '../../store/useAppStore';
import { useReverseGeocodingMutation } from '../../services/geocoding/mutation';
import { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { POINT_COLORS } from '../routing/routingUtilities/colors';
import type { TransportMode } from '../../components/routing/routingUtilities/types';

interface RoutePolylinesProps
{
  showColors?: boolean;
}

export function RoutePolylines({ showColors = false }: RoutePolylinesProps)
{
  const activeModule = useAppStore(state => state.activeModule);
  const selectedPolyline = useRouteStore((state) => state.routeResults?.selectedPolyline);
  const polylinesIndex = useRouteStore((state) => state.routeResults?.routes[0].waypoints.map(waypoint => waypoint.polylineIndex)) as number[];
  const isReturnTrip = useRouteStore((state) => state.routeConfig.isReturnTrip);
  const isSimulating = useRouteStore((state) => state.isSimulating);
  const stopSimulation = useRouteStore((state) => state.stopSimulation);
  const transportMode = useRouteStore((state) => state.routeConfig.transportMode);

  const [markerPosition, setMarkerPosition] = useState<LatLngTuple | null>(null);
  const [rotation, setRotation] = useState(0);
  const [useTransportMarker] = useState<boolean>(true); // Set to false by default
  const animationRef = useRef<number>();
  const progressRef = useRef(0);
  const reverseGeocodingMutation = useReverseGeocodingMutation();

  // Function to get transport-specific icons based on the mode
  const getTransportIcon = useCallback((mode: TransportMode, rotation: number) =>
  {
    const iconFileName = mode.toLowerCase().replace('_', '');
    return L.divIcon({
      html: `<div style="transform: rotate(${rotation}deg); filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.5));">
        <img src="/transport/${iconFileName}.png" width="32" height="32" alt="${mode}" />
      </div>`,
      className: 'transport-marker simulation-marker',
      iconSize: [32, 32],
      iconAnchor: [16, 16]
    });
  }, []);

  // Modified marker icon creation to choose between arrow and transport icons
  const markerIcon = useMemo(() =>
  {
    if (useTransportMarker)
    {
      return getTransportIcon(transportMode, rotation);
    } else
    {
      return L.divIcon({
        html: `<div style="transform: rotate(${rotation - 90}deg); filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.5));">
          <svg width="32" height="32" viewBox="0 0 24 24">
            <path d="M8 4l8 8-8 8" fill="none" stroke="#1e3a8a" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 4l8 8-8 8" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>`,
        className: 'arrow-marker simulation-marker',
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      });
    }
  }, [rotation, useTransportMarker, transportMode, getTransportIcon]);

  // Handle polyline clicks
  const handlePolylineClick = useCallback(async (e: LeafletMouseEvent) =>
  {
    // Check constraints - only proceed if:
    // 1. Routing module is active
    // 2. Not simulating
    if (activeModule !== 'route' || isSimulating)
    {
      return;
    }

    const clickedPosition = e.latlng;

    try
    {
      // Add a new via point
      useRouteStore.getState().addViaPoint();

      // Get the newly added via point 
      const viaPoints = useRouteStore.getState().getViaPoints();
      const newPoint = viaPoints[viaPoints.length - 1];

      if (!newPoint)
      {
        console.error('Failed to add via point');
        return;
      }

      // Perform reverse geocoding at the clicked location
      const response = await reverseGeocodingMutation.mutateAsync({
        coordinateSat: {
          lat: clickedPosition.lat,
          lon: clickedPosition.lng
        },
        radius: 500,
        maximumResults: 1,
      });

      if (response.elements.length > 0)
      {
        const place = response.elements[0];
        const address = place.postalAddress;

        // Format place text
        let placeText = `${address.street || ''}, ${address.postalCode || ''} ${address.city || ''}, ${address.county || ''}, ${address.state || ''}, ${address.country || ''}`.replace(/,\s*,/g, ', ').replace(/,\s*$/, '').trim();

        // Check if place text is valid
        if (placeText.split(',').length <= 1 || placeText.trim() === ',')
        {
          placeText = 'Unknown location';
        }

        const placetextwithoutcomma = placeText.replace(/,/g, '').trim();
        if (placetextwithoutcomma === address.countryCode || placetextwithoutcomma === address.country || placetextwithoutcomma === address.postalCode)
        {
          placeText = 'Unknown location';
        }

        // Set point location with all data
        useRouteStore.getState().setPointLocation(newPoint.id, {
          coordinate: {
            latitude: clickedPosition.lat,
            longitude: clickedPosition.lng
          },
          place: placeText,
          elemType: 'address',
          address: address
        });

        // Trigger route calculation automatically
        const handleComputeRoute = useRouteStore.getState().handleComputeRoute;
        if (handleComputeRoute)
        {
          handleComputeRoute();
        }
      } else
      {
        // Handle case when no address is found
        useRouteStore.getState().setPointLocation(newPoint.id, {
          coordinate: {
            latitude: clickedPosition.lat,
            longitude: clickedPosition.lng
          },
          place: 'Unknown location',
          elemType: 'address',
          address: { city: 'Unknown location' }
        });

        // Trigger route calculation
        const handleComputeRoute = useRouteStore.getState().handleComputeRoute;
        if (handleComputeRoute)
        {
          handleComputeRoute();
        }
      }
    } catch (error)
    {
      console.error('Error handling polyline click:', error);

      // Clean up the via point if something went wrong
      const viaPoints = useRouteStore.getState().getViaPoints();
      const newPoint = viaPoints[viaPoints.length - 1];
      if (newPoint)
      {
        useRouteStore.getState().removePoint(newPoint.id);
      }
    }
  }, [activeModule, isSimulating, reverseGeocodingMutation]);

  const positions = useMemo(() =>
  {
    if (!selectedPolyline) return [];
    return selectedPolyline.map(coord => [coord.lat, coord.lon] as LatLngTuple);
  }, [selectedPolyline]);

  const polylineSections = useMemo(() =>
  {
    if (!selectedPolyline || !polylinesIndex) return [];

    const sections: { positions: LatLngTuple[]; color: string }[] = [];

    for (let i = 0; i < polylinesIndex.length - 1; i++)
    {
      const startIdx = polylinesIndex[i];
      const endIdx = polylinesIndex[i + 1];
      const sectionCoords = selectedPolyline.slice(startIdx, endIdx + 1);
      const positions = sectionCoords.map(coord => [coord.lat, coord.lon] as LatLngTuple);

      let color;
      if (i === 0)
      {
        // First section always uses start color
        color = POINT_COLORS.start;
      } else if (isReturnTrip && i === polylinesIndex.length - 2)
      {
        // Last section uses end color only for return trips
        color = POINT_COLORS.end;
      } else
      {
        // All other sections use via colors
        color = POINT_COLORS.via[(i - 1) % POINT_COLORS.via.length];
      }

      sections.push({ positions, color });
    }

    return sections;
  }, [selectedPolyline, polylinesIndex, isReturnTrip]);

  useEffect(() =>
  {
    if (!isSimulating || !positions.length)
    {
      if (animationRef.current)
      {
        cancelAnimationFrame(animationRef.current);
      }
      setMarkerPosition(null);
      return;
    }

    const animate = () =>
    {
      progressRef.current += 0.0005; // Reduced speed for smoother animation
      if (progressRef.current > 1)
      {
        progressRef.current = 0;
        stopSimulation();
        return;
      }

      // Calculate position along the polyline
      const idx = Math.floor(progressRef.current * (positions.length - 1));
      const nextIdx = Math.min(idx + 1, positions.length - 1);
      const ratio = progressRef.current * (positions.length - 1) - idx;

      const pos1 = positions[idx];
      const pos2 = positions[nextIdx];
      const lat = pos1[0] + (pos2[0] - pos1[0]) * ratio;
      const lng = pos1[1] + (pos2[1] - pos1[1]) * ratio;

      // Calculate rotation angle based on direction
      const angle = Math.atan2(pos2[1] - pos1[1], pos2[0] - pos1[0]) * (180 / Math.PI);
      setRotation(angle);
      setMarkerPosition([lat, lng]);

      animationRef.current = requestAnimationFrame(animate);
    };

    progressRef.current = 0;
    animate();

    return () =>
    {
      if (animationRef.current)
      {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isSimulating, positions, stopSimulation]);

  if (!positions.length) return null;

  return (
    <>
      {/* Base polyline (colored or blue) */}
      {!showColors ? (
        <Polyline
          positions={positions}
          pathOptions={{
            color: '#3b82f6', // blue-500
            weight: 4,
            opacity: 1
          }}
          eventHandlers={{
            click: handlePolylineClick
          }}
        />
      ) : polylineSections.map((section, index) => (
        <Polyline
          key={index}
          positions={section.positions}
          pathOptions={{
            color: section.color,
            weight: 4,
            opacity: 1
          }}
          eventHandlers={{
            click: handlePolylineClick
          }}
        />
      ))}

      {/* Animated marker */}
      {isSimulating && markerPosition && (
        <Marker
          position={markerPosition}
          icon={markerIcon}
        />
      )}
    </>
  );
}
