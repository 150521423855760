import { getItem } from '../../api/storage';
import { StorageEnum } from '../../api/enum';

interface Geoserver {
  key: string;
  value: string;
  byDefault?: boolean;
}

interface UserData {
  username: string;
  rights: string[];
  geoservers: Geoserver[];
  chargingStationProviders: unknown[];
}

export const getCurrentGeoserver = (): string => {
  const user = getItem<UserData>(StorageEnum.User);

  if (!user?.geoservers?.length) {
    throw new Error('No geoservers available. Please login again.');
  }

  // First check for herehlp
  const herehlpServer = user.geoservers.find(server => server.key === 'herehlp');
  if (herehlpServer) {
    return herehlpServer.key;
  }

  // Then check for nominatim
  const nominatimServer = user.geoservers.find(server => server.key === 'nominatim');
  if (nominatimServer) {
    return nominatimServer.key;
  }

  // Finally use the default server
  const defaultServer = user.geoservers.find(server => server.byDefault);
  if (!defaultServer) {
    throw new Error('No default geoserver available');
  }

  return defaultServer.key;
};

export const  getDefaultGeoserver = (): string => {
  const user = getItem<UserData>(StorageEnum.User);

  if (!user?.geoservers?.length) {
    throw new Error('No geoservers available. Please login again.');
  }

  const defaultServer = user.geoservers.find(server => server.byDefault);
  if (!defaultServer) {
    throw new Error('No default geoserver available');
  }

  return defaultServer.key;
};
