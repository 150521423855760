// Fixed color palettes
export const POINT_COLORS = {
    start: '#22c55e', // green-500
    end: '#ef4444',   // red-500
    via: [
        '#3b82f6', // blue-500
        '#f97316', // orange-500
        '#8b5cf6', // purple-500
        '#eab308', // yellow-500
        '#14b8a6', // teal-500
        '#ec4899', // pink-500
        '#6366f1', // indigo-500
        '#84cc16',  // lime-500
        '#f59e0b', // amber-500
        '#7c3aed', // violet-600
        '#0f766e',  // teal-700
        '#0891b2', // cyan-600
        '#15803d', // green-700
        '#4338ca', // indigo-700
        '#b45309', // amber-700
        '#86198f', // fuchsia-700
        '#c2410c', // orange-700
        '#0369a1', // sky-700
        '#1e3a8a', // blue-900
        '#831843', // pink-900
        '#365314', // lime-900
        '#134e4a', // teal-900
        '#78350f', // amber-900
        '#581c87',  // purple-900
        '#fca5a5', // light red (red-300)
        '#92400e',  // brown (amber-800)
        '#d1d5db', // light grey (gray-300)
        '#374151', // dark grey (gray-700)
        '#171717' // black (neutral-900)
    ]
};