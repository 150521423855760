import type { OptimizationType, RoutePoint } from './types';
import type { RoutingResponse } from '../../../services/routing/response-types';

export const processOptimizationResults = (
    response: RoutingResponse,
    points: RoutePoint[],
    optimizationType: OptimizationType
): RoutePoint[] => {
    if (!response.usedDestinations || !response.routingRoutes?.[0]) {
        return points;
    }

    // Map points by their input order (exclude start point which is 0)
    const pointsByInputOrder = new Map<number, RoutePoint>();
    points.slice(1).forEach((point, idx) => {
        pointsByInputOrder.set(idx + 1, point); // idx + 1 because input order starts at 1
    });

    // Get start point which stays at beginning for both types
    const startPoint = points[0];
    const reorderedPoints: RoutePoint[] = [startPoint];

    // Sort destinations by their used order (excluding start point)
    const sortedDests = response.usedDestinations
        .filter(d => d.used && d.inputOrder > 0)
        .sort((a, b) => a.usedOrder - b.usedOrder);

    if (optimizationType === 'openEnded') {
        // For open-ended, all points are reordered including the end point
        sortedDests.forEach(dest => {
            const point = pointsByInputOrder.get(dest.inputOrder);
            if (point) {
                reorderedPoints.push({
                    ...point,
                    // Maintain status and location while updating type
                    type: 'via',
                    status: point.status,
                    location: point.location
                });
            }
        });

        // Last point becomes end
        if (reorderedPoints.length > 1) {
            const lastPoint = reorderedPoints[reorderedPoints.length - 1];
            reorderedPoints[reorderedPoints.length - 1] = {
                ...lastPoint,
                type: 'end'
            };
        }
        console.log('reorderedPoints', reorderedPoints);
    } else {
        // For optimized trip, keep end point at end and reorder via points
        const endPoint = points[points.length - 1];
        sortedDests
            .filter(dest => pointsByInputOrder.get(dest.inputOrder)?.type === 'via')
            .forEach(dest => {
                const point = pointsByInputOrder.get(dest.inputOrder);
                if (point) {
                    reorderedPoints.push({
                        ...point,
                        type: 'via',
                        status: point.status,
                        location: point.location
                    });
                }
            });
        reorderedPoints.push(endPoint);
    }

    // Update order and colors for all points
    return reorderedPoints.map((point, index) => ({
        ...point,
        order: index,
        type: index === 0 ? 'start' :
              index === reorderedPoints.length - 1 ? 'end' : 'via',
        // Ensure we maintain location and status
        location: point.location,
        status: point.status
    }));
};
