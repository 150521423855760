import { useMutation } from '@tanstack/react-query';
import { RoutingRequest, RoutingOption, RoutingCriteria, Destination, RoutingVehicleProfile, RoutingVehicleFeature } from './request-types';
import { RoutingResponse } from './response-types';
import apiClient from '../../api/apiClient';
import { OptimizationType, RoutingMethod, TrafficMode, TransportMode, TruckSpecFormData } from '../../components/routing/routingUtilities/types';

const ROUTING_ENDPOINT = 'service/routing/1.0';

export interface CalculateRouteParams {
  start: Destination;
  end: Destination;
  viaPoints?: Destination[];
  isReturnTrip?: boolean;
  optimizationType?: OptimizationType;
  routingMethod?: RoutingMethod;
  trafficMode?: TrafficMode;
  trafficHistoryDate?: Date | null;
  showRoutesheet?: boolean;
  transportMode?: TransportMode;
  truckProfile?: TruckSpecFormData | null;
  avoidFeatures?: {
    tolls?: boolean;
    motorways?: boolean;
    ferries?: boolean;
    unpavedRoads?: boolean;
    borderCrossings?: boolean;
  };
}

// Default values for truck:
// - Truck height: 4.0m = 400cm
// - Truck width: 2.55m = 255cm
// - Truck length: 12.0m = 1200cm
// - Truck weight: 3.5t = 0.35 in tens of tons
// - Truck max speed: 90km/h
const defaultTruckFeature: RoutingVehicleFeature = {
  height: 400,  // cm
  width: 255,   // cm
  length: 1200, // cm
  weight: 0.35, // tens of tons (3.5t)
  maxSpeed: 90  // km/h
};

// Default values for car:
// - Car height: 1.8m = 180cm
// - Car width: 1.8m = 180cm
// - Car length: 4.5m = 450cm
// - Car weight: 2.0t = 0.2 in tens of tons
const defaultVehicleFeature: RoutingVehicleFeature = {
  height: 180,  // cm
  width: 180,   // cm
  length: 450,  // cm
  weight: 0.2   // tens of tons (2.0t)
};

const baseOptions: RoutingOption[] = ['POLYLINE', 'EVT_TOLL_COST'];

const convertTruckProfile = (profile: TruckSpecFormData): RoutingVehicleFeature => ({
  // API expects:
  // - heights/lengths in cm (no conversion needed)
  // - weights in tens of tons (divide by 10)
  // e.g., 3.5t becomes 0.35, 18t becomes 1.8
  height: profile.height,    // already in cm
  width: profile.width,      // already in cm
  length: profile.length,    // already in cm
  weight: profile.weight / 10,     // convert t to tens of t
  axleWeight: profile.axleWeight ? profile.axleWeight / 10 : undefined,
  maxSpeed: profile.maxSpeed
});

export const calculateRoute = async ({
  start,
  end,
  viaPoints = [],
  isReturnTrip = false,
  optimizationType = 'none',
  routingMethod = 'FASTEST',
  trafficMode = 'none',
  trafficHistoryDate = null,
  showRoutesheet = false,
  transportMode = 'CAR',
  truckProfile = null,
  avoidFeatures = {}
}: CalculateRouteParams): Promise<RoutingResponse> => {
  // Prepare destinations list
  let destinations = [start];

  // Add via points if any
  if (viaPoints.length > 0) {
    destinations = destinations.concat(viaPoints);
  }

  // Add end point for all cases
  destinations.push(end);

  // For return trips, add start point again at the end
  if (isReturnTrip) {
    console.log('Adding return trip');
    destinations.push(start);
  }

  // Build vehicle profile based on transport mode
  const vehicleProfile: RoutingVehicleProfile = {
    transportMode,
    routingVehicleFeature: transportMode === 'TRUCK' ?
      (truckProfile ? convertTruckProfile(truckProfile) : defaultTruckFeature) :
      defaultVehicleFeature
  };

  // Build base request
  const request: RoutingRequest = {
    geoserver: "here",
    outputLanguage: "fr",
    routingMode: viaPoints.length > 0 ? 'MODE_VIAS' : undefined,
    destinations,
    options: [...baseOptions],
    routingVehicleProfile: vehicleProfile
  };

  let updatedOptions: RoutingOption[] = [...baseOptions];

  // Handle routing method
  request.routingCriterias = [routingMethod];

  // Add optimization if needed
  if (viaPoints.length > 0) {
    switch (optimizationType) {
      case 'optimized':
        updatedOptions = [...updatedOptions, 'OPTIMIZED_TRIP'];
        break;
      case 'openEnded':
        updatedOptions = [...updatedOptions, 'OPTIMIZED_TRIP_UNDEFSTOP'];
        break;
    }
  }

  // Add routesheet option if enabled
  if (showRoutesheet) {
    updatedOptions = [...updatedOptions, 'ROUTESHEET'];
  }

  // Add traffic options
  switch (trafficMode) {
    case 'live':
      updatedOptions = [...updatedOptions, 'TRAFFIC', 'EVENT', 'EVT_TRAFFIC'];
      break;
    case 'historical':
      updatedOptions = [...updatedOptions, 'TRAFFIC_PATTERNS', 'EVENT', 'EVT_TRAFFIC_HISTORICAL'];

      // Always set departureTime for historical traffic
      request.departureTime = trafficHistoryDate
        ? trafficHistoryDate.toISOString()
        : new Date().toISOString();
      break;
  }

  // Add avoid features if some are enabled
  if (avoidFeatures && Object.values(avoidFeatures).some(value => value)) {
    const avoidCriterias = Object.entries(avoidFeatures)
      .filter(([, value]) => value)
      .map(([key]): RoutingCriteria | null => {
        switch (key) {
          case 'tolls': return 'AVOID_TOLLS';
          case 'motorways': return 'AVOID_MOTORWAYS';
          case 'ferries': return 'AVOID_FERRIES';
          case 'unpavedRoads': return 'AVOID_UNPAVED';
          case 'borderCrossings': return 'AVOID_CROSSING_BORDER';
          default: return null;
        }
      })
      .filter((criteria): criteria is RoutingCriteria => criteria !== null);

    // Combine routing method with avoid features
    request.routingCriterias = [...request.routingCriterias, ...avoidCriterias];
  }

  // Update request options and add waypoints option for via points
  request.options = [
    ...updatedOptions,
    'WAYPOINTS_POLYLINE',
    'STARTSTOPINFO_WITHVIA'
  ];

  try {
    // Log request in development mode
    if (import.meta.env.DEV)
    {
      // Always use the full URL for logging
      const apiBaseUrl = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';
      console.log(`Routing request to: ${apiBaseUrl}/${ROUTING_ENDPOINT}`, {
        transportMode,
        viaPointsCount: viaPoints.length,
        optimizationType,
        requestSize: JSON.stringify(request).length
      });
    }

    const response = await apiClient.post<RoutingResponse>({
      url: ROUTING_ENDPOINT,
      data: request,
    });

    return response;
  } catch (error) {
    // Enhanced error logging
    console.error('Failed to calculate route:', {
      message: error instanceof Error ? error.message : 'Unknown error',
      transportMode,
      optimizationType,
      endpoint: ROUTING_ENDPOINT,
      viaPointsCount: viaPoints.length
    });
    throw error;
  }
};

export const useRouteMutation = () => {
  return useMutation({
    mutationKey: ['routing', ROUTING_ENDPOINT],
    meta: {
      endpoint: ROUTING_ENDPOINT
    },
    mutationFn: calculateRoute,
    retry: 1, // Add a single retry for routing failures
  });
};
