import { memo } from 'react';
import type { LatLngTuple } from 'leaflet';
import { useAppStore } from '../../store/useAppStore';
import { GeocodingMarkers } from '../geocoding/GeocodingMarkers';
import { RouteMarkers } from '../routing/markers/RouteMarkers';
import { useRouteFormBase } from '../routing/routingUtilities/hooks';
import { RouteDragProvider } from '../routing/drag/RouteDragContext';

interface MapMarkersProps {
  searchMarkerPosition: LatLngTuple | null;
}

export const MapMarkers = memo(({ searchMarkerPosition }: MapMarkersProps) => {
  const activeModule = useAppStore(state => state.activeModule);

  const {
    state: { isRouteCalculating, isOptimizing },
    isPending
  } = useRouteFormBase();

  const isCalculating = isPending || isOptimizing || isRouteCalculating;

  return (
    <>
      {/* Show routing markers only when in routing module */}
      {activeModule === 'route' && (
        <RouteDragProvider isCalculating={isCalculating}>
          <RouteMarkers />
        </RouteDragProvider>
      )}
      {/* Show geocoding markers only when not in any module */}
      <GeocodingMarkers searchMarkerPosition={searchMarkerPosition} />
    </>
  );
});

MapMarkers.displayName = 'MapMarkers';
