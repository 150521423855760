import { AuthProvider } from '../contexts/AuthProvider';
import App from '../../App';
import Login from '../../components/app/Login';

export const AuthenticatedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export const AuthenticatedLogin = () => (
  <AuthProvider>
    <Login />
  </AuthProvider>
);