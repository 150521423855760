import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { ResultEnum, ApiError } from './api';

// Add an event emitter for auth state changes
type AuthChangeCallback = (isAuthenticated: boolean) => void;
const authChangeCallbacks: AuthChangeCallback[] = [];

// Export function to subscribe to auth changes
export const onAuthStateChange = (callback: AuthChangeCallback): () => void => {
  authChangeCallbacks.push(callback);

  // Return unsubscribe function
  return () => {
    const index = authChangeCallbacks.indexOf(callback);
    if (index > -1) {
      authChangeCallbacks.splice(index, 1);
    }
  };
};

const notifyAuthChange = (newState: boolean) => {
  authChangeCallbacks.forEach(callback => callback(newState));
};

// Environment detection
const isDev = import.meta.env.DEV;
const configuredApiBase = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';

// Check if we're on the same domain as the API
const apiHostname = new URL(configuredApiBase).hostname;
const currentHostname = window.location.hostname;
const isOnSameDomain = apiHostname === currentHostname;

// Log environment detection for debugging
console.log('Environment detection:', {
  isDev,
  isProd: import.meta.env.PROD,
  host: window.location.host,
  origin: window.location.origin,
  apiBase: configuredApiBase,
  apiHostname,
  currentHostname,
  isOnSameDomain
});

// Normalize the base URL to ensure it doesn't have trailing slash
const normalizedBaseUrl = configuredApiBase.endsWith('/')
  ? configuredApiBase.slice(0, -1)
  : configuredApiBase;

// For cross-domain requests without CORS headers properly set,
// we need to disable withCredentials
const shouldUseCredentials = isDev || isOnSameDomain;

// Show the resolved API URL for debugging
console.log('API Client initialization:', {
  baseURL: normalizedBaseUrl,
  withCredentials: shouldUseCredentials,
  fullSampleUrl: `${normalizedBaseUrl}/service/acl/1.0/user/details`,
  strategy: shouldUseCredentials ? 'Using cookies (withCredentials)' : 'Using Authorization header only'
});

// Create axios instance with proper configuration
const axiosInstance = axios.create({
  baseURL: normalizedBaseUrl,
  timeout: 30000, // 30 seconds timeout
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
  withCredentials: shouldUseCredentials, // Only use credentials if on same domain or in dev mode
});

// Track authentication state and credentials
let isAuthenticated = false;
let storedAuthHeader: string | null = null;

// Helper to check if error is auth-related
const isAuthError = (error: AxiosError<ApiError>): boolean => {
  const status = error.response?.status;
  const message = error.response?.data?.message;

  return status === 401 ||
         (status === 400 && message === 'Access is denied');
};

// For debugging URL construction
axiosInstance.interceptors.request.use(config =>
{
  // Ensure URL has proper slash between base and path
  if (config.url && !config.url.startsWith('/'))
  {
    config.url = `/${config.url}`;
  }

  // Add detailed debugging information for auth requests
  if (config.auth)
  {
    console.log(`Making authenticated request to: ${config.baseURL}${config.url}`);
  }

  if (isDev)
  {
    // In development, log the request for debugging
    console.log(`${config.method?.toUpperCase()} ${config.baseURL}${config.url}`, config);
  }

  return config;
});

axiosInstance.interceptors.request.use(
  (config) =>
  {
    // If auth credentials are provided in the request config (for initial login)
    if (config.auth) {
      const credentials = btoa(`${config.auth.username}:${config.auth.password}`);
      const authHeader = `Basic ${credentials}`;
      config.headers.Authorization = authHeader;

      // Store credentials for future requests when not using withCredentials
      if (!shouldUseCredentials)
      {
        storedAuthHeader = authHeader;
      }

      // Ensure we don't send empty credentials
      if (!config.auth.username || !config.auth.password)
      {
        console.warn('Auth credentials incomplete in request config');
      }
      return config;
    }

    // Check if we have a stored auth header when not using cookies
    if (!shouldUseCredentials && storedAuthHeader)
    {
      config.headers.Authorization = storedAuthHeader;
      return config;
    }

    // When using cookies, check local storage for user to verify auth state
    if (shouldUseCredentials)
    {
      const userStr = localStorage.getItem('user');
      const isUserInStorage = !!userStr;

      // For subsequent requests, rely on cookies and user in storage
      if (isUserInStorage || isAuthenticated)
      {
        return config;
      }
    }

    // In development, allow unauthenticated requests to pass through
    if (isDev)
    {
      console.warn('DEV MODE: Allowing unauthenticated request:', config.url);
      return config;
    }

    // If not authenticated and no auth provided, reject immediately
    return Promise.reject(new Error('Authentication required'));
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    // If this was an auth request and it succeeded, mark as authenticated
    if (res.config.auth && res.status === ResultEnum.SUCCESS) {
      isAuthenticated = true;
      notifyAuthChange(true);
    }

    // Return the actual data from the response
    return res.data;
  },
  (error: AxiosError<ApiError>) => {
    // Enhanced error logging for easier debugging
    console.error('API Error:', {
      message: error.message,
      code: error.code,
      status: error.response?.status,
      apiMessage: error.response?.data?.message,
      url: error.config?.url,
      baseURL: axiosInstance.defaults.baseURL
    });

    // Handle network errors
    if (error.code === 'ERR_NETWORK') {
      console.error('Network error details:', {
        message: error.message,
        code: error.code,
        baseURL: axiosInstance.defaults.baseURL,
        withCredentials: axiosInstance.defaults.withCredentials,
        url: error.config?.url
      });

      // Provide more specific error messages based on the error
      if (error.message.includes('Network Error'))
      {
        return Promise.reject(new Error('Network error: Unable to connect to API server. Please check your internet connection and try again.'));
      }

      return Promise.reject(new Error('Unable to connect to server. Please try again later.'));
    }

    // Log CORS issues (common in production)
    if (error.message?.includes('CORS'))
    {
      console.error('CORS error detected:', {
        message: error.message,
        origin: window.location.origin,
        baseUrl: axiosInstance.defaults.baseURL,
        requestUrl: error.config?.url ? `${axiosInstance.defaults.baseURL}${error.config.url}` : 'unknown',
        method: error.config?.method?.toUpperCase() || 'unknown',
        withCredentials: axiosInstance.defaults.withCredentials
      });

      // More helpful error message for CORS issues with guidance
      if (shouldUseCredentials)
      {
        return Promise.reject(new Error(`Cross-origin request blocked due to CORS policy. The API server needs to allow requests from ${window.location.origin} with credentials.`));
      } else
      {
        return Promise.reject(new Error(`Cross-origin request blocked. Please try refreshing the page. If the issue persists, contact the API administrator.`));
      }
    }

    // Handle auth errors
    if (isAuthError(error)) {
      isAuthenticated = false;
      storedAuthHeader = null;
      notifyAuthChange(false);
      return Promise.reject(new Error('Authentication failed'));
    }

    // If we have an API error response, use its message
    if (error.response?.data) {
      const apiError = error.response.data;
      return Promise.reject(new Error(apiError.message || 'An error occurred'));
    }

    // Handle timeout
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(new Error('Request timed out - please try again'));
    }

    // Fallback error message
    return Promise.reject(new Error('An unexpected error occurred'));
  },
);

export interface APIClientConfig {
  keepSession?: boolean;
}

class APIClient {
  private config: APIClientConfig = {
    keepSession: false
  };

  configure(config: APIClientConfig) {
    this.config = { ...this.config, ...config };
  }

  getConfig(): APIClientConfig {
    return { ...this.config };
  }

  // Helper method to add retries for timeout errors
  private async requestWithRetry<ResponseType>(
    config: AxiosRequestConfig,
    retries = 2,
    delay = 1000
  ): Promise<ResponseType>
  {
    try
    {
      return await axiosInstance.request(config);
    } catch (error)
    {
      // Only retry on network timeouts
      if (
        retries > 0 &&
        error instanceof Error &&
        (error.message.includes('timeout') || error.message.includes('network'))
      )
      {
        console.log(`Request timed out, retrying... (${retries} attempts left)`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.requestWithRetry(config, retries - 1, delay * 1.5);
      }
      throw error;
    }
  }

  get<ResponseType>(config: AxiosRequestConfig): Promise<ResponseType> {
    return this.request<ResponseType>({ ...config, method: 'GET' });
  }

  post<ResponseType>(config: AxiosRequestConfig): Promise<ResponseType> {
    return this.request<ResponseType>({ ...config, method: 'POST' });
  }

  put<ResponseType>(config: AxiosRequestConfig): Promise<ResponseType> {
    return this.request<ResponseType>({ ...config, method: 'PUT' });
  }

  delete<ResponseType>(config: AxiosRequestConfig): Promise<ResponseType> {
    return this.request<ResponseType>({ ...config, method: 'DELETE' });
  }

  request<ResponseType>(config: AxiosRequestConfig): Promise<ResponseType> {
    const finalConfig = {
      ...config,
      // Use our credential strategy consistently
      withCredentials: shouldUseCredentials,
    };

    // Use the retry logic for all requests
    return this.requestWithRetry<ResponseType>(finalConfig);
  }

  clearAuth(): void {
    isAuthenticated = false;
    storedAuthHeader = null;
    delete axiosInstance.defaults.headers.common['Authorization'];
    notifyAuthChange(false);
  }

  setAuthenticated(value: boolean): void {
    if (isAuthenticated !== value) {
      isAuthenticated = value;
      notifyAuthChange(value);
    }
  }

  isSessionKeepAliveEnabled(): boolean {
    return this.config.keepSession ?? false;
  }
}

export default new APIClient();