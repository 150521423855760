import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthenticatedApp, AuthenticatedLogin } from './auth/components/AuthenticatedComponents'
import apiClient from './api/apiClient'
import './index.css'

// Enable session keepalive in development
if (import.meta.env.DEV) {
  apiClient.configure({ keepSession: true });
  console.debug('Session keepalive enabled for development');
}

// Log API base URL for debugging - always use the configured URL
const configuredApiBase = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';
console.log('API Base URL:', configuredApiBase);

// Add global error handlers
window.addEventListener('error', (event) =>
{
  console.error('Global error caught:', event.error);
});

window.addEventListener('unhandledrejection', (event) =>
{
  console.error('Unhandled Promise rejection:', event.reason);
});

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
      gcTime: 1000 * 60 * 60 * 24, // Keep mutation data for 24 hours
      meta: {
        errorHandler: (error: Error, mutation: { meta: { endpoint?: string } }) => {
          const endpoint = mutation.meta?.endpoint || 'unknown endpoint';
          console.error(`[${endpoint}] Mutation error:`, error);
        }
      }
    },
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
      meta: {
        errorHandler: (error: Error, query: { meta: { endpoint?: string } }) => {
          const endpoint = query.meta?.endpoint || 'unknown endpoint';
          console.error(`[${endpoint}] Query error:`, error);
        }
      }
    }
  },
});

// Auth loader to protect routes
const protectedLoader = () => {
  const user = localStorage.getItem('user');
  if (!user) {
    return redirect('/login');
  }
  return null;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthenticatedLogin />,
  },
  {
    path: '/login',
    element: <AuthenticatedLogin />,
  },
  {
    path: '/map',
    element: <AuthenticatedApp />,
    loader: protectedLoader,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
    </QueryClientProvider>
  </React.StrictMode>,
)
