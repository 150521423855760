import { memo } from "react";
import { Icon } from "@iconify/react";
import { OptimizationType } from "../routingUtilities/types";

const optimizationIcons: Record<OptimizationType, { icon: string; tooltip: string }> = {
  none: {
    icon: "mdi:arrow-right",
    tooltip: "Ordre défini - Suivre l'ordre exact des points"
  },
  optimized: {
    icon: "mdi:routes",
    tooltip: "Points fixes - Optimiser l'ordre avec début et fin fixes"
  },
  openEnded: {
    icon: "mdi:map-marker-path",
    tooltip: "Point de départ fixe - Optimiser avec seulement le départ fixe"
  }
};

interface OptimizationOptionsProps {
  optimizationType: OptimizationType;
  onOptimizeTypeChange: (type: OptimizationType) => void;
}

export const OptimizationOptions = memo(function OptimizationOptions({
  optimizationType,
  onOptimizeTypeChange
}: OptimizationOptionsProps) {
  return (
    <div>
      <h4 className="text-xs font-medium text-gray-500 mb-2">Optimisation</h4>
      <div className="flex gap-2">
        {Object.entries(optimizationIcons).map(([value, { icon, tooltip }]) => (
          <button
            key={value}
            onClick={() => onOptimizeTypeChange(value as OptimizationType)}
            title={tooltip}
            className={`p-3 rounded-md transition-all hover:scale-110 ${
              optimizationType === value
                ? "bg-blue-600 text-white shadow-md shadow-blue-500/20"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            <Icon icon={icon} className="text-2xl" />
          </button>
        ))}
      </div>
    </div>
  );
});
