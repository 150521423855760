import { RoutePoint } from '../routingUtilities/types';
import { RoutePointDisplay } from './RoutePointDisplay';
import { useRouteStore } from '../../../store/useRouteStore';

export const WaypointsAccordion = ({ via }: { via: RoutePoint[] }) => {
  const isExpanded = useRouteStore(state => state.isWaypointsExpanded);
  const toggleExpanded = useRouteStore(state => state.toggleWaypointsExpanded);
  const { routeResults } = useRouteStore();
  const usedDestinations = routeResults?.usedDestinations;

  const formatCoordinates = (lat: number | null, lng: number | null): string =>
  {
    if (lat === null || lng === null) return 'Unknown coordinates';
    return `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
  };

  return (
    <div className="border-l-2 border-gray-300 pl-4 py-1 ml-1">
      <button
        onClick={toggleExpanded}
        className="w-full flex items-center justify-between mb-2 group p-2 rounded-lg hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-start space-x-3">
          <div className="w-7 h-7 bg-blue-600 rounded-full flex-shrink-0 flex items-center justify-center shadow-sm">
            <svg
              className="w-4 h-4 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
          <div>
            <div className="font-medium text-left text-gray-900">Via Points</div>
            <div className="text-sm text-gray-600 text-left">{via.length} location{via.length > 1 ? 's' : ''}</div>
          </div>
        </div>
        <div className="flex items-center space-x-2 text-gray-500 group-hover:text-blue-600 transition-colors bg-gray-100 group-hover:bg-blue-100 px-3 py-1 rounded-lg border border-gray-200 group-hover:border-blue-200">
          <span className="text-sm font-medium">{isExpanded ? 'Hide' : 'Show'}</span>
          <svg
            className={`w-4 h-4 transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </button>

      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="space-y-1">
          {via.map((point, idx) =>
          {
            // Find matching destination data - via points start at inputOrder 1
            const destinationData = usedDestinations?.find(d => d.inputOrder === idx + 1);

            // Only pass duration and distance if they are valid (not -1)
            const duration = destinationData?.duration;
            const validDuration = duration && duration !== -1 ? duration : undefined;

            const distance = destinationData?.length;
            const validDistance = distance && distance !== -1 ? distance : undefined;

            return (
              <RoutePointDisplay
                key={point.id}
                icon={
                  <div className="w-7 h-7 rounded-full flex-shrink-0 flex items-center justify-center shadow-sm"
                    style={{ backgroundColor: point.color || '#818CF8' }}>
                    <span className="text-white text-xs font-medium">
                      {idx + 1}
                    </span>
                  </div>
                }
                label="Via"
                address={point.address || 'Unknown location'}
                cordinates={formatCoordinates(point.lat, point.lng)}
                duration={validDuration}
                distance={validDistance}
                hideCoordinates={true}
                compact={true}
                lowContrast={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
