import { memo } from "react";
import { Icon } from "@iconify/react";
import { RoutingMethod } from "../routingUtilities/types";

const routingMethods: Record<RoutingMethod, { icon: string; tooltip: string }> = {
  FASTEST: {
    icon: "mdi:timer-outline",
    tooltip: "Plus rapide - Optimiser pour le temps de trajet"
  },
  SHORTEST: {
    icon: "mdi:map-marker-distance",
    tooltip: "Plus court - Optimiser pour la distance"
  },
  ECO_ENERGY: {
    icon: "mdi:leaf",
    tooltip: "Éco-énergétique - Optimiser pour la consommation"
  }
};

interface RoutingMethodOptionsProps {
  selectedMethod?: RoutingMethod;
  onMethodChange?: (method: RoutingMethod) => void;
}

export const RoutingMethodOptions = memo(function RoutingMethodOptions({
  selectedMethod = 'FASTEST',
  onMethodChange
}: RoutingMethodOptionsProps) {
  return (
    <div>
      <h4 className="text-xs font-medium text-gray-500 mb-2">Méthode de calcul</h4>
      <div className="flex gap-2">
        {Object.entries(routingMethods).map(([method, { icon, tooltip }]) => (
          <button
            key={method}
            onClick={() => onMethodChange?.(method as RoutingMethod)}
            title={tooltip}
            className={`p-3 rounded-md transition-all hover:scale-110 ${
              method === selectedMethod
                ? "bg-blue-600 text-white shadow-md shadow-blue-500/20"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            <Icon icon={icon} className="text-2xl" />
          </button>
        ))}
      </div>
    </div>
  );
});
