import { memo, useState, useEffect } from "react";
import { Icon } from "@iconify/react";

interface TrafficDateModalProps {
  initialDate: Date | null;
  onSave: (date: Date | null) => void;
  onClose: () => void;
}

export const TrafficDateModal = memo(function TrafficDateModal({
  initialDate,
  onSave,
  onClose
}: TrafficDateModalProps) {
  const [selectedDate, setSelectedDate] = useState<string>(
    initialDate ? initialDate.toISOString().split('T')[0] : new Date().toISOString().split('T')[0]
  );
  const [selectedTime, setSelectedTime] = useState<string>(
    initialDate ? initialDate.toTimeString().slice(0, 5) : new Date().toTimeString().slice(0, 5)
  );

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(initialDate.toISOString().split('T')[0]);
      setSelectedTime(initialDate.toTimeString().slice(0, 5));
    }
  }, [initialDate]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedDate && selectedTime) {
      const [hours, minutes] = selectedTime.split(':');
      const date = new Date(selectedDate);
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      onSave(date);
    }
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-50"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-80 p-4 bg-white rounded-lg shadow-xl border border-gray-200 z-50">
        <div className="flex items-center justify-between mb-4">
          <h5 className="font-medium text-sm text-gray-700">Sélectionner une date et heure</h5>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
            title="Fermer"
          >
            <Icon icon="mdi:close" className="text-xl" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-3">
          <div className="flex flex-col gap-2">
            <label className="text-xs text-gray-600">
              Date pour le trafic historique
            </label>
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              max={new Date().toISOString().split('T')[0]}
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-xs text-gray-600">
              Heure pour le trafic historique
            </label>
            <input
              type="time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>
          <div className="flex justify-end gap-2 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Appliquer
            </button>
          </div>
        </form>
      </div>
    </>
  );
});