import { useCallback } from 'react';
import { useRouteStore } from '../../../../store/useRouteStore';
import type { AutocompleteElem } from '../../../../services/geocoding-autocomplete/response-types';

export function useRoutePoints() {
  const {
    setPointLocation,
    addViaPoint,
    removePoint,
    getViaPoints
  } = useRouteStore();

  const handleRouteStart = useCallback((location: AutocompleteElem | null) => {
    setPointLocation('start', location);
  }, [setPointLocation]);

  const handleRouteStop = useCallback((location: AutocompleteElem | null) => {
    setPointLocation('end', location);
  }, [setPointLocation]);

  const handleLocationDelete = useCallback(() => {
    // Check for empty points to clean up
    const points = getViaPoints();
    const emptyPoint = points.find(p => p.status === 'empty');
    if (emptyPoint) {
      removePoint(emptyPoint.id);
    }
  }, [getViaPoints, removePoint]);

  const handlePointClear = useCallback((pointId: string) => {
    setPointLocation(pointId, null);
    handleLocationDelete();
  }, [setPointLocation, handleLocationDelete]);

  return {
    handleRouteStart,
    handleRouteStop,
    handleLocationDelete,
    handlePointClear,
    handleAddViaPoint: addViaPoint
  };
}

export type UseRoutePoints = ReturnType<typeof useRoutePoints>;
