import { type DragEvent } from 'react';
import { useRouteDrag } from './RouteDragContext';
import { LocationInput } from '../locationInput/LocationInput';
import { useRouteStore } from '../../../store/useRouteStore';
import type { AutocompleteElem } from '../../../services/geocoding-autocomplete/response-types';
import type { Coordinate } from '../../../services/common/types';
import type { RoutePoint } from '../routingUtilities/types';

interface DraggablePointProps {
  point: RoutePoint;
  label: string;
  pointText: string;
  onLocationSelect: (location: AutocompleteElem | null) => void;
  onDelete: () => void;
  onBlur: () => void;
  mapCenter: Coordinate;
  pointNumber: number;
  isCalculating?: boolean;
}

export const DraggablePoint = ({
  point,
  label,
  pointText,
  onLocationSelect,
  onDelete,
  onBlur,
  mapCenter,
  pointNumber,
  isCalculating = false
}: DraggablePointProps) =>
{
  const { draggedId, draggedOverId, handleDragStart, handleDragEnd, handleDragOver, handleDrop } = useRouteDrag();
  const hasEmptyPoints = useRouteStore(state => state.hasEmptyPoints());
  const isStartOrEnd = point.type === 'start' || point.type === 'end';

  // Only allow dragging for set points when not calculating and no empty points exist
  const isDraggable = !isCalculating && point.status === 'set' && !hasEmptyPoints;

  const handleDragStartEvent = (e: DragEvent<HTMLDivElement>) => {
    if (!isDraggable) {
      e.preventDefault();
      return;
    }
    e.currentTarget.classList.add('opacity-50');
    handleDragStart(point.id);
  };

  const handleDragEndEvent = (e: DragEvent<HTMLDivElement>) =>
  {
    e.currentTarget.classList.remove('opacity-50');
    handleDragEnd();
  };

  const handleDragOverEvent = (e: DragEvent<HTMLDivElement>) =>
  {
    e.preventDefault();
    handleDragOver(point.id);
  };

  const handleDropEvent = (e: DragEvent<HTMLDivElement>) =>
  {
    e.preventDefault();
    handleDrop(point.id);
  };

  const handleAction = () => {
    if (point.status === 'empty' && point.type === 'via') {
      onDelete();
    } else {
      // Clear the point by setting location to null
      onLocationSelect(null);
    }
  };

  return (
    <LocationInput
      label={label}
      pointNumber={pointNumber}
      pointText={pointText}
      isPontStartOrEnd={isStartOrEnd}
      point={point}
      initialLocation={point.location}
      mapCenter={mapCenter}
      onLocationSelect={onLocationSelect}
      onAction={handleAction}
      onBlur={onBlur}
      disabled={isCalculating}
      isCalculating={isCalculating}
      badgeProps={{
        draggable: isDraggable,
        onDragStart: handleDragStartEvent,
        onDragEnd: handleDragEndEvent,
        onDragOver: handleDragOverEvent,
        onDrop: handleDropEvent,
        className: `
          ${isDraggable ? 'cursor-grab active:cursor-grabbing' : ''}
          ${draggedId === point.id ? 'dragging' : ''}
          ${draggedOverId === point.id ? 'drop-target' : ''}
        `
      }}
    />
  );
};
