import { useCallback } from 'react';
import { useRouteStore } from '../../../../store/useRouteStore';
import type { OptimizationType, RoutingMethod, TrafficMode, TransportMode, TruckSpecFormData } from '../types';
import type { AvoidFeatures } from '../../../../services/routing/request-types';

// Default truck profile values
const defaultTruckProfile: TruckSpecFormData = {
  height: 400,  // cm
  width: 255,   // cm
  length: 1200, // cm
  weight: 3.5,  // t
  maxSpeed: 90  // km/h
};

export function useRouteOptions() {
  const {
    setRouteConfig,
    routeConfig: {
      avoidFeatures,
      optimizationType,
      isReturnTrip,
      transportMode,
      routingMethod,
      trafficMode,
      trafficHistoryDate,
      truckProfile,
      showRoutesheet
    }
  } = useRouteStore();

  const handleOptimizeTypeChange = useCallback((type: OptimizationType) => {
    // If changing to a non-normal optimization type, ensure return trip is off
    if (type !== 'none') {
      setRouteConfig({
        optimizationType: type,
        isReturnTrip: false  // Force return trip off for non-normal optimization
      });
    } else {
      // For normal order, just update the optimization type
      setRouteConfig({
        optimizationType: type
      });
    }
  }, [setRouteConfig]);

  const handleAvoidFeatureChange = useCallback((feature: keyof AvoidFeatures, value: boolean) => {
    setRouteConfig({
      avoidFeatures: {
        ...avoidFeatures,
        [feature]: value
      }
    });
  }, [avoidFeatures, setRouteConfig]);

  const handleToggleReturnTrip = useCallback(() => {
    // Only allow toggling if optimization type is "none" (normal order)
    if (optimizationType === 'none') {
      setRouteConfig({
        isReturnTrip: !isReturnTrip
      });
    }
  }, [optimizationType, isReturnTrip, setRouteConfig]);

  const handleRoutingMethodChange = useCallback((method: RoutingMethod) => {
    setRouteConfig({
      routingMethod: method
    });
  }, [setRouteConfig]);

  const handleTrafficModeChange = useCallback((mode: TrafficMode) => {
    setRouteConfig({
      trafficMode: mode
    });
  }, [setRouteConfig]);

  const handleTrafficHistoryDateChange = useCallback((date: Date | null) => {
    setRouteConfig({
      trafficHistoryDate: date
    });
  }, [setRouteConfig]);

  const handleTransportModeChange = useCallback((mode: TransportMode) => {
    setRouteConfig({
      transportMode: mode,
      // Use default truck profile when switching to TRUCK mode if no profile is set
      truckProfile: mode === 'TRUCK' ? (truckProfile || defaultTruckProfile) : null
    });
  }, [setRouteConfig, truckProfile]);

  const handleTruckProfileChange = useCallback((profile: TruckSpecFormData | null) => {
    setRouteConfig({
      truckProfile: profile
    });
  }, [setRouteConfig]);

  const handleToggleRoutesheet = useCallback(() => {
    setRouteConfig({
      showRoutesheet: !showRoutesheet
    });
  }, [showRoutesheet, setRouteConfig]);

  return {
    // Handlers
    handleOptimizeTypeChange,
    handleAvoidFeatureChange,
    handleToggleReturnTrip,
    handleRoutingMethodChange,
    handleTrafficModeChange,
    handleTrafficHistoryDateChange,
    handleTransportModeChange,
    handleTruckProfileChange,
    handleToggleRoutesheet,

    // Current values
    optimizationType,
    avoidFeatures,
    isReturnTrip,
    transportMode,
    routingMethod,
    trafficMode,
    trafficHistoryDate,
    truckProfile,
    showRoutesheet
  };
}

export type UseRouteOptimization = ReturnType<typeof useRouteOptions>;
