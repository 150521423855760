import { useRouteStore } from '../../../store/useRouteStore';
import { ErrorMessage } from './ErrorMessage';
import { DraggablePoint } from '../drag/DraggablePoint';
import { ViaPoints } from './ViaPoints';
import { RouteOptions } from '../options/RouteOptions';
import { RouteActions } from './RouteActions';
import { RouteDragProvider } from '../drag/RouteDragContext';
import { useRouteFormBase, type RouteFormState } from '../routingUtilities/hooks';
import { useRouteCalculation } from '../routingUtilities/hooks/useRouteCalculation';
import type { Coordinate } from '../../../services/common/types';
import './RouteForm.css';

interface RouteFormProps {
  mapCenter: Coordinate;
}

export function RouteForm({ mapCenter }: RouteFormProps) {
  const {
    getStartPoint,
    getEndPoint,
    getViaPoints,
    setPointLocation,
    hasEmptyPoints,
    routeConfig: {
      isReturnTrip,
      optimizationType,
      avoidFeatures,
      routingMethod,
      trafficMode,
      trafficHistoryDate,
      transportMode,
      truckProfile
    }
  } = useRouteStore();

  const startPoint = getStartPoint();
  const endPoint = getEndPoint();
  const viaPoints = getViaPoints();
  const hasEmptyPoint = hasEmptyPoints();

  const {
    state: routeState,
    setState,
    handleRouteStart,
    handleRouteStop,
    handleLocationDelete,
    handleAddViaPoint,
    handleAvoidFeatureChange,
    handleOptimizeTypeChange,
    handleToggleOptions,
    handleToggleReturnTrip,
    handleRoutingMethodChange,
    handleTrafficModeChange,
    handleTrafficHistoryDateChange,
    handleTransportModeChange,
    handleTruckProfileChange
  } = useRouteFormBase();

  const {
    state: calculationState,
    handleComputeRoute,
    isPending
  } = useRouteCalculation();

  const {
    isOptimizing,
    isRouteCalculating,
    hasInvalidPoint,
    showOptions
  } = routeState;

  // Compute button is enabled if:
  // 1. Start and end points have coordinates
  // 2. No invalid points
  // 3. No empty points in state
  // 4. Not currently calculating
  const canCompute = !!(startPoint?.location?.coordinate && endPoint?.location?.coordinate) &&
    !hasInvalidPoint &&
    !hasEmptyPoint &&
    !isRouteCalculating;

  // Can only add via point if start and end points are set and no empty points exist
  const canAddVia = !!(startPoint?.status === 'set' && endPoint?.status === 'set') && !hasEmptyPoint;

  const isCalculating = isPending || isOptimizing || isRouteCalculating;

  // Handle clearing points
  const handlePointClear = (point: typeof startPoint) => {
    if (!point) return;
    setPointLocation(point.id, null);
    handleLocationDelete();
  };

  return (
    <RouteDragProvider isCalculating={isCalculating}>
      <div className="absolute top-5 left-24 z-[1000] w-96 bg-white rounded-lg shadow-lg max-h-[calc(100vh-40px)] overflow-y-auto route-form">
        <div className="p-4 space-y-4">
          <ErrorMessage message={routeState.error || calculationState.error} />

          {startPoint && (
            <DraggablePoint
              point={startPoint}
              label=""
              pointText=""
              onLocationSelect={handleRouteStart}
              onDelete={() => handlePointClear(startPoint)}
              onBlur={() => {
                if (startPoint.location && !startPoint.location.coordinate) {
                  setState((prev: RouteFormState) => ({
                    ...prev,
                    error: 'Please select valid locations for all points'
                  }));
                }
              }}
              mapCenter={mapCenter}
              pointNumber={0}
              isCalculating={isCalculating}
            />
          )}

          <ViaPoints
            mapCenter={mapCenter}
            isCalculating={isCalculating}
            onValidationError={(message: string) =>
              setState((prev: RouteFormState) => ({ ...prev, error: message }))
            }
          />

          {endPoint && (
            <DraggablePoint
              point={endPoint}
              label=""
              pointText=""
              onLocationSelect={handleRouteStop}
              onDelete={() => handlePointClear(endPoint)}
              onBlur={() => {
                if (endPoint.location && !endPoint.location.coordinate) {
                  setState((prev: RouteFormState) => ({
                    ...prev,
                    error: 'Please select valid locations for all points'
                  }));
                }
              }}
              mapCenter={mapCenter}
              pointNumber={viaPoints.length + 1}
              isCalculating={isCalculating}
            />
          )}

          <RouteActions
            onAddViaPoint={handleAddViaPoint}
            onToggleOptions={handleToggleOptions}
            onToggleReturnTrip={handleToggleReturnTrip}
            isReturnTrip={isReturnTrip}
            canCompute={canCompute}
            canAddVia={canAddVia}
            onCompute={handleComputeRoute}
            optimizationType={optimizationType}
            showOptions={showOptions}
          />

          <RouteOptions
            // Optimization
            optimizationType={optimizationType}
            onOptimizeTypeChange={handleOptimizeTypeChange}
            hasViaPoints={viaPoints.length > 0}
            isReturnTrip={isReturnTrip}

            // Transport
            transportMode={transportMode}
            onTransportModeChange={handleTransportModeChange}
            truckProfile={truckProfile}
            onTruckProfileChange={handleTruckProfileChange}

            // Traffic
            trafficMode={trafficMode}
            trafficHistoryDate={trafficHistoryDate}
            onTrafficModeChange={handleTrafficModeChange}
            onTrafficHistoryDateChange={handleTrafficHistoryDateChange}

            // Routing method
            routingMethod={routingMethod}
            onRoutingMethodChange={handleRoutingMethodChange}

            // Avoid features
            avoidFeatures={avoidFeatures}
            onAvoidFeatureChange={handleAvoidFeatureChange}

            // Visibility
            isVisible={showOptions}
          />
        </div>
      </div>
    </RouteDragProvider>
  );
}
