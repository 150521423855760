import { useState } from 'react';
import { useRouteStore } from '../../../store/useRouteStore';
import { exportRouteToPDF } from '../export/exportRoutePDF';
import { RouteDetails } from './RouteDetails'; import type { LatLngBoundsLiteral } from 'leaflet';

export const RouteResultsPanel = () =>
{
  const [isExporting, setIsExporting] = useState(false);
  const [exportProgress, setExportProgress] = useState('');

  const {
    getStartPoint,
    getEndPoint,
    getViaPoints,
    routeResults
  } = useRouteStore();

  const startPoint = getStartPoint();
  const endPoint = getEndPoint();
  const viaPoints = getViaPoints();

  const route = routeResults?.routes[0];

  if (!route || !startPoint?.location || !endPoint?.location || !routeResults?.usedConfig)
  {
    return null;
  }

  const bounds: LatLngBoundsLiteral = [
    [startPoint.location.coordinate.latitude, startPoint.location.coordinate.longitude],
    [endPoint.location.coordinate.latitude, endPoint.location.coordinate.longitude],
  ];

  const handleExport = async () =>
  {
    try
    {
      setIsExporting(true);
      setExportProgress('Préparation du PDF...');

      // Small delay to ensure UI updates
      await new Promise(resolve => setTimeout(resolve, 100));

      // Get routing instructions if available
      const routingInstructions = route.routingInstructions;

      setExportProgress('Génération du PDF...');
      await exportRouteToPDF(routeResults, startPoint, endPoint, viaPoints, routingInstructions);

      setExportProgress('PDF téléchargé!');
      // Show success message briefly
      setTimeout(() =>
      {
        setIsExporting(false);
        setExportProgress('');
      }, 1500);
    } catch (error)
    {
      console.error('Error exporting PDF:', error);
      setExportProgress('Erreur lors de l\'export');
      setTimeout(() =>
      {
        setIsExporting(false);
        setExportProgress('');
      }, 1500);
    }
  };

  return (
    <div className="absolute top-5 right-5 z-[1000] bg-white rounded-xl shadow-xl max-h-[calc(100vh-7rem)] overflow-auto
      [&::-webkit-scrollbar]:w-1.5
      [&::-webkit-scrollbar-track]:bg-transparent
      [&::-webkit-scrollbar-thumb]:bg-blue-600
      [&::-webkit-scrollbar-thumb]:rounded-full
      border border-gray-200"
    >
      <RouteDetails
        route={route}
        bounds={bounds}
        via={viaPoints}
        start={startPoint.location.place}
        end={endPoint.location.place}
        routeConfig={routeResults.usedConfig}
        className="route-details"
      />

      <div className="p-4 pt-0 pb-5">
        <button
          onClick={handleExport}
          disabled={isExporting}
          className={`w-full px-4 py-3 ${isExporting ? 'bg-blue-500' : 'bg-blue-700 hover:bg-blue-800'} text-white rounded-lg flex items-center justify-center transition-colors shadow-md`}
          title="Export route to PDF"
        >
          {isExporting ? (
            <>
              <svg className="w-5 h-5 mr-2 animate-spin" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <circle cx="12" cy="12" r="10" strokeOpacity="0.25" />
                <path d="M12 2a10 10 0 0 1 10 10" strokeLinecap="round" />
              </svg>
              <span className="font-medium">{exportProgress}</span>
            </>
          ) : (
            <>
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 10l5 5 5-5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 15V3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="font-medium">Export Route to PDF</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};
