import type { RoutingInstruction } from '../../../../services/routing/response-types';
import { POINT_COLORS } from '../../routingUtilities/colors';
import { maneuverList } from '../svg/directions';
import { formatDistance, formatDuration } from '../utils/formatters';

// Maximum number of instructions per page
const MAX_INSTRUCTIONS_PER_PAGE = 10

export const createInstructionsHTML = (
    instructions: RoutingInstruction[],
    pageIndex: number = 0
): HTMLDivElement =>
{
    // Initialize viaIndex inside the function to reset it on each PDF generation
    let viaIndex = 0;

    // Get turn icon based on instruction type
    const getTurnIcon = (instruction: RoutingInstruction, isLast: boolean) =>
    {
        const maneuver = instruction?.manoeuvre;

        // Extract maneuver type from instruction text or use a default
        // const text = instruction.text?.toLowerCase() || '';
        if (isLast)
        {
            return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
            <circle cx="12" cy="10" r="3"></circle>
        </svg>`;
        }
        else if (!maneuver) return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><circle fill="#5A77A2" cx="12" cy="12" r="8"/></svg>`;
        else return maneuverList[maneuver];
    };

    // Filter out instructions with missing required properties
    const validInstructions = instructions.filter(instruction =>
        instruction &&
        typeof instruction.length === 'number' &&
        typeof instruction.duration === 'number'
    );

    // Pre-process instructions to find all STOP_VIA points and assign them indices
    // This ensures consistent numbering across pages
    const viaPointIndices = new Map();
    validInstructions.forEach((instruction, idx) =>
    {
        if (instruction.type === 'STOP_VIA')
        {
            viaIndex++;
            viaPointIndices.set(idx, viaIndex);
        }
    });

    // Reset viaIndex for the actual rendering
    viaIndex = 0;

    // Calculate total distance
    let totalDistance = 0;
    validInstructions.forEach(instruction =>
    {
        totalDistance += instruction.length;
    });

    // Calculate total pages
    const totalPages = Math.ceil(validInstructions.length / MAX_INSTRUCTIONS_PER_PAGE);

    // Get instructions for current page
    const startIdx = pageIndex * MAX_INSTRUCTIONS_PER_PAGE;
    const endIdx = Math.min(startIdx + MAX_INSTRUCTIONS_PER_PAGE, validInstructions.length);
    const pageInstructions = validInstructions.slice(startIdx, endIdx);

    // Generate HTML for each instruction
    let instructionsHTML = '';
    let cumulativeDistance = 0;

    // Calculate cumulative distance up to the start of this page
    for (let i = 0; i < startIdx; i++)
    {
        cumulativeDistance += validInstructions[i].length;
    }

    pageInstructions.forEach((instruction, index) =>
    {
        const globalIndex = startIdx + index;
        cumulativeDistance += instruction.length;

        const distancePercentage = Math.round((cumulativeDistance / totalDistance) * 100);

        const isFirst = globalIndex === 0;
        const isLast = globalIndex === validInstructions.length - 1;
        const isStopVia = instruction.type === 'STOP_VIA';

        // Determine background color based on instruction type
        let bgColor = '#f1f5f9';
        let borderColor = '#0ea5e9';
        let iconBgColor = '#bae6fd';
        let iconColor = '#0284c7';

        // Create a copy of the instruction text that we can modify
        let instructionText = instruction.text || 'Instruction non disponible';

        if (isFirst)
        {
            bgColor = POINT_COLORS.start + '33';
            iconBgColor = POINT_COLORS.start + '33';
            borderColor = POINT_COLORS.start;
            iconColor = POINT_COLORS.start;
        }
        else if (isLast)
        {
            bgColor = POINT_COLORS.end + '33';
            iconBgColor = POINT_COLORS.end + '33';
            borderColor = POINT_COLORS.end;
            iconColor = POINT_COLORS.end;
        }
        else if (isStopVia)
        {
            // Use the pre-calculated index for this via point
            const currentViaIndex = viaPointIndices.get(globalIndex);

            // Calculate color index based on the via point index
            const colorIndex = (currentViaIndex - 1) % POINT_COLORS.via.length;
            const viaColor = POINT_COLORS.via[colorIndex];

            bgColor = viaColor + '33';
            iconBgColor = viaColor + '33';
            borderColor = viaColor;
            iconColor = viaColor;

            // Add the via point index to the instruction text
            instructionText = instruction.text + ` (${currentViaIndex})`;
        }

        instructionsHTML += `
            <div style="display: flex; margin-bottom: ${index === pageInstructions.length - 1 ? '0' : '20px'}; position: relative;">
                ${index !== pageInstructions.length - 1 ? `
                <div style="position: absolute; top: 30px; bottom: 0; left: 15px; width: 2px; background: #cbd5e1; z-index: 1;"></div>
                ` : ''}

                <div style="width: 30px; height: 30px; background: ${isFirst ? iconBgColor : isLast ? iconBgColor : iconBgColor}; border-radius: 50%; display: grid; justify-content: center; align-items: center; color: ${isFirst ? iconColor : isLast ? iconColor : iconColor}; margin-right: 10px; z-index: 2; flex-shrink: 0;">
                    ${getTurnIcon(instruction, isLast)}
                </div>

                <div style="flex: 1; background: ${bgColor}; border-radius: 8px; padding: 6px 8px; border-left: 3px solid ${borderColor};">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 4px;">
                        <div style="font-size: 13px; font-weight: ${isFirst || isLast ? 'bold' : 'normal'}; color: #0f172a;">
                            <span style="color: #0369a1; font-size: 11px; margin-right: 4px; font-weight: bold;">${globalIndex + 1}.</span>
                            ${instructionText || 'Instruction non disponible'}
                        </div>
                        <div style="font-size: 12px; color: #0369a1; white-space: nowrap; margin-left: 6px; font-weight: 500;">
                            ${instruction.textDist || formatDistance(instruction.length)}
                        </div>
                    </div>

                    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 4px;">
                        <div style="flex: 1; height: 4px; background: #e2e8f0; border-radius: 2px; overflow: hidden; margin-right: 6px;">
                            <div style="height: 100%; width: ${distancePercentage}%; background: ${borderColor};"></div>
                        </div>
                        <div style="font-size: 11px; color: #0369a1; font-weight: 500;">
                            ${formatDuration(instruction.duration)}
                        </div>
                    </div>
                </div>
            </div>
        `;
    });

    const html = `
        <div style="display: flex; flex-direction: column; padding: 15px; background: white; border-radius: 8px; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); margin-top: 15px; width: 100%;">
            <div style="font-size: 18px; font-weight: bold; color: #0f172a; margin-bottom: 15px; border-bottom: 2px solid #e2e8f0; padding-bottom: 8px; display: flex; justify-content: space-between; align-items: center;">
                <span>Instructions de navigation</span>
                <span style="font-size: 14px; color: #0369a1; font-weight: 500;">Page ${pageIndex + 1}/${totalPages} (${validInstructions.length} étapes)</span>
            </div>

            <div style="display: flex; flex-direction: column;">
                ${instructionsHTML}
            </div>

            ${totalPages > 1 ? `
            <div style="display: flex; justify-content: space-between; margin-top: 15px; padding-top: 10px; border-top: 1px solid #e2e8f0;">
                <div style="font-size: 14px; color: #0369a1; font-weight: 500;">
                    Étapes ${startIdx + 1} à ${endIdx} sur ${validInstructions.length}
                </div>
                <div style="font-size: 14px; color: #0369a1; font-weight: 500;">
                    Page ${pageIndex + 1} sur ${totalPages}
                </div>
            </div>
            ` : ''}
        </div>
    `;

    const div = document.createElement('div');
    div.innerHTML = html;
    return div;
};

// Helper function to get the number of pages needed for instructions
export const getInstructionsPagesCount = (instructions: RoutingInstruction[]): number =>
{
    const validInstructions = instructions.filter(instruction =>
        instruction &&
        typeof instruction.length === 'number' &&
        typeof instruction.duration === 'number'
    );

    return Math.ceil(validInstructions.length / MAX_INSTRUCTIONS_PER_PAGE);
};