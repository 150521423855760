import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import type { MapControllerProps } from './types';
import type { LatLngExpression } from 'leaflet';

export function MapController({ center, onCenterChange, bounds }: MapControllerProps) {
  const map = useMap();

  // Handle bounds and center updates
  useEffect(() => {
    try {
      if (bounds && bounds.length > 1) {
        // If we have multiple points, fit the map to show all points
        map.fitBounds(bounds, {
          padding: [50, 50], // Add padding around bounds
          maxZoom: 13, // Don't zoom in too far
          animate: true,
          duration: 0.5
        });
      } else if (center) {
        // For single point, center on it
        map.setView(center as LatLngExpression, 13, {
          animate: true,
          duration: 0.5
        });
      }
    } catch (error) {
      console.error('Error updating map view:', error);
    }
  }, [bounds, center, map]);

  // Handle map move events
  useEffect(() => {
    const handleMoveEnd = () => {
      try {
        const mapCenter = map.getCenter();
        onCenterChange({
          lat: mapCenter.lat,
          lon: mapCenter.lng
        });
      } catch (error) {
        console.error('Error handling map move:', error);
      }
    };

    map.on('moveend', handleMoveEnd);
    return () => {
      map.off('moveend', handleMoveEnd);
    };
  }, [map, onCenterChange]);

  return null;
}
