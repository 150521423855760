import { useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient, { onAuthStateChange } from '../../api/apiClient';
import { AuthContext } from './AuthContext';
import { AuthNotification } from '../components/AuthNotification';
import { useSessionKeepAlive } from '../hooks/useSessionKeepAlive';

// Get stored user from localStorage
const getStoredUser = () => {
  const userStr = localStorage.getItem('user');
  try {
    return userStr ? JSON.parse(userStr) : null;
  } catch {
    return null;
  }
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !!getStoredUser();
  });
  const [showNotification, setShowNotification] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate();

  // Initialize session keepalive
  useSessionKeepAlive();

  useEffect(() => {
    // Set initial auth state based on stored user
    const user = getStoredUser();
    if (user) {
      apiClient.setAuthenticated(true);
    }

    // Subscribe to auth state changes first
    const unsubscribe = onAuthStateChange((newState) => {
      console.log('Auth state changed:', { newState, initialLoad: initialLoad });
      setIsAuthenticated(newState);

      // Only show notification and redirect if:
      // 1. This is NOT the first load of the app
      // 2. The auth state is changing to false (logout)
      if (!newState && !initialLoad)
      {
        localStorage.removeItem('user');
        setShowNotification(true);
        // Redirect after notification duration
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 3000); // Wait for 3 seconds
      }
    });

    // Mark initial load as complete after the effect runs
    // This ensures we don't show notifications during initial auth check
    setTimeout(() =>
    {
      setInitialLoad(false);
      console.log('Initial load completed');
    }, 1000);

    return unsubscribe;
  }, [navigate]);

  const logout = () => {
    localStorage.removeItem('user');
    apiClient.clearAuth();
    navigate('/login', { replace: true });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, logout }}>
      {showNotification && (
        <AuthNotification
          message="Votre session a expiré. Vous allez être redirigé vers la page de connexion."
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
      {children}
    </AuthContext.Provider>
  );
}