import { useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import type { AuthResponse } from '../../services/auth/response-types';

const SESSION_CHECK_ENDPOINT = 'service/acl/1.0/user/details';
const KEEPALIVE_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds

export const useSessionKeepAlive = () => {
  const { keepSession } = apiClient.getConfig();

  return useQuery({
    queryKey: ['session', 'keepalive', SESSION_CHECK_ENDPOINT],
    queryFn: () => apiClient.get<AuthResponse>({ url: SESSION_CHECK_ENDPOINT }),
    meta: {
      endpoint: SESSION_CHECK_ENDPOINT
    },
    enabled: keepSession && !!localStorage.getItem('user'),
    refetchInterval: KEEPALIVE_INTERVAL,
    staleTime: KEEPALIVE_INTERVAL,
    retry: false,
    refetchOnWindowFocus: true
  });
};