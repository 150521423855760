import type { RouteConfig } from '../../routingUtilities/types';
import { formatDate } from '../utils/formatters';

export const createConfigurationHTML = (config: RouteConfig): HTMLDivElement =>
{
    // Get transport mode icon and translated name
    const getTransportModeInfo = (mode: string) =>
    {
        let icon = '';
        let name = '';

        switch (mode)
        {
            case 'CAR':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M14 16H9m10 0h3v-3.15a1 1 0 00-.84-.99L16 11l-2.7-3.6a1 1 0 00-.8-.4H5.24a2 2 0 00-1.8 1.1l-.8 1.63A6 6 0 002 12.42V16h2"></path>
                    <circle cx="6.5" cy="16.5" r="2.5"></circle>
                    <circle cx="16.5" cy="16.5" r="2.5"></circle>
                </svg>`;
                name = 'Voiture';
                break;
            case 'TRUCK':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M10 17h4V5H2v12h3"></path>
                    <path d="M20 17h2v-3.34a4 4 0 0 0-1.17-2.83L19 9h-5v8h1"></path>
                    <circle cx="7.5" cy="17.5" r="2.5"></circle>
                    <circle cx="17.5" cy="17.5" r="2.5"></circle>
                </svg>`;
                name = 'Camion';
                break;
            case 'PUBLIC_BUS':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M19 17h2l.64-2.54c.24-.959.24-1.96 0-2.92l-1.07-4.27A3 3 0 0 0 17.66 5H4a2 2 0 0 0-2 2v10h2"></path>
                    <path d="M14 17H9"></path>
                    <circle cx="6.5" cy="17.5" r="2.5"></circle>
                    <circle cx="16.5" cy="17.5" r="2.5"></circle>
                </svg>`;
                name = 'Bus';
                break;
            case 'TAXI':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M18 8h1a4 4 0 0 1 4 4v6h-2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1H8v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1H1v-6a4 4 0 0 1 4-4h1"></path>
                    <path d="M4 11v3h16v-3"></path>
                    <path d="m12 2 4 6H8l4-6z"></path>
                </svg>`;
                name = 'Taxi';
                break;
            case 'BICYCLE':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="5.5" cy="17.5" r="3.5"></circle>
                    <circle cx="18.5" cy="17.5" r="3.5"></circle>
                    <path d="M15 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3 11.5V14l-3-3 4-3 2 3h2"></path>
                </svg>`;
                name = 'Vélo';
                break;
            default:
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M12 8v4"></path>
                    <path d="M12 16h.01"></path>
                </svg>`;
                name = mode;
                break;
        }

        return { icon, name };
    };

    // Get routing method name
    const getRoutingMethodName = (method: string) =>
    {
        switch (method)
        {
            case 'FASTEST': return 'Le plus rapide';
            case 'SHORTEST': return 'Le plus court';
            case 'ECO_ENERGY': return 'Économie d\'énergie';
            default: return method;
        }
    };

    // Get traffic mode icon and name
    const getTrafficInfo = (mode: string) =>
    {
        let icon = '';
        let name = '';
        let color = '';

        switch (mode)
        {
            case 'live':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>`;
                name = 'Trafic en temps réel';
                color = '#16a34a';
                break;
            case 'historical':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                </svg>`;
                name = 'Trafic historique';
                color = '#f59e0b';
                break;
            default:
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>`;
                name = 'Sans trafic';
                color = '#0ea5e9';
                break;
        }

        return { icon, name, color };
    };

    // Get optimization type name and details
    const getOptimizationInfo = (type: string) =>
    {
        let name = '';
        let details = '';

        switch (type)
        {
            case 'optimized':
                name = 'Optimisé';
                details = 'Points de départ et d\'arrivée fixes';
                break;
            case 'openEnded':
                name = 'Ouvert';
                details = 'Point de départ fixe';
                break;
            default:
                name = 'Aucune optimisation';
                details = 'Tous les points sont fixes';
                break;
        }

        return { name, details };
    };

    // Generate truck profile HTML if applicable
    const getTruckProfileHTML = () =>
    {
        if (config.transportMode !== 'TRUCK' || !config.truckProfile)
        {
            return '';
        }

        const profile = config.truckProfile;

        return `
            <div style="margin-top: 15px; background: #e0f2fe; border-radius: 8px; padding: 12px;">
                <div style="font-size: 16px; font-weight: bold; color: #0f172a; margin-bottom: 10px;">
                    Profil du camion
                </div>
                <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); gap: 10px;">
                    <div style="background: white; padding: 8px; border-radius: 6px; border-left: 3px solid #0ea5e9;">
                        <div style="font-size: 12px; color: #0369a1; font-weight: 500;">Hauteur</div>
                        <div style="font-size: 14px; font-weight: bold;">${profile.height} cm</div>
                    </div>
                    <div style="background: white; padding: 8px; border-radius: 6px; border-left: 3px solid #0ea5e9;">
                        <div style="font-size: 12px; color: #0369a1; font-weight: 500;">Largeur</div>
                        <div style="font-size: 14px; font-weight: bold;">${profile.width} cm</div>
                    </div>
                    <div style="background: white; padding: 8px; border-radius: 6px; border-left: 3px solid #0ea5e9;">
                        <div style="font-size: 12px; color: #0369a1; font-weight: 500;">Longueur</div>
                        <div style="font-size: 14px; font-weight: bold;">${profile.length} cm</div>
                    </div>
                    <div style="background: white; padding: 8px; border-radius: 6px; border-left: 3px solid #0ea5e9;">
                        <div style="font-size: 12px; color: #0369a1; font-weight: 500;">Poids</div>
                        <div style="font-size: 14px; font-weight: bold;">${profile.weight} t</div>
                    </div>
                    ${profile.maxSpeed ? `
                    <div style="background: white; padding: 8px; border-radius: 6px; border-left: 3px solid #0ea5e9;">
                        <div style="font-size: 12px; color: #0369a1; font-weight: 500;">Vitesse max</div>
                        <div style="font-size: 14px; font-weight: bold;">${profile.maxSpeed} km/h</div>
                    </div>
                    ` : ''}
                </div>
            </div>
        `;
    };

    // Generate avoided features HTML
    const getAvoidedFeaturesHTML = () =>
    {
        const avoidFeatures = config.avoidFeatures;
        if (!Object.values(avoidFeatures).some(v => v))
        {
            return '';
        }

        const features = [];

        if (avoidFeatures.tolls)
        {
            features.push({
                name: 'Péages',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                </svg>`
            });
        }

        if (avoidFeatures.motorways)
        {
            features.push({
                name: 'Autoroutes',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                </svg>`
            });
        }

        if (avoidFeatures.ferries)
        {
            features.push({
                name: 'Ferries',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M3 10h18c-.5-5.5-2.5-10-9-10-6.5 0-8.5 4.5-9 10z"></path>
                    <path d="M7 10v2a5 5 0 0 0 10 0v-2"></path>
                    <path d="M2 20h20"></path>
                </svg>`
            });
        }

        if (avoidFeatures.unpavedRoads)
        {
            features.push({
                name: 'Routes non pavées',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M2 9h20"></path>
                    <path d="M14 4l-7 5"></path>
                    <path d="M9 4l-7 5"></path>
                    <path d="M22 13l-7 5"></path>
                    <path d="M17 13l-7 5"></path>
                </svg>`
            });
        }

        if (avoidFeatures.borderCrossings)
        {
            features.push({
                name: 'Passages frontaliers',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M12 22v-6"></path>
                    <path d="M9 18h6"></path>
                    <path d="M5 12a7 7 0 0 1 14 0"></path>
                    <path d="M5 12v-2"></path>
                    <path d="M19 12v-2"></path>
                    <path d="M5 8v2"></path>
                    <path d="M19 8v2"></path>
                </svg>`
            });
        }

        let featuresHTML = '';
        features.forEach(feature =>
        {
            featuresHTML += `
                <div style="display: flex; align-items: center; background: #fee2e2; padding: 8px 12px; border-radius: 6px; margin-right: 8px; margin-bottom: 8px;">
                    <div style="color: #dc2626; margin-right: 6px; flex-shrink: 0;">
                        ${feature.icon}
                    </div>
                    <div style="font-size: 14px; color: #0f172a;">
                        ${feature.name}
                    </div>
                </div>
            `;
        });

        return `
            <div style="margin-top: 15px; background: #e0f2fe; border-radius: 8px; padding: 12px;">
                <div style="font-size: 16px; font-weight: bold; color: #0f172a; margin-bottom: 10px;">
                    Éléments évités
                </div>
                <div style="display: flex; flex-wrap: wrap;">
                    ${featuresHTML}
                </div>
            </div>
        `;
    };

    // Get transport mode info
    const transportModeInfo = getTransportModeInfo(config.transportMode);

    // Get traffic info
    const trafficInfo = getTrafficInfo(config.trafficMode);

    // Get optimization info
    const optimizationInfo = getOptimizationInfo(config.optimizationType);

    const html = `
        <div style="display: flex; flex-direction: column; padding: 15px; background: white; border-radius: 8px; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); margin-top: 15px; width: 100%;">
            <div style="font-size: 18px; font-weight: bold; color: #0f172a; margin-bottom: 15px; border-bottom: 2px solid #e2e8f0; padding-bottom: 8px;">
                Configuration de l'itinéraire
            </div>

            <!-- Transport Mode -->
            <div style="display: flex; align-items: center; margin-bottom: 15px;">
                <div style="width: 40px; height: 40px; background: #bae6fd; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: #0284c7; margin-right: 15px; flex-shrink: 0;">
                    ${transportModeInfo.icon}
                </div>
                <div>
                    <div style="font-size: 16px; font-weight: bold; color: #0f172a;">
                        Mode de transport: ${transportModeInfo.name}
                    </div>
                    <div style="font-size: 14px; color: #0369a1; font-weight: 500;">
                        Méthode: ${getRoutingMethodName(config.routingMethod)}
                    </div>
                </div>
            </div>

            <!-- Traffic Mode -->
            <div style="display: flex; align-items: center; margin-bottom: 15px;">
                <div style="width: 40px; height: 40px; background: #${trafficInfo.color.substring(1)}15; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: ${trafficInfo.color}; margin-right: 15px; flex-shrink: 0;">
                    ${trafficInfo.icon}
                </div>
                <div>
                    <div style="font-size: 16px; font-weight: bold; color: #0f172a;">
                        ${trafficInfo.name}
                    </div>
                    ${config.trafficMode === 'historical' && config.trafficHistoryDate ? `
                    <div style="font-size: 14px; color: #0369a1; font-weight: 500;">
                        Date: ${formatDate(new Date(config.trafficHistoryDate))}
                    </div>
                    ` : ''}
                </div>
            </div>

            <!-- Optimization -->
            <div style="display: flex; align-items: center; margin-bottom: 15px;">
                <div style="width: 40px; height: 40px; background: #bae6fd; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: #0284c7; margin-right: 15px; flex-shrink: 0;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M12 20h9"></path>
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                    </svg>
                </div>
                <div>
                    <div style="font-size: 16px; font-weight: bold; color: #0f172a;">
                        Optimisation: ${optimizationInfo.name}
                    </div>
                    <div style="font-size: 14px; color: #0369a1; font-weight: 500;">
                        ${optimizationInfo.details}
                    </div>
                    <div style="font-size: 14px; color: #0369a1; font-weight: 500;">
                        Aller-retour: ${config.isReturnTrip ? 'Oui' : 'Non'}
                    </div>
                </div>
            </div>

            <!-- Truck Profile if applicable -->
            ${getTruckProfileHTML()}

            <!-- Avoided Features -->
            ${getAvoidedFeaturesHTML()}
        </div>
    `;

    const div = document.createElement('div');
    div.innerHTML = html;
    return div;
};