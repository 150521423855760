import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { AuthRequest } from '../../services/auth/request-types';
import type { AuthResponse } from '../../services/auth/response-types';
import { useLoginMutation, AUTH_MUTATION_KEY } from '../../services/auth/mutation';
import type { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

interface ErrorResponse {
  message: string;
  status: number;
}

const Login = () => {
  const [credentials, setCredentials] = useState<AuthRequest>({
    username: '',
    password: '',
    remember: false,
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutate: login,
    isError,
    error,
    isPending,
    reset
  } = useLoginMutation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Reset any previous error states
    reset();

    // Log authentication attempt info (not credentials)
    console.log('Authentication attempt:', {
      baseUrl: import.meta.env.VITE_APP_BASE_API,
      withCredentials: true
    });

    login(credentials, {
      onSuccess: (data: AuthResponse) => {
        // Store user data in localStorage
        localStorage.setItem('user', JSON.stringify(data));

        // Update auth state
        apiClient.setAuthenticated(true);

        // Cache user data
        queryClient.setQueryData([AUTH_MUTATION_KEY, 'user'], data);

        // Navigate to map
        navigate('/map');
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        console.error('Login error details:', {
          message: error.message,
          code: error.code,
          response: error.response?.data,
          status: error.response?.status,
          baseUrl: import.meta.env.VITE_APP_BASE_API,
          origin: window.location.origin
        });
        localStorage.removeItem('user');
        apiClient.clearAuth();
      }
    });
  };

  const getErrorMessage = () => {
    if (!error) return '';

    if (error instanceof Error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      // Network error or server unreachable
      if (axiosError.code === 'ERR_NETWORK') {
        return `Server connection error. Please ensure the server is running at ${import.meta.env.VITE_APP_BASE_API}`;
      }
      // Server responded with error
      if (axiosError.response?.data?.message) {
        return axiosError.response.data.message;
      }
      // Other error with message
      if (axiosError.message) {
        return axiosError.message;
      }
    }

    return 'Failed to authenticate';
  };

  const errorMessage = getErrorMessage();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-blue-100">
      <div className="max-w-2xl w-full space-y-8 p-10 bg-white rounded-xl shadow-2xl transform transition-all duration-300 hover:scale-[1.01]">
        <div className="text-center">
          <div className="mx-auto h-23 w-50 p-4 flex items-center justify-center">
            <img
              src="/logob.png"
              alt="BeMap Logo"
              className="h-full w-full object-contain"
            />
          </div>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit} method="post" id="login-form">
          {isError && (
            <div className="animate-shake bg-red-50 border-l-4 border-red-500 p-4 rounded">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 001.414-1.414L11.414 10l1.293-1.293a1 1 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    {errorMessage}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Identifiant</label>
              <input
                id="username"
                name="username"
                type="text"
                required
                autoComplete="username"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-colors duration-200"
                placeholder="Entrer votre identifiant"
                value={credentials.username}
                onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
                disabled={isPending}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe</label>
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-colors duration-200"
                placeholder="Entrer votre mot de passe"
                value={credentials.password}
                onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                disabled={isPending}
              />
            </div>
          </div>

          <button
            type="submit"
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${isPending
              ? 'bg-indigo-400 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              } transition-colors duration-200`}
            disabled={isPending}
          >
            {isPending ? (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : null}
            {isPending ? 'Connexion...' : 'Connexion'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
