import { memo, useCallback } from 'react';
import type { ModuleType } from './types';
import { useAppStore } from '../../store/useAppStore';

const ModuleButton = ({ type, activeModule, onToggle }: {
  type: Exclude<ModuleType, null>;
  activeModule: ModuleType;
  onToggle: (type: Exclude<ModuleType, null>) => void;
}) => {
  const getIcon = () => {
    switch (type) {
      case 'route':
        return (
          <svg width="28" height="28" viewBox="0 0 24 24" fill={activeModule === type ? "#1e3a8a" : "none"} stroke={activeModule === type ? "white" : "#1e3a8a"} strokeWidth="2.5">
            <path
              d="M6 4 L6 8 C6 9 12 9 18 8 L18 12 C18 13 12 13 6 12 L6 16 C6 17 12 17 18 16 L18 20 L21 17 M18 20 L15 17"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'fleex':
        return (
          <svg width="28" height="28" viewBox="0 0 24 24" fill={activeModule === type ? "#1e3a8a" : "none"} stroke={activeModule === type ? "white" : "#1e3a8a"} strokeWidth="3">
            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
          </svg>
        );
      case 'ev':
        return (
          <svg width="28" height="28" viewBox="0 0 24 24" fill={activeModule === type ? "#1e3a8a" : "none"} stroke={activeModule === type ? "white" : "#1e3a8a"} strokeWidth="3">
            <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      case 'zone':
        return (
          <svg width="28" height="28" viewBox="0 0 24 24" fill={activeModule === type ? "#1e3a8a" : "none"} stroke={activeModule === type ? "white" : "#1e3a8a"} strokeWidth="3">
            <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2z" />
          </svg>
        );
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'route':
        return "Calcul d'itinéraire";
      case 'fleex':
        return "Création de tournée Fleex";
      case 'ev':
        return "Calcul d'itinéraire véhicule électrique";
      case 'zone':
        return "Calcul de zone atteignable";
      default:
        return "";
    }
  };

  return (
    <button
      onClick={() => onToggle(type)}
      className="w-14 h-14 bg-white shadow-lg flex items-center justify-center hover:bg-gray-50 rounded-full"
      title={getTitle()}
    >
      <div className={`p-2 rounded-full ${activeModule === type ? 'bg-blue-900' : ''}`}>
        {getIcon()}
      </div>
    </button>
  );
};

const MemoizedModuleButton = memo(ModuleButton);

export function MapModules() {
  const activeModule = useAppStore(state => state.activeModule);
  const setActiveModule = useAppStore(state => state.setActiveModule);

  const handleToggle = useCallback((type: Exclude<ModuleType, null>) => {
    setActiveModule(current => current === type ? null : type);
  }, [setActiveModule]);

  const modules: Exclude<ModuleType, null>[] = ['route', 'fleex', 'ev', 'zone'];

  return (
    <div className="absolute top-5 left-5 z-[1000] flex flex-col gap-4">
      {modules.map((type) => (
        <MemoizedModuleButton
          key={type}
          type={type}
          activeModule={activeModule}
          onToggle={handleToggle}
        />
      ))}
    </div>
  );
}
