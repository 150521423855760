import { memo, useEffect, useRef, useState } from "react";
import type { AvoidFeatures } from "../../../services/routing/request-types";
import type { OptimizationType, RoutingMethod, TrafficMode, TransportMode, TruckSpecFormData } from "../routingUtilities/types";
import { OptimizationOptions } from "./OptimizationOptions";
import { TransportOptions } from "./TransportOptions";
import { RoutingMethodOptions } from "./RoutingMethodOptions";
import { TrafficOptions } from "./TrafficOptions";
import { AvoidFeatureOptions } from "./AvoidFeatureOptions";

interface RouteOptionsProps {
  // Optimization
  optimizationType: OptimizationType;
  onOptimizeTypeChange: (type: OptimizationType) => void;
  hasViaPoints: boolean;
  isReturnTrip: boolean;

  // Transport
  transportMode: TransportMode;
  onTransportModeChange: (mode: TransportMode) => void;
  truckProfile: TruckSpecFormData | null;
  onTruckProfileChange: (profile: TruckSpecFormData | null) => void;

  // Traffic
  trafficMode: TrafficMode;
  trafficHistoryDate: Date | null;
  onTrafficModeChange: (mode: TrafficMode) => void;
  onTrafficHistoryDateChange: (date: Date | null) => void;

  // Routing method
  routingMethod: RoutingMethod;
  onRoutingMethodChange: (method: RoutingMethod) => void;

  // Avoid features
  avoidFeatures: AvoidFeatures;
  onAvoidFeatureChange: (feature: keyof AvoidFeatures, value: boolean) => void;

  // Visibility
  isVisible: boolean;
}

export const RouteOptions = memo(function RouteOptions({
  // Optimization
  optimizationType,
  onOptimizeTypeChange,
  hasViaPoints,
  isReturnTrip,
  // Transport
  transportMode,
  onTransportModeChange,
  truckProfile,
  onTruckProfileChange,
  // Traffic
  trafficMode,
  trafficHistoryDate,
  onTrafficModeChange,
  onTrafficHistoryDateChange,
  // Routing method
  routingMethod,
  onRoutingMethodChange,
  // Avoid features
  avoidFeatures,
  onAvoidFeatureChange,
  // Visibility
  isVisible
}: RouteOptionsProps) {
  const [animationClass, setAnimationClass] = useState('route-options-enter');
  const [shouldRender, setShouldRender] = useState(isVisible);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() =>
  {
    if (isVisible)
    {
      // When opening, render immediately and start animation
      setShouldRender(true);
      setAnimationClass('route-options-enter');

      // Trigger reflow to ensure animation works
      setTimeout(() =>
      {
        setAnimationClass('route-options-enter-active');
      }, 10);
    } else
    {
      // When closing, start exit animation
      setAnimationClass('route-options-exit');

      setTimeout(() =>
      {
        setAnimationClass('route-options-exit-active');

        // Wait for animation to complete before removing from DOM
        setTimeout(() =>
        {
          setShouldRender(false);
        }, 200); // Match the transition duration in CSS
      }, 10);
    }
  }, [isVisible]);

  if (!shouldRender)
  {
    return null;
  }

  return (
    <div className={`route-options-container ${animationClass}`} ref={contentRef}>
      <div className="p-4 bg-gradient-to-b from-gray-50 to-white rounded-lg shadow-lg border border-gray-200 relative">
        <div className="-mt-2 pt-2 space-y-4">
          {hasViaPoints && !isReturnTrip && (
            <OptimizationOptions
              optimizationType={optimizationType}
              onOptimizeTypeChange={onOptimizeTypeChange}
            />
          )}
          <TransportOptions
            selectedMode={transportMode}
            onModeChange={onTransportModeChange}
            truckProfile={truckProfile}
            onTruckProfileChange={onTruckProfileChange}
          />
          <TrafficOptions
            selectedMode={trafficMode}
            trafficHistoryDate={trafficHistoryDate}
            onModeChange={onTrafficModeChange}
            onTrafficHistoryDateChange={onTrafficHistoryDateChange}
          />
          <RoutingMethodOptions
            selectedMethod={routingMethod}
            onMethodChange={onRoutingMethodChange}
          />
          <AvoidFeatureOptions
            avoidFeatures={avoidFeatures}
            onAvoidFeatureChange={onAvoidFeatureChange}
          />
        </div>
      </div>
    </div>
  );
});
