// Request 

export enum SearchType
{
    CONTAINS = 'CONTAINS',
    FUZZY = 'FUZZY',
    KEY_SEARCH = 'KEY_SEARCH',
    STRICT = 'STRICT',
    STRICT_BEGINNING = 'STRICT_BEGINNING',
    WORD_BEGINNING = 'WORD_BEGINNING'
}

export enum AssetSearchType
{
    CITY_CENTER = 'CITY_CENTER',
    OBJECT = 'OBJECT',
    POI = 'POI',
    ROAD = 'ROAD'
}