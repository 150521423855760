import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import type { AuthRequest } from './request-types';
import type { AuthResponse } from './response-types';
import type { AxiosError } from 'axios';

interface ErrorResponse
{
  message: string;
  status: number;
}

const AUTH_ENDPOINT = 'service/acl/1.0/user/details';
export const AUTH_MUTATION_KEY = 'login';

export const useLoginMutation = () =>
{
  const queryClient = useQueryClient();

  return useMutation<AuthResponse, AxiosError<ErrorResponse>, AuthRequest>({
    mutationKey: [AUTH_MUTATION_KEY, AUTH_ENDPOINT],
    meta: {
      endpoint: AUTH_ENDPOINT
    },
    mutationFn: async (credentials: AuthRequest) =>
    {
      try
      {
        // Check if API endpoint is properly configured
        if (!import.meta.env.DEV && !import.meta.env.VITE_APP_BASE_API)
        {
          console.error('API base URL not configured. Check your .env file.');
        }

        // Get the API base URL
        const apiBaseUrl = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';

        // Log the full API request URL
        console.log(`Attempting auth request to: ${apiBaseUrl}/${AUTH_ENDPOINT}`);

        // Make the actual authentication request
        const response = await apiClient.get<AuthResponse>({
          url: AUTH_ENDPOINT,
          auth: {
            username: credentials.username,
            password: credentials.password,
          },
        });

        // Only store auth data if we got a valid response
        if (response && response.username && response.rights)
        {
          localStorage.setItem('user', JSON.stringify(response));
          return response;
        }
        throw new Error('Invalid response format from server');
      } catch (error)
      {
        console.error('Login failed:', error);
        throw error;
      }
    },
    onSuccess: (data) =>
    {
      // Cache the user data
      queryClient.setQueryData([AUTH_MUTATION_KEY, 'user'], data);
    },
    onError: (error) =>
    {
      // Log the error for debugging
      console.error('Login mutation error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
    }
  });
};

// Helper function to get stored user data
export const getStoredUser = (): AuthResponse | null =>
{
  const stored = localStorage.getItem('user');
  if (stored)
  {
    try
    {
      return JSON.parse(stored);
    } catch
    {
      return null;
    }
  }
  return null;
};
