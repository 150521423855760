
// export type InstructionType = 'ENTER_MOTORWAY' | 'ENTER_ROUNDABOUT' | 'EXIT_MOTORWAY' | 'EXIT_ROUNDABOUT' | 'FOLLOW' | 'FOLLOW_SIGN' | 'LEAVE_FERRY' | 'STOP' | 'STOP_VIA' | 'TAKE_FERRY' | 'TAKE_RAMP';
// export type Maneuver = 'STRAIGHT' | 'SLIGHT_RIGHT' | 'SLIGHT_LEFT' | 'RIGHT' | 'LEFT' | 'SHARP_RIGHT' | 'SHARP_LEFT' | 'U_TURN' | 'BEAR_RIGHT' | 'BEAR_LEFT';
import { Maneuver } from "../../../../services/routing/response-types";

export type ExtendedManeuver = Maneuver & 'Fallback';

export const maneuverList: Record<Maneuver, string> = {
    "STRAIGHT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="m12 2-8 8h6v12h4V10h6z"/></svg>`,
    // Right turn
    "RIGHT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M12 2v6H4l-2 2v10h4v-8h6v6l10-9z"/></svg>`,
    "BEAR_RIGHT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="m4 2 5 5-5.9 5.9V22h3.4v-7.8l4.9-5.1 5.2 5.2V2z"/></svg>`,
    "SLIGHT_RIGHT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="m4 2 5 5-5.9 5.9V22h3.4v-7.8l4.9-5.1 5.2 5.2V2z"/></svg>`,
    "SHARP_RIGHT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M4 2 2 4v14h4V8l8 8-5 5h12V9l-5 5z"/></svg>`,
    // Left turn
    "LEFT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M12 2v6h8l2 2v10h-4v-8h-6v6L2 9z"/></svg>`,
    "BEAR_LEFT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M2 2v12l5-5 5 5v8h3v-9L9 7l5-5z"/></svg>`,
    "SLIGHT_LEFT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M2 2v12l5-5 5 5v8h3v-9L9 7l5-5z"/></svg>`,
    "SHARP_LEFT": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M18 2 8 12 3 7v12h12l-5-5 7-7v9h3V4z"/></svg>`,
    // Uturn
    "U_TURN": `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path fill="#5A77A2" d="M12 1C9.2 1 6.8 2 5.4 3.7 4 5.5 3.5 7.4 3.5 9.5V20h3.4V9.5c0-1.4.4-2.7 1.2-3.6C8.8 5 10 4.3 12 4.3s3.2.7 3.9 1.6 1.2 2.2 1.2 3.6v3.4H9.5L19 22l9.5-9.5h-6.9v-3c0-2.1-.6-4.1-1.9-5.8C17.2 2 14.8 1 12 1"/></svg>`,
}