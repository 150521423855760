import { MapContainer } from 'react-leaflet';
import type { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppStore } from '../../store/useAppStore';
import { useRouteStore } from '../../store/useRouteStore';
import type { Coordinate } from '../../services/common/types';
import { useGeocodingMutation, createGeocodingRequestFromAutocomplete } from '../../services/geocoding/mutation';
import { MapController } from './MapController';
import { MapMarkers } from './MapMarkers';
import { MapModules } from './MapModules';
import { MapControls } from './MapControls';
import { SearchBar } from '../geocoding/SearchBar';
import { LogoutButton } from './LogoutButton';
import { RouteForm, RouteResultsPanel } from '../routing';
import { RoutePolylines } from './RoutePolylines';
import { MapClickHandler } from './MapClickHandler';

// Paris coordinates (fallback center)
const PARIS_CENTER: LatLngTuple = [48.8566, 2.3522];

function Map()
{
  const [initialLocationSet, setInitialLocationSet] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<Coordinate>({
    lat: PARIS_CENTER[0],
    lon: PARIS_CENTER[1]
  });

  // App store selectors
  const {
    activeModule,
    isAutocompleteVisible,
    isRoutingVisible,
    geocodingLocation
  } = useAppStore(state => ({
    activeModule: state.activeModule,
    isAutocompleteVisible: state.isAutocompleteVisible,
    isRoutingVisible: state.isRoutingVisible,
    geocodingLocation: state.geocodingLocation
  }));

  // Route store selectors
  const {
    routeResults,
    getStartPoint,
    getEndPoint,
    getViaPoints,
    points,
    isFormVisible,
    isResultsVisible,
    toggleFormVisibility,
    toggleResultsVisibility
  } = useRouteStore();

  // Get user's location on mount
  useEffect(() =>
  {
    if ('geolocation' in navigator)
    {
      navigator.geolocation.getCurrentPosition(
        (position) =>
        {
          const newCenter = {
            lat: position.coords.latitude,
            lon: position.coords.longitude
          };
          setMapCenter(newCenter);
          setInitialLocationSet(true);
        },
        (error) =>
        {
          console.warn('Geolocation error:', error);
          setInitialLocationSet(true); // Use Paris as fallback
        },
        {
          timeout: 5000,
          maximumAge: 0,
          enableHighAccuracy: true
        }
      );
    } else
    {
      setInitialLocationSet(true); // No geolocation support, use Paris
    }
  }, []); // Run only on mount

  // Setup geocoding mutation for precise coordinates
  const geocodingMutation = useGeocodingMutation();

  useEffect(() =>
  {
    if (!geocodingLocation)
    {
      // Clear error when location is cleared
      setErrorMessage(null);
      return;
    }

    try
    {
      const request = createGeocodingRequestFromAutocomplete(geocodingLocation);
      geocodingMutation.mutate(request, {
        onSuccess: () =>
        {
          // Clear error on successful geocoding
          setErrorMessage(null);
        },
        onError: (error) =>
        {
          console.error('Geocoding error:', error);
          setErrorMessage('Failed to get precise location coordinates. Using approximate coordinates instead.');
        }
      });
    } catch (error)
    {
      console.error('Failed to process location:', error);
      setErrorMessage('Unable to process this location. Please try a different search.');
      // Reset to previous valid state
      const prevLocation = useAppStore.getState().geocodingLocation;
      if (prevLocation?.place !== geocodingLocation.place)
      {
        useAppStore.setState({ geocodingLocation: null });
      }
    }
  }, [geocodingLocation]);

  // Get search marker position
  const searchMarkerPosition = useMemo(() =>
  {
    try
    {
      // Only show search marker when no module is active
      if (!activeModule && geocodingLocation?.coordinate)
      {
        if (geocodingMutation.data?.elements?.[0]?.coordinate)
        {
          // Use precise geocoded coordinates if available
          return [
            geocodingMutation.data.elements[0].coordinate.lat,
            geocodingMutation.data.elements[0].coordinate.lon
          ] as LatLngTuple;
        }
        // Fall back to autocomplete coordinates
        return [
          geocodingLocation.coordinate.latitude,
          geocodingLocation.coordinate.longitude
        ] as LatLngTuple;
      }
      return null;
    } catch (error)
    {
      console.error('Error computing marker position:', error);
      return null;
    }
  }, [geocodingMutation.data, geocodingLocation, activeModule]);

  // Get current bounds from all points
  const mapBounds = useMemo(() =>
  {
    try
    {
      const positions: LatLngTuple[] = [];
      const start = getStartPoint();
      const end = getEndPoint();

      // Add points with coordinates to positions array
      if (start?.location?.coordinate) {
        positions.push([start.location.coordinate.latitude, start.location.coordinate.longitude]);
      }

      if (end?.location?.coordinate) {
        positions.push([end.location.coordinate.latitude, end.location.coordinate.longitude]);
      }

      // Add via points
      getViaPoints().forEach(point => {
        if (point.location?.coordinate) {
          positions.push([point.location.coordinate.latitude, point.location.coordinate.longitude]);
        }
      });

      // Add route points if available
      if (routeResults?.selectedPolyline) {
        routeResults.selectedPolyline.forEach(coord => {
          positions.push([coord.lat, coord.lon]);
        });
      }

      // If we have exactly one position, return null to let center handle it
      if (positions.length === 1) {
        return null;
      }

      // Return bounds only if we have multiple positions
      return positions.length > 1 ? positions : null;

    } catch (error)
    {
      console.error('Error computing bounds:', error);
      return null;
    }
  }, [getStartPoint, getEndPoint, getViaPoints, routeResults?.selectedPolyline, points]);

  // Get center point for single marker scenarios
  const centerPoint = useMemo(() =>
  {
    if (searchMarkerPosition && !activeModule)
    {
      return searchMarkerPosition;
    }

    const start = getStartPoint();
    if (start?.location?.coordinate)
    {
      return [start.location.coordinate.latitude, start.location.coordinate.longitude] as LatLngTuple;
    }

    const end = getEndPoint();
    if (end?.location?.coordinate)
    {
      return [end.location.coordinate.latitude, end.location.coordinate.longitude] as LatLngTuple;
    }

    const viaPoints = getViaPoints();
    const firstViaPoint = viaPoints[0];
    if (firstViaPoint?.location?.coordinate)
    {
      return [firstViaPoint.location.coordinate.latitude, firstViaPoint.location.coordinate.longitude] as LatLngTuple;
    }

    // Use first route point if available
    if (routeResults?.selectedPolyline?.[0])
    {
      const firstPoint = routeResults.selectedPolyline[0];
      return [firstPoint.lat, firstPoint.lon] as LatLngTuple;
    }

    return null;
  }, [searchMarkerPosition, activeModule, getStartPoint, getEndPoint, getViaPoints, routeResults?.selectedPolyline, points]);

  // Handle map center updates - used for search/geocoding
  const handleCenterChange = useCallback((center: Coordinate) =>
  {
    setMapCenter(center);
  }, []);

  if (!initialLocationSet)
  {
    return (
      <div className="h-screen w-full flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600">Getting your location...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full relative overflow-hidden">
      {/* Error message - positioned at bottom */}
      {errorMessage && (
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-[1000] bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg">
          {errorMessage}
        </div>
      )}

      {/* Search Bar - Show when autocomplete is visible */}
      {isAutocompleteVisible && (
        <SearchBar mapCenter={mapCenter} />
      )}

      {/* Routing UI */}
      {isRoutingVisible && (
        <>
          {/* Toggle Buttons - positioned above logout button */}
          <div className="absolute bottom-24 left-5 z-[1000] flex flex-col space-y-2">
            <button
              onClick={toggleFormVisibility}
              className={`w-14 h-14 shadow-lg flex items-center justify-center rounded-full transition-colors ${
                isFormVisible ? 'bg-blue-500 hover:bg-blue-600' : 'bg-white hover:bg-gray-50'
              }`}
              title="Toggle Route Form"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke={isFormVisible ? "#ffffff" : "#4b5563"} strokeWidth="2">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" strokeLinecap="round" strokeLinejoin="round"/>
                <line x1="7" y1="8" x2="17" y2="8" strokeLinecap="round" strokeLinejoin="round"/>
                <line x1="7" y1="12" x2="17" y2="12" strokeLinecap="round" strokeLinejoin="round"/>
                <line x1="7" y1="16" x2="13" y2="16" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
            <button
              onClick={toggleResultsVisibility}
              disabled={!routeResults}
              className={`w-14 h-14 shadow-lg flex items-center justify-center rounded-full transition-colors ${
                !routeResults ? 'bg-gray-200 cursor-not-allowed' :
                isResultsVisible ? 'bg-blue-500 hover:bg-blue-600' : 'bg-white hover:bg-gray-50'
              }`}
              title="Toggle Results Panel"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke={!routeResults ? "#9ca3af" : isResultsVisible ? "#ffffff" : "#4b5563"} strokeWidth="2">
                <path d="M3 12h4l3-9 4 18 3-9h4" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>

          {isFormVisible && <RouteForm mapCenter={mapCenter} />}
          {isResultsVisible && <RouteResultsPanel />}
        </>
      )}

      {/* Module Menu */}
      <MapModules />

      {/* Logout Button */}
      <LogoutButton />

      <MapContainer
        center={[mapCenter.lat, mapCenter.lon]}
        zoom={13}
        className="h-full w-full"
        scrollWheelZoom
        zoomControl={false}
      >
        <MapControls />
        <MapClickHandler />

        {/* Update map center and bounds */}
        <MapController
          center={centerPoint}
          bounds={mapBounds}
          onCenterChange={handleCenterChange}
        />

        {/* Show markers and route */}
        <MapMarkers searchMarkerPosition={searchMarkerPosition} />
        <RoutePolylines />
      </MapContainer>
    </div>
  );
}

export default Map;
