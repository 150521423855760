import { useCallback, useMemo } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { useRouteStore } from '../../../store/useRouteStore';
import { getPointIcon } from './markers';
import { useReverseGeocodingMutation } from '../../../services/geocoding/mutation';
import './markers.css';

export const RouteMarkers = () =>
{
  const {
    getStartPoint,
    getEndPoint,
    getViaPoints,
    setPointLocation,
    routeConfig: { optimizationType }
  } = useRouteStore();

  const startPoint = getStartPoint();
  const endPoint = getEndPoint();
  const viaPoints = getViaPoints();

  const reverseGeocodingMutation = useReverseGeocodingMutation();

  const handleDragEnd = useCallback(async (e: L.DragEndEvent) =>
  {
    const marker = e.target;
    const { lat, lng } = marker.getLatLng();
    const pointId = marker.getElement()?.getAttribute('data-point-id');

    if (!pointId) return;

    // Check if a route exists before updating the location
    const routeExisted = useRouteStore.getState().routeResults !== null;

    // Update position immediately
    setPointLocation(pointId, {
      coordinate: {
        latitude: lat,
        longitude: lng
      },
      place: 'Updating...',
      elemType: 'address',
      address: { city: 'Updating...' }
    });

    try
    {
      const response = await reverseGeocodingMutation.mutateAsync({
        coordinateSat: {
          lat,
          lon: lng
        },
        radius: 500,
        maximumResults: 1,
      });

      if (response.elements.length > 0)
      {
        const place = response.elements[0];
        const address = place.postalAddress;

        /*  "postalAddress": {
           "countryCode": "FRA",
           "country": "France",
           "state": "Provence-Alpes-Côte d'Azur",
           "county": "Alpes-Maritimes",
           "city": "Cagnes-sur-Mer",
           "postalCode": "06800",
           "roadNumber": "A8/E80",
           "street": "La Provençale"
       }, */
        // place nned to check for empty values and formatting , remove unnecessary commas
        let placeText = `${address.street || ''}, ${address.postalCode || ''} ${address.city || ''}, ${address.county || ''}, ${address.state || ''}, ${address.country || ''}`.replace(/,\s*,/g, ', ').replace(/,\s*$/, '').trim();
        // if place text has only one comma or empty show unkown setPointLocation

        if (placeText.split(',').length <= 1 || placeText.trim() === ',')
        {
          placeText = 'Unknown location';
        }
        // fon't show country code or country name by itself if there is no other data
        // trimm and remove uncessary commas first
        // check if trimmed comma removed placeText is only country code or country name or both

        // trim and remove comma and then check
        // trim both start and end and remove all comas
        const placetextwithoutcomma = placeText.replace(/,/g, '').trim();

        if (placetextwithoutcomma === address.countryCode || placetextwithoutcomma === address.country || placetextwithoutcomma === address.postalCode)
        {
          placeText = 'Unknown location';
        }

        setPointLocation(pointId, {
          coordinate: {
            latitude: lat,
            longitude: lng
          },
          // full address:
          place: placeText,
          elemType: 'address',
          address: address
        });

        // After updating the location, check if we should recalculate the route
        if (routeExisted)
        {
          // Check if all points have locations (no empty points)
          const hasEmptyPoints = useRouteStore.getState().hasEmptyPoints();

          if (!hasEmptyPoints)
          {
            useRouteStore.getState().setIsCalculating(true);
            // Get the calculation function from the store and trigger route calculation
            useRouteStore.getState().handleComputeRoute?.();
          }
        }
      }
      else
      {
        setPointLocation(pointId, {
          coordinate: {
            latitude: lat,
            longitude: lng
          },
          place: 'Unknown location',
          elemType: 'address',
          address: { city: 'Unknown location' }
        });
      }
    } catch (error)
    {
      console.error('Reverse geocoding failed:', error);
      setPointLocation(pointId, {
        coordinate: {
          latitude: lat,
          longitude: lng
        },
        place: 'Unknown location',
        elemType: 'address',
        address: { city: 'Unknown location' }
      });
    }
  }, [reverseGeocodingMutation, setPointLocation]);

  const routeMarkers = useMemo(() =>
  {
    const markers: JSX.Element[] = [];

    if (startPoint?.lat && startPoint?.lng)
    {
      markers.push(
        <Marker
          key={startPoint.id}
          data-point-id={startPoint.id}
          position={[startPoint.lat, startPoint.lng]}
          icon={getPointIcon('start', 0, startPoint.color)}
          draggable={true}
          eventHandlers={{
            dragend: handleDragEnd,
            add: (e) =>
            {
              const el = e.target.getElement();
              if (el) el.setAttribute('data-point-id', startPoint.id);
            }
          }}
        />
      );
    }

    viaPoints.forEach((point, idx) =>
    {
      if (point.lat && point.lng)
      {
        markers.push(
          <Marker
            key={point.id}
            data-point-id={point.id}
            position={[point.lat, point.lng]}
            icon={getPointIcon('via', idx + 1, point.color)}
            draggable={true}
            eventHandlers={{
              dragend: handleDragEnd,
              add: (e) =>
              {
                const el = e.target.getElement();
                if (el) el.setAttribute('data-point-id', point.id);
              }
            }}
          />
        );
      }
    });

    if (endPoint?.lat && endPoint?.lng)
    {
      const useNumberedEndMarker = optimizationType === 'openEnded';
      markers.push(
        <Marker
          key={endPoint.id}
          data-point-id={endPoint.id}
          position={[endPoint.lat, endPoint.lng]}
          icon={getPointIcon('end', viaPoints.length + 1, endPoint.color, useNumberedEndMarker)}
          draggable={true}
          eventHandlers={{
            dragend: handleDragEnd,
            add: (e) =>
            {
              const el = e.target.getElement();
              if (el) el.setAttribute('data-point-id', endPoint.id);
            }
          }}
        />
      );
    }

    return markers;
  }, [startPoint, endPoint, viaPoints, handleDragEnd, optimizationType]);

  return routeMarkers;
};
