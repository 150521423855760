import { create } from 'zustand'
import { useRouteStore } from './useRouteStore';
import type { ModuleType } from '../components/map/types'
import type { AutocompleteElem } from '../services/geocoding-autocomplete/response-types';

interface AppState {
  activeModule: ModuleType;
  isAutocompleteVisible: boolean;
  isRoutingVisible: boolean;
  isFleexVisible: boolean;
  isEvVisible: boolean;
  isZoneVisible: boolean;
  geocodingLocation: AutocompleteElem | null;

  // Actions
  setActiveModule: (moduleNameOrUpdater: ModuleType | ((current: ModuleType) => ModuleType)) => void;
  setGeocodingLocation: (location: AutocompleteElem | null) => void;
}

export const useAppStore = create<AppState>((set) => ({
  activeModule: null,
  isAutocompleteVisible: true,
  isRoutingVisible: false,
  isFleexVisible: false,
  isEvVisible: false,
  isZoneVisible: false,
  geocodingLocation: null,

  setGeocodingLocation: (location: AutocompleteElem | null) =>
    set({ geocodingLocation: location }),

  setActiveModule: (moduleNameOrUpdater: ModuleType | ((current: ModuleType) => ModuleType)) =>
    set((state) => {
      const moduleName = typeof moduleNameOrUpdater === 'function'
        ? moduleNameOrUpdater(state.activeModule)
        : moduleNameOrUpdater;

      // Only proceed if module actually changes
      if (moduleName === state.activeModule) return state;

      // Clear previous module state
      if (state.activeModule === 'route') {
        useRouteStore.getState().reset();
      }

      const newState: Partial<AppState> = {
        activeModule: moduleName,
        isAutocompleteVisible: moduleName === null,
        isRoutingVisible: moduleName === 'route',
        isFleexVisible: moduleName === 'fleex',
        isEvVisible: moduleName === 'ev',
        isZoneVisible: moduleName === 'zone',
      };

      // Clear geocoding location when switching to a non-geocoding module
      if (moduleName !== null) {
        newState.geocodingLocation = null;
      }

      // If switching to search mode, ensure any previous module states are cleared
      if (moduleName === null) {
        // Clear routing state
        useRouteStore.getState().reset();
        // Add other module state resets here as needed
      }

      return newState;
    }),
}));
