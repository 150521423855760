import { useMutation } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { GeocodingRequest, ReverseGeocodingRequest } from './request-types';
import { GeocodingResponse } from './response-types';
import { SearchType, AssetSearchType } from './request-enums';
import { AutocompleteElem } from '../geocoding-autocomplete/response-types';
import { getDefaultGeoserver } from '../common/config';

const GEOCODING_ENDPOINT = 'service/geocoding/1.0';
const REVERSE_GEOCODING_ENDPOINT = 'service/geocoding/1.0/reverse';

const geocode = async (request: GeocodingRequest): Promise<GeocodingResponse> => {
  try {
    // Log request in development
    if (import.meta.env.DEV)
    {
      // Always use the full URL for logging
      const apiBaseUrl = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';
      console.log(`Geocoding request to: ${apiBaseUrl}/${GEOCODING_ENDPOINT}`, { request });
    }

    // Get user's default geoserver
    const geoserver = getDefaultGeoserver();
    return apiClient.post<GeocodingResponse>({
      url: GEOCODING_ENDPOINT,
      data: {
        ...request,
        geoserver // Use default geoserver from user settings
      }
    });
  } catch (error) {
    // Enhanced error logging
    console.error('Geocoding error:', {
      message: error instanceof Error ? error.message : 'Unknown error',
      request,
      endpoint: GEOCODING_ENDPOINT
    });

    if (error instanceof Error) {
      throw new Error(`Geocoding failed: ${error.message}`);
    }
    throw new Error('Geocoding failed: Unknown error');
  }
};

// Helper to convert from autocomplete result to geocoding request
export const createGeocodingRequestFromAutocomplete = (item: AutocompleteElem): GeocodingRequest => {
  // Ensure we have a valid address
  const address = item.address || {
    city: item.place // Fallback to place name if no address
  };

  // Validate the address has at least some data
  if (!address.city && !address.street && !address.postalCode) {
    throw new Error('Invalid address: No location data available');
  }

  // Create request with address and exact coordinates if available
  const request: GeocodingRequest = {
    address,
    searchType: SearchType.FUZZY, // Changed to FUZZY for better matching
    maximumResult: 5,
    assetSearchType: AssetSearchType.OBJECT,
    language: 'fr', // Default to French for better French address matching
    coordinate: {
      lat: item.coordinate.latitude,
      lon: item.coordinate.longitude
    },
  };

  // Add exact coordinates if available
  if (item.coordinate) {
    request.coordinate = {
      lat: item.coordinate.latitude,
      lon: item.coordinate.longitude
    };
  }

  return request;
};

export const useGeocodingMutation = () => {
  return useMutation({
    mutationKey: ['geocoding', GEOCODING_ENDPOINT],
    meta: {
      endpoint: GEOCODING_ENDPOINT
    },
    mutationFn: (request: GeocodingRequest) => {
      try {
        // Ensure we have required fields and set defaults
        const geocodingRequest: GeocodingRequest = {
          ...request,
          searchType: request.searchType || SearchType.FUZZY,
          maximumResult: request.maximumResult || 5,
          language: request.language || 'fr'
          // Let geocode function handle geoserver
        };

        // Validate address
        if (!geocodingRequest.address || (!geocodingRequest.address.city && !geocodingRequest.address.street)) {
          throw new Error('Invalid request: Address is required');
        }

        return geocode(geocodingRequest);
      } catch (error) {
        console.error('Geocoding mutation error:', {
          error,
          request,
          endpoint: GEOCODING_ENDPOINT
        });
        throw error;
      }
    },
    retry: false // Don't retry failed mutations automatically
  });
};

const reverseGeocode = async (request: ReverseGeocodingRequest): Promise<GeocodingResponse> => {
  try {
    // Log request in development
    if (import.meta.env.DEV)
    {
      // Always use the full URL for logging
      const apiBaseUrl = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';
      console.log(`Reverse geocoding request to: ${apiBaseUrl}/${REVERSE_GEOCODING_ENDPOINT}`, { request });
    }

    const geoserver = getDefaultGeoserver();
    return apiClient.post<GeocodingResponse>({
      url: REVERSE_GEOCODING_ENDPOINT,
      data: {
        ...request,
        geoserver
      }
    });
  } catch (error) {
    // Enhanced error logging
    console.error('Reverse geocoding error:', {
      message: error instanceof Error ? error.message : 'Unknown error',
      request,
      endpoint: REVERSE_GEOCODING_ENDPOINT
    });

    if (error instanceof Error) {
      throw new Error(`Reverse geocoding failed: ${error.message}`);
    }
    throw new Error('Reverse geocoding failed: Unknown error');
  }
};

export const useReverseGeocodingMutation = () => {
  return useMutation({
    mutationKey: ['reverseGeocoding', REVERSE_GEOCODING_ENDPOINT],
    meta: {
      endpoint: REVERSE_GEOCODING_ENDPOINT
    },
    mutationFn: (request: ReverseGeocodingRequest) => {
      try {
        const reverseRequest: ReverseGeocodingRequest = {
          ...request,
          radius: request.radius || 50,
          //language: request.language || 'fr',
          maximumResults: request.maximumResults || 1,
        };
        return reverseGeocode(reverseRequest);
      } catch (error) {
        console.error('Reverse geocoding mutation error:', error);
        throw error;
      }
    },
    retry: false
  });
};
