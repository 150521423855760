// Helper to format duration from seconds to hours/minutes
export const formatDuration = (seconds: number): string =>
{
    if (seconds < 60)
    {
        return `${seconds} sec`;
    } else if (seconds < 3600)
    {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} min`;
    } else
    {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours} h ${minutes > 0 ? `${minutes} min` : ''}`;
    }
};

// Helper to format distance in meters to km
export const formatDistance = (meters: number): string =>
{
    if (meters < 1000)
    {
        return `${meters} m`;
    } else
    {
        const km = (meters / 1000).toFixed(1);
        return `${km} km`;
    }
};

// Format date to French locale
export const formatDate = (date: Date): string =>
{
    return date.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

// Format coordinates
export const formatCoordinates = (lat: number, lng: number): string =>
{
    return `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
};

// Format currency (assuming EUR)
export const formatCurrency = (amount: number): string =>
{
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
    }).format(amount);
};