import { useCallback, useState } from 'react';
import { AutoComplete } from './AutoComplete';
import type { AutocompleteElem } from '../../../services/geocoding-autocomplete/response-types';
import type { Coordinate } from '../../../services/common/types';
import type { RoutePoint } from '../routingUtilities/types';
import { useRouteStore } from '../../../store/useRouteStore';
import './LocationInput.css';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>
{
  draggable?: boolean;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
}

interface LocationInputProps
{
  label: string;
  pointNumber: number;
  pointText: string;
  isPontStartOrEnd: boolean;
  point: RoutePoint;
  initialLocation: AutocompleteElem | null;
  mapCenter: Coordinate;
  onLocationSelect: (location: AutocompleteElem | null) => void;
  onAction: () => void;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  badgeProps?: BadgeProps;
  isCalculating?: boolean;
}

export const LocationInput = ({
  label,
  pointNumber,
  // pointText,
  isPontStartOrEnd,
  point,
  initialLocation,
  mapCenter,
  onLocationSelect,
  onAction,
  onBlur,
  className = '',
  disabled = false,
  badgeProps,
  isCalculating = false
}: LocationInputProps) =>
{
  const [isLoading, setIsLoading] = useState(false);
  // Get the optimization type from the store
  const optimizationType = useRouteStore(state => state.routeConfig.optimizationType);

  const handleLoadingChange = useCallback((loading: boolean) =>
  {
    setIsLoading(loading);
  }, []);

  // Determine if we should show a number instead of text for the end point
  const showNumberForEnd = optimizationType === 'openEnded' && point.type === 'end';
  const isOpenEnded = optimizationType === 'openEnded';

  // Determine display text based on point type and optimization mode
  const displayText = showNumberForEnd ? pointNumber.toString() :
    (isPontStartOrEnd && !showNumberForEnd) ?
      (isOpenEnded && point.type === 'start' ? '🏳️' :
        isOpenEnded && point.type === 'end' ? '' :
          '🏳️') :
      pointNumber.toString();

  return (
    <div className={`relative flex ${className}`}>
      {/* Point Number Badge - Always show for all points */}
      <div
        {...badgeProps}
        className={`point-number-badge self-end ${badgeProps?.className || ''}`}
        style={{ backgroundColor: point.color }}
      >
        {displayText}
      </div>

      <div className="location-input-wrapper flex-1 relative -ml-px">
        <label className="text-sm font-bold" style={{ color: point.color }}>
          {label}
        </label>
        <div className="location-input-container" style={{
          '--point-color': point.color
        } as React.CSSProperties}>
          <AutoComplete
            mapCenter={mapCenter}
            initialLocation={initialLocation}
            onLocationSelect={onLocationSelect}
            onBlur={onBlur}
            disabled={disabled}
            onLoadingChange={handleLoadingChange}
          />

          {/* Button appears on hover only when there's content or for empty via points */}
          {((point.status === 'empty' && point.type === 'via') || point.status === 'set') && !isLoading && (
            <button
              onClick={onAction}
              title={point.status === 'set' ? 'Clear point' : 'Delete point'}
              className={`
                absolute right-2 top-1/2 -translate-y-1/2 p-1
                ${isCalculating ? 'opacity-50 cursor-not-allowed' : 'hover:text-red-500'}
                transition-colors duration-150 ease-in-out
              `}
              disabled={isCalculating}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-gray-400"
              >
                {point.status === 'set' ? (
                  <path d="M18 6L6 18M6 6l12 12" />
                ) : (
                  <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                )}
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
