import { useCallback } from 'react';
import type { LeafletMouseEvent } from 'leaflet';
import { useMapEvents } from 'react-leaflet';
import { useAppStore } from '../../store/useAppStore';
import { useRouteStore } from '../../store/useRouteStore';
import { useReverseGeocodingMutation } from '../../services/geocoding/mutation';

export function MapClickHandler()
{
  const activeModule = useAppStore(state => state.activeModule);
  const {
    getStartPoint,
    getEndPoint,
    getViaPoints,
    setPointLocation
  } = useRouteStore();

  const reverseGeocodingMutation = useReverseGeocodingMutation();

  const handleMapClick = useCallback(async (e: LeafletMouseEvent) =>
  {
    // Check if routing module is active
    if (activeModule !== 'route')
    {
      return;
    }

    // Check if there are any via points - if so, ignore the click
    const viaPoints = getViaPoints();
    if (viaPoints.length > 0)
    {
      return;
    }

    const clickedPosition = e.latlng;
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();

    // Determine which point to add
    let pointToAdd = null;

    if (startPoint?.status === 'empty')
    {
      // Start point is empty, add it
      pointToAdd = startPoint;
    } else if (endPoint?.status === 'empty')
    {
      // Start point exists but end point is empty, add end point
      pointToAdd = endPoint;
    } else
    {
      // Both points already exist, do nothing
      return;
    }

    // Proceed with reverse geocoding and point update
    try
    {
      // Set initial position first with loading state
      setPointLocation(pointToAdd.id, {
        coordinate: {
          latitude: clickedPosition.lat,
          longitude: clickedPosition.lng
        },
        place: 'Loading address...',
        elemType: 'address',
        address: { city: 'Loading...' }
      });

      // Perform reverse geocoding
      const response = await reverseGeocodingMutation.mutateAsync({
        coordinateSat: {
          lat: clickedPosition.lat,
          lon: clickedPosition.lng
        },
        radius: 500,
        maximumResults: 1,
      });

      if (response.elements.length > 0)
      {
        const place = response.elements[0];
        const address = place.postalAddress;

        // Format place text
        let placeText = `${address.street || ''}, ${address.postalCode || ''} ${address.city || ''}, ${address.county || ''}, ${address.state || ''}, ${address.country || ''}`.replace(/,\s*,/g, ', ').replace(/,\s*$/, '').trim();

        // Check if place text is valid
        if (placeText.split(',').length <= 1 || placeText.trim() === ',')
        {
          placeText = 'Unknown location';
        }

        const placetextwithoutcomma = placeText.replace(/,/g, '').trim();
        if (placetextwithoutcomma === address.countryCode || placetextwithoutcomma === address.country || placetextwithoutcomma === address.postalCode)
        {
          placeText = 'Unknown location';
        }

        // Update point with full address details
        setPointLocation(pointToAdd.id, {
          coordinate: {
            latitude: clickedPosition.lat,
            longitude: clickedPosition.lng
          },
          place: placeText,
          elemType: 'address',
          address: address
        });

        // After setting the point location, check if both start and end points exist
        const updatedStartPoint = getStartPoint();
        const updatedEndPoint = getEndPoint();

        // Check if both have valid locations (both are in 'set' status)
        if (updatedStartPoint?.status === 'set' && updatedEndPoint?.status === 'set')
        {
          // Auto-calculate route
          const computeRoute = useRouteStore.getState().handleComputeRoute;
          if (computeRoute)
          {
            computeRoute();
          }
        }
      } else
      {
        // Handle case when no address is found
        setPointLocation(pointToAdd.id, {
          coordinate: {
            latitude: clickedPosition.lat,
            longitude: clickedPosition.lng
          },
          place: 'Unknown location',
          elemType: 'address',
          address: { city: 'Unknown location' }
        });

        // After setting the point location, check if both start and end points exist
        const updatedStartPoint = getStartPoint();
        const updatedEndPoint = getEndPoint();

        // Check if both have valid locations (both are in 'set' status)
        if (updatedStartPoint?.status === 'set' && updatedEndPoint?.status === 'set')
        {
          // Auto-calculate route
          const computeRoute = useRouteStore.getState().handleComputeRoute;
          if (computeRoute)
          {
            computeRoute();
          }
        }
      }
    } catch (error)
    {
      console.error('Error handling map click:', error);

      // Reset point to empty state in case of error
      setPointLocation(pointToAdd.id, null);
    }
  }, [activeModule, getStartPoint, getEndPoint, getViaPoints, setPointLocation, reverseGeocodingMutation]);

  // Register map click event handler
  useMapEvents({
    click: handleMapClick
  });

  // This component doesn't render anything
  return null;
}