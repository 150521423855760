import { useState, useCallback, useRef, useEffect } from 'react';
import { useAutocompleteMutation } from '../../services/geocoding-autocomplete/mutation';
import { UseMutationResult } from '@tanstack/react-query';
import type { AutocompleteElem, AutocompleteResponse, AutocompleteError } from '../../services/geocoding-autocomplete/response-types';
import type { AutocompleteMutationProps } from '../../services/geocoding-autocomplete/mutation';
import type { Coordinate } from '../../services/common/types';
import { useAppStore } from '../../store/useAppStore';

interface GeocodingAutoCompleteProps {
  onLocationSelect: (location: AutocompleteElem | null) => void;
  mapCenter: Coordinate;
  disableLocation?: boolean;
  disabled?: boolean;
}

export const GeocodingAutoComplete = ({
  onLocationSelect,
  mapCenter,
  disableLocation,
  disabled = false
}: GeocodingAutoCompleteProps) => {
  const geocodingLocation = useAppStore(state => state.geocodingLocation);
  const [searchText, setSearchText] = useState(geocodingLocation?.place || '');
  const [isFocused, setIsFocused] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [useLocation, setUseLocation] = useState(false);
  const [userLocation, setUserLocation] = useState<Coordinate | null>(null);
  const [results, setResults] = useState<AutocompleteElem[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const searchTimeoutRef = useRef<number | null>(null);
  const isDirtyRef = useRef(false);
  const lastValidSearchRef = useRef<string>('');

  // Update text when geocoding location changes
  useEffect(() => {
    if (geocodingLocation?.place) {
      setSearchText(geocodingLocation.place);
    } else {
      setSearchText('');
    }
  }, [geocodingLocation]);

  const { mutate: searchLocation, isPending } = useAutocompleteMutation() as UseMutationResult<
    AutocompleteResponse,
    AutocompleteError,
    AutocompleteMutationProps
  >;

  // Reset selected index when results change
  useEffect(() => {
    setSelectedIndex(-1);
  }, [results]);

  useEffect(() => {
    let mounted = true;

    if (useLocation && !userLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (mounted) {
            setUserLocation({
              lat: position.coords.latitude,
              lon: position.coords.longitude
            });
          }
        },
        (error) => {
          if (mounted) {
            console.error('Error getting location:', error);
            setUseLocation(false);
          }
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, [useLocation]);

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        window.clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const debouncedSearch = useCallback((text: string) => {
    if (searchTimeoutRef.current) {
      window.clearTimeout(searchTimeoutRef.current);
    }

    if (text.length < 2) {
      setResults([]);
      return;
    }

    searchTimeoutRef.current = window.setTimeout(() => {
      try {
        searchLocation(
          {
            searchText: text,
            coordinate: useLocation ? userLocation ?? mapCenter : mapCenter
          },
          {
            onSuccess: (data) => {
              if (data.items.length > 0) {
                lastValidSearchRef.current = text;
              }
              setResults(data.items);
            },
            onError: (error) => {
              console.error('Search error:', error);
              setResults([]);
              // Restore last successful search text if available
              if (lastValidSearchRef.current && text !== lastValidSearchRef.current) {
                setIsRestoring(true);
                setSearchText(lastValidSearchRef.current);
                setTimeout(() => setIsRestoring(false), 1000);
              }
            }
          }
        );
      } catch (error) {
        console.error('Failed to initiate search:', error);
        setResults([]);
      }
    }, 300);
  }, [searchLocation, userLocation, useLocation, mapCenter, setSearchText]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const text = e.target.value;
    setSearchText(text);
    isDirtyRef.current = true;

    if (text === '') {
      onLocationSelect(null);
    } else {
      debouncedSearch(text);
    }
  }, [debouncedSearch, onLocationSelect, disabled]);

  const handleResultClick = useCallback((result: AutocompleteElem) => {
    try {
      if (!result?.place || !result.coordinate) {
        throw new Error("Invalid location selected: missing required data");
      }

      setSearchText(result.place);
      setResults([]);
      isDirtyRef.current = false;
      onLocationSelect(result);
    } catch (error) {
      console.error('Error handling location selection:', error);
      // Keep the previous valid state
      if (geocodingLocation?.place) {
        setSearchText(geocodingLocation.place);
      } else {
        setSearchText('');
      }
      setResults([]);
      isDirtyRef.current = false;
    }
  }, [onLocationSelect, geocodingLocation]);

  const clearSearch = useCallback(() => {
    setSearchText('');
    setResults([]);
    setUseLocation(false);
    setUserLocation(null);
    isDirtyRef.current = false;
    onLocationSelect(null);
  }, [onLocationSelect]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsFocused(false);
      setResults([]);
      setSelectedIndex(-1);

      // If text was changed but no location was selected, restore previous location text
      if (isDirtyRef.current && geocodingLocation?.place) {
        setSearchText(geocodingLocation.place);
      }
      isDirtyRef.current = false;
    }, 200);
  }, [geocodingLocation]);

  const handleFocus = useCallback(() => {
    if (!disabled) {
      setIsFocused(true);
      setSelectedIndex(-1);
      if (searchText) {
        isDirtyRef.current = true;
      }
    }
  }, [disabled, searchText]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape' && searchText) {
      clearSearch();
    } else if (e.key === 'ArrowDown' && results.length > 0) {
      e.preventDefault();
      setSelectedIndex(prev => (prev + 1) % results.length);
    } else if (e.key === 'ArrowUp' && results.length > 0) {
      e.preventDefault();
      setSelectedIndex(prev => (prev - 1 + results.length) % results.length);
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault();
      handleResultClick(results[selectedIndex]);
    }
  }, [searchText, results, selectedIndex, clearSearch, handleResultClick]);

  return (
    <div
      className="relative w-full"
      role="combobox"
      aria-expanded={isFocused && results.length > 0}
      aria-haspopup="listbox"
      aria-owns="search-results"
    >
      <div className="relative">
          <input
            type="text"
            value={searchText}
            onChange={handleSearchChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder="Search location..."
            role="searchbox"
            aria-label="Search for a location"
            aria-controls="search-results"
            aria-autocomplete="list"
            aria-activedescendant={selectedIndex >= 0 ? `result-${selectedIndex}` : undefined}
            className={`
              w-full px-4 py-2 pr-20 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent
              ${disabled ? 'opacity-75' : ''}
              ${isRestoring ? 'animate-pulse bg-yellow-50' : ''}
            `}
          />
        <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center space-x-2">
          {isPending && (
            <div className="w-5 h-5">
              <div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin"></div>
            </div>
          )}
          {searchText && (
            <button
              onClick={clearSearch}
              className="text-gray-400 hover:text-gray-600 p-1"
              type="button"
              aria-label="Clear search"
            >
              <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          )}
        </div>
      </div>

      {isFocused && !disableLocation && (
        <div className="absolute w-full p-2 mt-1 bg-white border rounded-lg shadow-lg">
          <label className="flex items-center space-x-2 text-sm text-gray-600">
            <input
              type="checkbox"
              checked={useLocation}
              onChange={(e) => setUseLocation(e.target.checked)}
              className="rounded text-blue-500 focus:ring-blue-500"
            />
            <span>Use current location to improve search results</span>
          </label>
        </div>
      )}

      {results.length > 0 && isFocused && (
        <div
          id="search-results"
          role="listbox"
          className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto z-50"
        >
          {results.map((result, index) => (
            <div
              key={`${result.place}-${index}`}
              id={`result-${index}`}
              className={`px-4 py-2 flex items-center justify-between hover:bg-gray-100 cursor-pointer
                ${selectedIndex === index ? 'bg-gray-100' : ''}
              `}
              onClick={() => handleResultClick(result)}
              role="option"
              aria-selected={selectedIndex === index}
            >
              <div>
                <div className="font-medium">{result.place}</div>
                {result.addressLabel && (
                  <div className="text-sm text-gray-600">{result.addressLabel}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
