import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import type { RouteResults, RoutePoint } from '../routingUtilities/types';
import type { RoutingInstruction } from '../../../services/routing/response-types';
import { createRoutePointsHTML } from './html/RoutePointsComponent';
import { createConfigurationHTML } from './html/ConfigurationComponent';
import { createInstructionsHTML, getInstructionsPagesCount } from './html/InstructionsComponent';
import 'jspdf-autotable';

interface AutoTableResult extends jsPDF
{
    lastAutoTable: {
        finalY: number;
    };
}

export const exportRouteToPDF = async (
    routeResults: RouteResults,
    startPoint: RoutePoint,
    endPoint: RoutePoint,
    viaPoints: RoutePoint[],
    routingInstructions?: RoutingInstruction[]
): Promise<void> =>
{
    try
    {
        // Calculate PDF dimensions
        const pdfWidth = 210; // A4 width in mm
        const contentWidth = pdfWidth - 30; // Accounting for margins
        const contentWidthPx = contentWidth * 3.779528; // Convert mm to px (approximate)
        const pointsPerPage = 9; // Maximum number of points per page (reduced from 10 to 8)

        // Create a hidden container for all temporary elements
        const hiddenContainer = document.createElement('div');
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.left = '-9999px';
        hiddenContainer.style.top = '-9999px';
        hiddenContainer.style.width = `${contentWidthPx}px`; // Match PDF content width
        hiddenContainer.style.height = 'auto';
        hiddenContainer.style.overflow = 'hidden';
        // Add styles to ensure proper rendering
        hiddenContainer.style.fontFamily = 'Arial, sans-serif';
        hiddenContainer.style.fontSize = '12px';
        hiddenContainer.style.lineHeight = '1.5';
        hiddenContainer.style.color = '#000';
        document.body.appendChild(hiddenContainer);

        // Create PDF document
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        }) as AutoTableResult;

        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const margin = 15;
        let yPos = margin;

        // Calculate total points and instructions
        const totalPoints = 2 + viaPoints.length; // start + end + via points
        const totalInstructions = routingInstructions?.length || 0;

        // ===== FIRST PAGE: HEADER, SUMMARY, CONFIG =====

        // ===== 1. HEADER SECTION =====
        // Create a BeMap header similar to the screenshot
        doc.setFillColor(230, 247, 255); // Light blue background
        doc.rect(0, 0, pageWidth, 40, 'F');

        // Add BeMap logo text
        doc.setFontSize(25);
        doc.setTextColor(0, 102, 204); // BeMap blue color
        doc.text("BeNomad", margin, margin + 10);

        // Add "Planification d'itinéraire" subtitle
        doc.setFontSize(10);
        doc.text("Planification d'itinéraire", margin, margin + 20);

        // Add reference number on the right
        doc.setFontSize(10);
        doc.text(`Référence: BM-${Math.floor(Math.random() * 1000000)}`, pageWidth - margin - 50, margin + 10);

        // Add generation date on the right
        const now = new Date();
        const formattedDate = `${now.getDate()} ${['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'][now.getMonth()]} ${now.getFullYear()} à ${now.getHours()}:${now.getMinutes().toString().padStart(2, '0')}`;
        doc.text(`Généré le: ${formattedDate}`, pageWidth - margin - 50, margin + 20);

        // Add "Itinéraire détaillé" in the center with white background
        doc.setFillColor(255, 255, 255);
        doc.roundedRect(pageWidth / 2 - 30, 15, 60, 20, 3, 3, 'F');
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text("Itinéraire détaillé BeMap", pageWidth / 2, 28, { align: 'center' });

        // Update position after header
        yPos = 50;

        // ===== 2. SUMMARY SECTION =====
        // Skip the summary title and go directly to the boxes
        // Get route data
        const route = routeResults.routes[0];
        const distance = route.length;
        const duration = route.duration;
        const avgSpeed = distance > 0 && duration > 0 ? Math.round((distance / 1000) / (duration / 3600)) : 0;

        // Create summary boxes
        const boxWidth = (pageWidth - 2 * margin - 20) / 5; // 5 boxes with small gaps
        const boxHeight = 50; // Increase height for vertical layout
        const boxY = yPos;

        // Box 1: Distance
        doc.setFillColor(230, 247, 255); // Light blue background
        doc.roundedRect(margin, boxY, boxWidth, boxHeight, 2, 2, 'F');

        // Distance icon - centered at top, smaller and lighter
        /* doc.setFillColor(0, 120, 215); // Lighter blue for the icon
        doc.circle(margin + boxWidth / 2, boxY + 12, 6, 'F'); // Smaller circle
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(255, 255, 255);
        doc.setLineWidth(1.2); // Thinner lines
        doc.line(margin + boxWidth / 2 - 3, boxY + 12, margin + boxWidth / 2 + 3, boxY + 12);
        doc.line(margin + boxWidth / 2, boxY + 9, margin + boxWidth / 2, boxY + 15); */
        doc.setFillColor(0, 120, 215); // Blue for the icon background
        doc.circle(margin + boxWidth / 2, boxY + 12, 6, 'F'); // Circle background - same position as original

        // Draw a horizontal line with arrows on both ends
        doc.setDrawColor(255, 255, 255);
        doc.setFillColor(255, 255, 255);
        doc.setLineWidth(1.2);

        // Main horizontal line
        const lineLength = 8;
        doc.line(
            margin + boxWidth / 2 - lineLength / 2,
            boxY + 12,
            margin + boxWidth / 2 + lineLength / 2,
            boxY + 12
        );

        // Left arrow
        const arrowSize = 2;
        doc.line(
            margin + boxWidth / 2 - lineLength / 2,
            boxY + 12,
            margin + boxWidth / 2 - lineLength / 2 + arrowSize,
            boxY + 12 - arrowSize
        );
        doc.line(
            margin + boxWidth / 2 - lineLength / 2,
            boxY + 12,
            margin + boxWidth / 2 - lineLength / 2 + arrowSize,
            boxY + 12 + arrowSize
        );

        // Right arrow
        doc.line(
            margin + boxWidth / 2 + lineLength / 2,
            boxY + 12,
            margin + boxWidth / 2 + lineLength / 2 - arrowSize,
            boxY + 12 - arrowSize
        );
        doc.line(
            margin + boxWidth / 2 + lineLength / 2,
            boxY + 12,
            margin + boxWidth / 2 + lineLength / 2 - arrowSize,
            boxY + 12 + arrowSize
        );
        
        // Distance label - centered in middle, bolder
        doc.setTextColor(0, 102, 204);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold'); // Make title bold
        doc.text("Distance", margin + boxWidth / 2, boxY + 25, { align: 'center' });

        // Distance value - centered at bottom
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'normal'); // Reset to normal font
        doc.text(`${(distance / 1000).toFixed(1)} km`, margin + boxWidth / 2, boxY + 40, { align: 'center' });

        // Box 2: Duration
        // Box 2: Duration (box styling remains the same)
        doc.setFillColor(230, 247, 255);
        doc.roundedRect(margin + boxWidth + 5, boxY, boxWidth, boxHeight, 2, 2, 'F');

        // Duration icon - simplified clock design
        // Using exact same positioning as original
        doc.setFillColor(0, 120, 215); // Blue background
        doc.circle(margin + boxWidth + 5 + boxWidth / 2, boxY + 12, 6, 'F'); // Circle background

        // Draw simpler clock hands
        doc.setDrawColor(255, 255, 255);
        doc.setFillColor(255, 255, 255);
        doc.setLineWidth(1.2); // Same line width as original

        // Hour hand (pointing to 12)
        doc.line(
            margin + boxWidth + 5 + boxWidth / 2,
            boxY + 12,
            margin + boxWidth + 5 + boxWidth / 2,
            boxY + 8
        );

        // Minute hand (pointing to 3)
        doc.line(
            margin + boxWidth + 5 + boxWidth / 2,
            boxY + 12,
            margin + boxWidth + 5 + boxWidth / 2 + 4,
            boxY + 12
        );

        // Small center dot
        doc.circle(margin + boxWidth + 5 + boxWidth / 2, boxY + 12, 0.5, 'F');

        // Duration label - centered in middle, bolder
        doc.setTextColor(0, 102, 204);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold'); // Make title bold
        doc.text("Durée", margin + boxWidth + 5 + boxWidth / 2, boxY + 25, { align: 'center' });

        // Format duration
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const formattedDuration = hours > 0
            ? `${hours} h ${minutes} min`
            : `${minutes} min`;

        // Duration value - centered at bottom
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'normal'); // Reset to normal font
        doc.text(formattedDuration, margin + boxWidth + 5 + boxWidth / 2, boxY + 40, { align: 'center' });

        // Box 3: Average Speed
        // Box 3: Average Speed (box styling remains the same)
        doc.setFillColor(230, 247, 255);
        doc.roundedRect(margin + (boxWidth + 5) * 2, boxY, boxWidth, boxHeight, 2, 2, 'F');

        // Speed icon - improved design with motion lines
        // Using same positioning as original for consistency
        const speedIconX = margin + (boxWidth + 5) * 2 + boxWidth / 2;
        const speedIconY = boxY + 12;

        // Circle background
        doc.setFillColor(0, 120, 215);
        doc.circle(speedIconX, speedIconY, 6, 'F');

        // Draw speedometer/gauge needle
        doc.setDrawColor(255, 255, 255);
        doc.setFillColor(255, 255, 255);
        doc.setLineWidth(1.2);

        // Main needle pointing upper-right (indicating high speed)
        doc.line(
            speedIconX,
            speedIconY,
            speedIconX + 4,
            speedIconY - 3
        );

        // Add motion/speed lines at 0, 90, and 180 degrees
        // Make them thinner and closer to the edge
        doc.setLineWidth(0.8); // Thinner lines

        // Line at 0 degrees (right)
        doc.line(
            speedIconX + 3.5,
            speedIconY,
            speedIconX + 5,
            speedIconY
        );

        // Line at 90 degrees (top)
        doc.line(
            speedIconX,
            speedIconY - 5,
            speedIconX,
            speedIconY - 3.5
        );

        // Line at 180 degrees (left)
        doc.line(
            speedIconX - 5,
            speedIconY,
            speedIconX - 3.5,
            speedIconY
        );

        // Speed label - centered in middle, bolder
        doc.setTextColor(0, 102, 204);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold'); // Make title bold
        doc.text("Vitesse moyenne", margin + (boxWidth + 5) * 2 + boxWidth / 2, boxY + 25, { align: 'center' });

        // Speed value - centered at bottom
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'normal'); // Reset to normal font
        doc.text(`${avgSpeed} km/h`, margin + (boxWidth + 5) * 2 + boxWidth / 2, boxY + 40, { align: 'center' });

        // Box 4: Points
        doc.setFillColor(230, 247, 255);
        doc.roundedRect(margin + (boxWidth + 5) * 3, boxY, boxWidth, boxHeight, 2, 2, 'F');

        // Points icon - centered at top, smaller and lighter
        doc.setFillColor(0, 120, 215); // Lighter blue for the icon
        doc.circle(margin + (boxWidth + 5) * 3 + boxWidth / 2, boxY + 12, 6, 'F'); // Smaller circle
        doc.setFillColor(255, 255, 255);
        // Draw location marker (circle with dot)
        doc.setDrawColor(255, 255, 255);
        doc.setLineWidth(1.2); // Thinner lines
        doc.circle(margin + (boxWidth + 5) * 3 + boxWidth / 2, boxY + 12, 3, 'S');
        doc.circle(margin + (boxWidth + 5) * 3 + boxWidth / 2, boxY + 12, 1, 'F');

        // Points label - centered in middle, bolder
        doc.setTextColor(0, 102, 204);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold'); // Make title bold
        doc.text("Points", margin + (boxWidth + 5) * 3 + boxWidth / 2, boxY + 25, { align: 'center' });

        // Points value - centered at bottom
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'normal'); // Reset to normal font
        doc.text(`${totalPoints}`, margin + (boxWidth + 5) * 3 + boxWidth / 2, boxY + 40, { align: 'center' });

        // Box 5: Instructions
        doc.setFillColor(230, 247, 255);
        doc.roundedRect(margin + (boxWidth + 5) * 4, boxY, boxWidth, boxHeight, 2, 2, 'F');

        // Instructions icon - centered at top, smaller and lighter
        doc.setFillColor(0, 120, 215); // Lighter blue for the icon
        doc.circle(margin + (boxWidth + 5) * 4 + boxWidth / 2, boxY + 12, 6, 'F'); // Smaller circle
        doc.setFillColor(255, 255, 255);
        // Draw hamburger menu icon
        doc.setDrawColor(255, 255, 255);
        doc.setLineWidth(1.2); // Thinner lines
        doc.line(margin + (boxWidth + 5) * 4 + boxWidth / 2 - 3, boxY + 9, margin + (boxWidth + 5) * 4 + boxWidth / 2 + 3, boxY + 9);
        doc.line(margin + (boxWidth + 5) * 4 + boxWidth / 2 - 3, boxY + 12, margin + (boxWidth + 5) * 4 + boxWidth / 2 + 3, boxY + 12);
        doc.line(margin + (boxWidth + 5) * 4 + boxWidth / 2 - 3, boxY + 15, margin + (boxWidth + 5) * 4 + boxWidth / 2 + 3, boxY + 15);

        // Instructions label - centered in middle, bolder
        doc.setTextColor(0, 102, 204);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold'); // Make title bold
        doc.text("Instructions", margin + (boxWidth + 5) * 4 + boxWidth / 2, boxY + 25, { align: 'center' });

        // Instructions value - centered at bottom
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'normal'); // Reset to normal font
        doc.text(`${totalInstructions}`, margin + (boxWidth + 5) * 4 + boxWidth / 2, boxY + 40, { align: 'center' });

        // Update position after summary section
        yPos += boxHeight + 10;

        // ===== 3. CONFIGURATION SECTION =====
        // Skip the configuration title and go directly to the component
        // Add configuration section with the original HTML component
        const configElement = createConfigurationHTML(routeResults.usedConfig);
        hiddenContainer.appendChild(configElement);
        const configCanvas = await html2canvas(configElement, {
            scale: 2, // Higher scale for better quality
            useCORS: true,
            logging: false,
            allowTaint: true,
            width: contentWidthPx,
            height: configElement.offsetHeight
        });
        hiddenContainer.removeChild(configElement);

        const configWidth = pageWidth - 2 * margin;
        // Use reasonable height for config - increase to show more content
        const configHeight = Math.min((configElement.offsetHeight / contentWidthPx) * configWidth * 0.9, 200);

        doc.addImage(
            configCanvas.toDataURL('image/png'),
            'PNG',
            margin,
            yPos,
            configWidth,
            configHeight
        );

        // ===== ROUTE POINTS PAGES =====

        // ALWAYS start route points on a new page
        doc.addPage();
        yPos = margin;

        // Add a title for the points section - similar to how the configuration section is handled
        // First, create a simple HTML element with just the title
        const pointsTitleElement = document.createElement('div');
        pointsTitleElement.style.width = `${contentWidthPx}px`;
        pointsTitleElement.style.fontFamily = 'Arial, sans-serif';
        pointsTitleElement.style.padding = '10px 0 30px 0'; // Add more padding to ensure visibility

        // Create the title with styling similar to the configuration section
        pointsTitleElement.innerHTML = `
          <div style="margin-bottom: 30px;">
            <h2 style="font-size: 18px; font-weight: bold; margin: 0 0 15px 0; padding: 0; color: #000;">Points de l'itinéraire</h2>
            <hr style="border: none; height: 1px; background-color: #ddd; margin: 0;">
          </div>
        `;

        // Render the title to canvas with higher scale for better quality
        hiddenContainer.appendChild(pointsTitleElement);
        const pointsTitleCanvas = await html2canvas(pointsTitleElement, {
            scale: 3, // Higher scale for better quality
            useCORS: true,
            logging: false,
            allowTaint: true,
            width: contentWidthPx,
            height: pointsTitleElement.offsetHeight
        });
        hiddenContainer.removeChild(pointsTitleElement);

        // Add the title to the PDF with more space
        const titleWidth = pageWidth - 2 * margin;
        const titleHeight = (pointsTitleElement.offsetHeight / contentWidthPx) * titleWidth * 1.2; // Increase height factor

        doc.addImage(
            pointsTitleCanvas.toDataURL('image/png'),
            'PNG',
            margin,
            yPos,
            titleWidth,
            titleHeight
        );

        // Update position after title with more space
        yPos += titleHeight + 15;

        // Calculate how many pages we need for route points
        const totalViaPoints = viaPoints.length;
        let routePointPages = 1;

        // Calculate how many points can fit on a single page (including start and end points)
        const maxPointsPerPage = pointsPerPage;

        // If all points (start + via + end) can fit on one page, use just one page
        if (totalViaPoints + 2 <= maxPointsPerPage)
        {
            routePointPages = 1;
        } else if (totalViaPoints > 0)
        {
            // If we have via points that don't fit on one page, calculate pages needed
            // First page: start + up to (pointsPerPage-1) via points
            // Last page: remaining via points + end
            const firstPageViaPoints = maxPointsPerPage - 1; // Reserve space for start point
            const remainingViaPoints = Math.max(0, totalViaPoints - firstPageViaPoints);

            if (remainingViaPoints === 0)
            {
                // All via points fit on the first page with the start point
                routePointPages = 1;
            } else
            {
                // Need at least one more page
                routePointPages = 2;

                // Check if we need additional pages
                if (remainingViaPoints > maxPointsPerPage - 1)
                { // Reserve space for end point on last page
                    routePointPages += Math.ceil((remainingViaPoints - (maxPointsPerPage - 1)) / maxPointsPerPage);
                }
            }
        }

        // Add route points with pagination
        let processedViaPoints = 0;
        let endPointAdded = false; // Track if end point has been added

        for (let pageIndex = 0; pageIndex < routePointPages; pageIndex++)
        {
            // For pages after the first route points page, start a new page
            if (pageIndex > 0)
            {
                doc.addPage();
                yPos = margin;
            }

            // Determine which points to show on this page
            let pageStartPoint = null;
            let pageEndPoint = null;
            let pageViaPoints: RoutePoint[] = [];

            if (routePointPages === 1)
            {
                // Special case: All points fit on one page
                pageStartPoint = startPoint;
                pageEndPoint = endPoint;
                pageViaPoints = viaPoints.slice(0);
                endPointAdded = true;
            } else if (pageIndex === 0)
            {
                // First page of multi-page: Start point + first batch of via points
                pageStartPoint = startPoint;
                const pointsToTake = maxPointsPerPage - 1; // Reserve space for start point
                pageViaPoints = viaPoints.slice(0, pointsToTake);
                processedViaPoints = pointsToTake;
            } else if (pageIndex === routePointPages - 1)
            {
                // Last page: Remaining via points + end point
                pageEndPoint = endPoint;
                endPointAdded = true;
                pageViaPoints = viaPoints.slice(processedViaPoints);
            } else
            {
                // Middle pages: Only via points
                const pointsToTake = maxPointsPerPage;
                pageViaPoints = viaPoints.slice(processedViaPoints, processedViaPoints + pointsToTake);
                processedViaPoints += pointsToTake;
            }

            // Create HTML for this page's points
            const routePointsElement = document.createElement('div');
            routePointsElement.style.width = `${contentWidthPx}px`;

            // Add start point if this is the first page
            if (pageStartPoint)
            {
                const startPointElement = createRoutePointsHTML(
                    pageStartPoint,
                    null,
                    [],
                    routeResults.usedDestinations,
                    routeResults.routes[0]
                );
                routePointsElement.appendChild(startPointElement);
            }

            // Add via points for this page with correct numbering
            if (pageViaPoints.length > 0)
            {
                // Calculate the starting index for via points on this page
                const viaStartIndex = pageIndex === 0 ? 1 : pageIndex * (pointsPerPage - 1) + 1;

                // Create a custom style element to properly center the numbers in circles
                const styleElement = document.createElement('style');
                styleElement.textContent = `
                    .point-number {
                        display: flex !important;
                        align-items: center !important;
                        justify-content: center !important;
                        text-align: center !important;
                        vertical-align: middle !important;
                        line-height: 1 !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        height: 100% !important;
                    }

                    .point-number span {
                        display: inline-block !important;
                        margin-top: -2px !important; /* Fine-tune vertical alignment */
                    }
                `;
                document.head.appendChild(styleElement);

                const viaPointsElement = createRoutePointsHTML(
                    null,
                    null,
                    pageViaPoints,
                    routeResults.usedDestinations,
                    routeResults.routes[0],
                    viaStartIndex // Pass the correct starting index
                );

                // Remove the style element after creating the component
                document.head.removeChild(styleElement);

                routePointsElement.appendChild(viaPointsElement);
            }

            // Add end point if this is the last page
            if (pageEndPoint)
            {
                const endPointElement = createRoutePointsHTML(
                    null,
                    pageEndPoint,
                    [],
                    routeResults.usedDestinations,
                    routeResults.routes[0]
                );
                routePointsElement.appendChild(endPointElement);
            }

            // Render the points to canvas
            hiddenContainer.appendChild(routePointsElement);
            const routePointsCanvas = await html2canvas(routePointsElement, {
                scale: 2,
                useCORS: true,
                logging: false,
                allowTaint: true,
                width: contentWidthPx,
                height: routePointsElement.offsetHeight
            });
            hiddenContainer.removeChild(routePointsElement);

            const routePointsWidth = pageWidth - 2 * margin;
            const routePointsHeight = (routePointsElement.offsetHeight / contentWidthPx) * routePointsWidth;
            const maxHeight = pageHeight - yPos - margin;
            const adjustedRoutePointsHeight = Math.min(routePointsHeight, maxHeight);

            doc.addImage(
                routePointsCanvas.toDataURL('image/png'),
                'PNG',
                margin,
                yPos,
                routePointsWidth,
                adjustedRoutePointsHeight
            );

            // If we're at the end of the page and there's more content, add a "continued" note
            if (routePointsHeight > maxHeight && pageIndex < routePointPages - 1)
            {
                doc.setFontSize(10);
                doc.setTextColor(100);
                doc.text("(Suite à la page suivante)", pageWidth / 2, pageHeight - 15, { align: 'center' });
            }
        }

        // If end point wasn't added for some reason, add it on a new page
        if (!endPointAdded && endPoint)
        {
            doc.addPage();
            yPos = margin;

            const endPointElement = createRoutePointsHTML(
                null,
                endPoint,
                [],
                routeResults.usedDestinations,
                routeResults.routes[0]
            );

            hiddenContainer.appendChild(endPointElement);
            const endPointCanvas = await html2canvas(endPointElement, {
                scale: 2,
                useCORS: true,
                logging: false,
                allowTaint: true,
                width: contentWidthPx,
                height: endPointElement.offsetHeight
            });
            hiddenContainer.removeChild(endPointElement);

            const endPointWidth = pageWidth - 2 * margin;
            const endPointHeight = (endPointElement.offsetHeight / contentWidthPx) * endPointWidth;

            doc.addImage(
                endPointCanvas.toDataURL('image/png'),
                'PNG',
                margin,
                yPos,
                endPointWidth,
                endPointHeight
            );
        }

        // Add routing instructions section if available
        if (routingInstructions && Array.isArray(routingInstructions) && routingInstructions.length > 0)
        {
            // Validate instructions to ensure they have the required properties
            const validInstructions = routingInstructions.filter(instruction =>
                instruction &&
                typeof instruction.length === 'number' &&
                typeof instruction.duration === 'number'
            );

            if (validInstructions.length > 0)
            {
                // Calculate how many pages we need for instructions
                const instructionPages = getInstructionsPagesCount(validInstructions);

                // Render each page of instructions
                for (let pageIndex = 0; pageIndex < instructionPages; pageIndex++)
                {
                    // Always start instructions on a new page
                    doc.addPage();
                    yPos = margin;

                    // Skip the instructions page header and go directly to the content
                    const instructionsElement = createInstructionsHTML(validInstructions, pageIndex);
                    hiddenContainer.appendChild(instructionsElement);
                    const instructionsCanvas = await html2canvas(instructionsElement, {
                        scale: 2,
                        useCORS: true,
                        logging: false,
                        allowTaint: true,
                        width: contentWidthPx,
                        height: instructionsElement.offsetHeight
                    });
                    hiddenContainer.removeChild(instructionsElement);

                    const instructionsWidth = pageWidth - 2 * margin;
                    const instructionsHeight = (instructionsElement.offsetHeight / contentWidthPx) * instructionsWidth;
                    const adjustedInstructionsHeight = Math.min(instructionsHeight, pageHeight - yPos - margin);

                    doc.addImage(
                        instructionsCanvas.toDataURL('image/png'),
                        'PNG',
                        margin,
                        yPos,
                        instructionsWidth,
                        adjustedInstructionsHeight
                    );

                    // If we're at the end of the page and there's more content, add a "continued" note
                    if (instructionsHeight > pageHeight - yPos - margin && pageIndex < instructionPages - 1)
                    {
                        doc.setFontSize(10);
                        doc.setTextColor(100);
                        doc.text("(Suite à la page suivante)", pageWidth / 2, pageHeight - 15, { align: 'center' });
                    }
                }
            }
        }

        // Add footer with page numbers
        const totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++)
        {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.setTextColor(128);
            doc.text(
                `Page ${i} sur ${totalPages}`,
                pageWidth / 2,
                pageHeight - 10,
                { align: 'center' }
            );
        }

        // Remove the hidden container
        document.body.removeChild(hiddenContainer);

        // Save the PDF
        const startName = startPoint.location?.place?.split(',')[0] || 'Départ';
        const endName = endPoint.location?.place?.split(',')[0] || 'Arrivée';
        doc.save(`Itinéraire_${startName}_vers_${endName}.pdf`);

    } catch (error)
    {
        // Make sure to clean up the hidden container if there's an error
        const hiddenContainer = document.querySelector('div[style*="position: absolute"][style*="left: -9999px"]');
        if (hiddenContainer && hiddenContainer.parentNode)
        {
            hiddenContainer.parentNode.removeChild(hiddenContainer);
        }

        console.error('Erreur lors de la génération du PDF:', error);
        alert('Échec de la génération du PDF. Veuillez réessayer.');
    }
};