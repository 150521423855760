import { memo, useCallback } from "react";
import { Icon } from "@iconify/react";
import { TruckSpecFormData } from "../routingUtilities/types";

interface TruckProfileModalProps {
  initialProfile: TruckSpecFormData | null;
  onSave: (profile: TruckSpecFormData | null) => void;
  onClose: () => void;
}

const defaultTruckProfile: TruckSpecFormData = {
  height: 400,  // cm
  width: 255,   // cm
  length: 1200, // cm
  weight: 3.5,  // t
  maxSpeed: 90  // km/h
};

export const TruckProfileModal = memo(function TruckProfileModal({
  initialProfile,
  onSave,
  onClose
}: TruckProfileModalProps) {
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const profile: TruckSpecFormData = {
      height: Number(formData.get('height')),
      width: Number(formData.get('width')),
      length: Number(formData.get('length')),
      weight: Number(formData.get('weight')),
      // Optional fields
      axleWeight: formData.get('axleWeight') ? Number(formData.get('axleWeight')) : undefined,
      maxSpeed: formData.get('maxSpeed') ? Number(formData.get('maxSpeed')) : undefined
    };

    // Validate required fields
    if (!profile.height || !profile.width || !profile.length || !profile.weight) {
      alert('Please fill in all required fields');
      return;
    }

    onSave(profile);
    onClose();
  }, [onSave, onClose]);

  // Use default truck profile if no initial profile is set
  const profile = initialProfile || defaultTruckProfile;

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-80 p-4 bg-white rounded-lg shadow-xl border border-gray-200 z-50">
        <div className="flex items-center justify-between mb-4">
          <h5 className="font-medium text-sm text-gray-700">Profil du camion</h5>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
            title="Fermer"
          >
            <Icon icon="mdi:close" className="text-xl" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-3">
          {[
            { key: "height", label: "Hauteur", unit: "cm", value: profile.height },
            { key: "width", label: "Largeur", unit: "cm", value: profile.width },
            { key: "length", label: "Longueur", unit: "cm", value: profile.length },
            { key: "weight", label: "Poids", unit: "t", value: profile.weight, step: 0.1 },
            { key: "axleWeight", label: "Poids par essieu", unit: "t", value: profile.axleWeight, step: 0.1, optional: true },
            { key: "maxSpeed", label: "Vitesse max", unit: "km/h", value: profile.maxSpeed, optional: true }
          ].map(({ key, label, unit, value, step = 1, optional }) => (
            <div key={key} className="flex items-center justify-between gap-2">
              <label className="text-xs text-gray-600">
                {label}
                {optional && <span className="text-gray-400 ml-1">(optionnel)</span>}
              </label>
              <div className="flex items-center gap-1">
                <input
                  type="number"
                  name={key}
                  className="w-20 px-2 py-1 text-sm border rounded focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  defaultValue={value}
                  step={step}
                  required={!optional}
                />
                <span className="text-xs text-gray-500">{unit}</span>
              </div>
            </div>
          ))}
          <div className="flex justify-end gap-2 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Sauvegarder
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
