import { memo } from 'react';
import { Icon } from "@iconify/react";
import type { LatLngBoundsLiteral } from 'leaflet';
import { RoutePoint, RouteResults, RouteConfig } from '../routingUtilities/types';
import { RoutePointDisplay } from './RoutePointDisplay';
import { WaypointsAccordion } from './WaypointsAccordion';

const formatDistance = (meters: number) =>
{
  if (meters < 1000)
  {
    return `${meters}m`;
  }
  return `${(meters / 1000).toFixed(1)}km`;
};

const formatDuration = (seconds: number) =>
{
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.round((seconds % 3600) / 60);
  if (hours > 0)
  {
    return `${hours}h ${minutes}min`;
  }
  return `${minutes}min`;
};

const formatAverageSpeed = (speed: number) =>
{
  return `${speed.toFixed(0)} km/h`;
}

interface RouteDetailsProps
{
  route: RouteResults['routes'][0];
  bounds: LatLngBoundsLiteral;
  via: RoutePoint[];
  start: string;
  end: string;
  routeConfig: RouteConfig;
  className?: string;
}

export const RouteDetails = memo(function RouteDetails({
  route,
  start,
  end,
  via,
  routeConfig,
  className
}: RouteDetailsProps)
{
  const isTruck = routeConfig.transportMode === 'TRUCK';
  const isTraficHistory = routeConfig.trafficMode === 'historical';
  const isOpenEnded = routeConfig.optimizationType === 'openEnded';
  const currentTruckProfile = isTruck ? routeConfig.truckProfile : null;
  const currentTrafficDate = isTraficHistory ? routeConfig.trafficHistoryDate : null;
  const truckProfileTitle = isTruck ? `Hauteur: ${currentTruckProfile?.height}cm, Largeur: ${currentTruckProfile?.width}cm, Longueur: ${currentTruckProfile?.length}cm, Poids: ${currentTruckProfile?.weight}t${currentTruckProfile?.maxSpeed ? `, Vitesse: ${currentTruckProfile?.maxSpeed}km/h` : ''}` : '';
  const trafficDateStringFromDate = currentTrafficDate ? new Date(currentTrafficDate).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '';
  const trafficTimeStringFromDate = currentTrafficDate ? new Date(currentTrafficDate).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) : '';
  const trafficDateTitle = isTraficHistory ? `Date et heure du trafic: ${trafficDateStringFromDate} ${trafficTimeStringFromDate}` : '';
  // Map routing options to icons and labels
  const routingMethodIcons = {
    FASTEST: { icon: "mdi:timer-outline", label: "Plus rapide" },
    SHORTEST: { icon: "mdi:map-marker-distance", label: "Plus court" },
    ECO_ENERGY: { icon: "mdi:leaf", label: "Éco-énergétique" }
  };

  const optimizationIcons = {
    none: { icon: "mdi:arrow-right", label: "Ordre défini" },
    optimized: { icon: "mdi:routes", label: "Points fixes" },
    openEnded: { icon: "mdi:map-marker-path", label: "Départ fixe" }
  };

  const trafficIcons = {
    none: { icon: "tabler:traffic-lights-off", label: "Sans trafic" },
    live: { icon: "emojione-monotone:vertical-traffic-light", label: "Trafic en direct" },
    historical: { icon: "carbon:update-now", label: "Historique" }
  };

  const transportIcons = {
    CAR: { icon: "mdi:car", label: "Voiture" },
    TRUCK: { icon: "mdi:truck", label: "Camion" },
    PUBLIC_BUS: { icon: "mdi:bus", label: "Bus" },
    TAXI: { icon: "mdi:taxi", label: "Taxi" },
    BICYCLE: { icon: "mdi:bike", label: "Vélo" }
  };

  const avoidFeatureIcons = {
    tolls: { icon: "mdi:cash-multiple", label: "Péages" },
    motorways: { icon: "mdi:highway", label: "Autoroutes" },
    ferries: { icon: "mdi:ferry", label: "Ferry" },
    unpavedRoads: { icon: "mdi:road-variant", label: "Routes non pavées" },
    borderCrossings: { icon: "mdi:passport", label: "Frontières" }
  };

  return (
    <div className={`p-4 max-w-md ${className || ''}`}>
      <div className="space-y-5">
        {/* Summary */}
        <div className="bg-white shadow-md rounded-xl overflow-hidden border border-gray-200">
          <div className="grid grid-cols-3 divide-x divide-gray-300">
            <div className="p-4">
              <div className="flex items-center space-x-2 text-gray-600 mb-1.5 text-sm">
                <Icon icon="mdi:map-marker-distance" className="w-4 h-4 text-blue-600" />
                <span>Distance</span>
              </div>
              <div className="font-semibold text-lg text-gray-900">{formatDistance(route.length)}</div>
            </div>
            <div className="p-4">
              <div className="flex items-center space-x-2 text-gray-600 mb-1.5 text-sm">
                <Icon icon="mdi:timer-outline" className="w-4 h-4 text-green-600" />
                <span>Duration</span>
              </div>
              <div className="font-semibold text-lg text-gray-900">{formatDuration(route.duration)}</div>
            </div>
            <div className="p-4">
              <div className="flex items-center space-x-2 text-gray-600 mb-1.5 text-sm">
                <Icon icon="mdi:speedometer" className="w-4 h-4 text-purple-600" />
                <span>Speed</span>
              </div>
              <div className="font-semibold text-lg text-gray-900">{formatAverageSpeed(route.averageSpeed)}</div>
            </div>
          </div>

          {route.trafficDelay > 60 && (
            <div className="bg-amber-100 border-t border-amber-200 p-3 flex items-center space-x-2">
              <Icon icon="mdi:alert" className="w-4 h-4 text-amber-700" />
              <span className="text-amber-800 text-sm font-medium">
                +{formatDuration(route.trafficDelay)} delay due to traffic
              </span>
            </div>
          )}
        </div>

        {/* Route Points */}
        <div className="space-y-3 bg-white shadow-md rounded-xl p-4 border border-gray-200">
          <RoutePointDisplay
            icon={
              <div className="w-7 h-7 bg-green-600 rounded-full flex items-center justify-center text-white font-bold shadow-sm">
                <Icon icon="mdi:flag-variant" className="w-4 h-4" />
              </div>
            }
            label="From"
            address={start}
            cordinates={route?.waypoints?.[0]?.coordinate?.lat?.toFixed(5) + ', ' + route?.waypoints?.[0]?.coordinate?.lon?.toFixed(5)}
            hideCoordinates={true}
          />

          {via.length > 0 && (
            <WaypointsAccordion via={via} />
          )}

          <RoutePointDisplay
            icon={
              <div className="w-7 h-7 bg-red-600 rounded-full flex items-center justify-center text-white font-bold shadow-sm">
                {isOpenEnded ? (
                  <span className="text-white text-xs font-medium">
                    {via.length + 1}
                  </span>
                ) : (
                  <Icon icon="mdi:flag-checkered" className="w-4 h-4" />
                )}
              </div>
            }
            label="To"
            address={end}
            cordinates={route?.waypoints?.[route.waypoints.length - 1]?.coordinate?.lat?.toFixed(5) + ', ' + route?.waypoints[route?.waypoints?.length - 1]?.coordinate?.lon?.toFixed(5)}
            hideCoordinates={true}
          />
        </div>

        {/* Options Summary */}
        <div className="bg-white shadow-md rounded-xl p-5 border border-gray-200">
          <div className="space-y-4">
            {/* Transport and Method Row */}
            <div className="flex items-center justify-between gap-3">
              <div className="flex-1">
                {isTruck ? (
                  <div title={truckProfileTitle} className="flex items-center space-x-2 bg-blue-100 px-3 py-2 rounded-lg border border-blue-200">
                    <Icon
                      icon={transportIcons[routeConfig.transportMode].icon}
                      className="w-5 h-5 text-blue-700"
                    />
                    <span className="text-sm font-medium text-blue-800">{transportIcons[routeConfig.transportMode].label}</span>
                  </div>
                ) : (
                  <div className="flex items-center space-x-2 bg-blue-100 px-3 py-2 rounded-lg border border-blue-200">
                    <Icon
                      icon={transportIcons[routeConfig.transportMode].icon}
                      className="w-5 h-5 text-blue-700"
                    />
                    <span className="text-sm font-medium text-blue-800">{transportIcons[routeConfig.transportMode].label}</span>
                  </div>
                )}
              </div>
              <div className="flex-1">
                <div className="flex items-center space-x-2 bg-green-100 px-3 py-2 rounded-lg border border-green-200">
                  <Icon
                    icon={routingMethodIcons[routeConfig.routingMethod].icon}
                    className="w-5 h-5 text-green-700"
                  />
                  <span className="text-sm font-medium text-green-800">{routingMethodIcons[routeConfig.routingMethod].label}</span>
                </div>
              </div>
            </div>

            {/* Optimization and Traffic */}
            <div className="flex items-center justify-between gap-3">
              <div className="flex-1">
                <div className="flex items-center space-x-2 bg-purple-100 px-3 py-2 rounded-lg border border-purple-200">
                  <Icon
                    icon={optimizationIcons[routeConfig.optimizationType].icon}
                    className="w-5 h-5 text-purple-700"
                  />
                  <span className="text-sm font-medium text-purple-800">{optimizationIcons[routeConfig.optimizationType].label}</span>
                </div>
              </div>
              <div className="flex-1">
                <div title={trafficDateTitle} className="flex items-center space-x-2 bg-amber-100 px-3 py-2 rounded-lg border border-amber-200">
                  <Icon
                    icon={trafficIcons[routeConfig.trafficMode].icon}
                    className="w-5 h-5 text-amber-700"
                  />
                  <span className="text-sm font-medium text-amber-800">{trafficIcons[routeConfig.trafficMode].label}</span>
                </div>
              </div>
            </div>

            {/* Return Trip - Adjusted to fit in the grid */}
            {routeConfig.isReturnTrip && (
              <div className="flex items-center gap-3">
                <div className="flex-1">
                  <div className="flex items-center space-x-2 bg-indigo-100 px-3 py-2 rounded-lg border border-indigo-200">
                    <Icon
                      icon="mdi:refresh"
                      className="w-5 h-5 text-indigo-700"
                    />
                    <span className="text-sm font-medium text-indigo-800">Aller-retour</span>
                  </div>
                </div>
                <div className="flex-1">
                  {/* Empty div to maintain grid layout */}
                </div>
              </div>
            )}

            {/* Divider */}
            {Object.entries(routeConfig.avoidFeatures).some(([, value]) => value) && (
              <div className="border-t border-gray-300 my-3"></div>
            )}

            {/* Avoid Features */}
            {Object.entries(routeConfig.avoidFeatures).some(([, value]) => value) && (
              <div className="flex flex-wrap gap-2">
                {Object.entries(routeConfig.avoidFeatures)
                  .filter(([, value]) => value)
                  .map(([feature]) =>
                  {
                    // Map features to specific colors
                    const colorMap: Record<string, { bg: string, border: string, text: string, icon: string }> = {
                      tolls: { bg: 'bg-yellow-100', border: 'border-yellow-200', text: 'text-yellow-800', icon: 'text-yellow-700' },
                      motorways: { bg: 'bg-blue-100', border: 'border-blue-200', text: 'text-blue-800', icon: 'text-blue-700' },
                      ferries: { bg: 'bg-cyan-100', border: 'border-cyan-200', text: 'text-cyan-800', icon: 'text-cyan-700' },
                      unpavedRoads: { bg: 'bg-orange-100', border: 'border-orange-200', text: 'text-orange-800', icon: 'text-orange-700' },
                      borderCrossings: { bg: 'bg-red-100', border: 'border-red-200', text: 'text-red-800', icon: 'text-red-700' }
                    };

                    const colors = colorMap[feature] || { bg: 'bg-gray-100', border: 'border-gray-200', text: 'text-gray-800', icon: 'text-gray-700' };

                    return (
                      <div
                        key={feature}
                        className={`flex items-center space-x-1.5 ${colors.bg} px-3 py-1.5 rounded-lg border ${colors.border}`}
                      >
                        <Icon
                          icon={avoidFeatureIcons[feature as keyof typeof avoidFeatureIcons].icon}
                          className={`w-4 h-4 ${colors.icon}`}
                        />
                        <span className={`text-sm font-medium ${colors.text}`}>
                          {avoidFeatureIcons[feature as keyof typeof avoidFeatureIcons].label}
                        </span>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
