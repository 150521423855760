import { useState, useCallback } from 'react';
import { useRouteStore } from '../../../../store/useRouteStore';
interface RouteFormState {
  error: string | null;
  isOptimizing: boolean;
  isRouteCalculating: boolean;
  hasInvalidPoint: boolean;
  showOptions: boolean;
}

export function useRouteValidation() {
  const [state, setState] = useState<RouteFormState>({
    error: null,
    isOptimizing: false,
    isRouteCalculating: false,
    hasInvalidPoint: false,
    showOptions: false
  });

  const { getStartPoint, getEndPoint, getViaPoints } = useRouteStore();

  const validatePoints = useCallback(() => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();
    const viaPoints = getViaPoints();

    const hasInvalid = [startPoint, endPoint, ...viaPoints].some(point => {
      if (!point) return false;
      if (point.location && !point.location.coordinate) return true;
      if ((point.type === 'start' || point.type === 'end') && point.status === 'empty') return true;
      return false;
    });

    setState(prev => ({ ...prev, hasInvalidPoint: hasInvalid }));
    return !hasInvalid;
  }, [getStartPoint, getEndPoint, getViaPoints]);

  const setError = useCallback((error: string | null) => {
    setState(prev => ({ ...prev, error }));
  }, []);

  return {
    state,
    validatePoints,
    setError
  };
}

export type UseRouteValidation = ReturnType<typeof useRouteValidation>;
