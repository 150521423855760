import { memo } from 'react';
import type { AutocompleteElem } from '../../services/geocoding-autocomplete/response-types';
import type { Coordinate } from '../../services/common/types';
import { GeocodingAutoComplete } from './AutoComplete';
import { useAppStore } from '../../store/useAppStore';

interface SearchBarProps {
  mapCenter: Coordinate;
}

export const SearchBar = memo(({ mapCenter }: SearchBarProps) => {
  const setGeocodingLocation = useAppStore(state => state.setGeocodingLocation);

  return (
    <div className="absolute top-4 left-1/2 -translate-x-1/2 w-96 z-10">
      <GeocodingAutoComplete
        onLocationSelect={(location: AutocompleteElem | null) => setGeocodingLocation(location)}
        mapCenter={mapCenter}
      />
    </div>
  );
});

SearchBar.displayName = 'SearchBar';
