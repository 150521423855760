import { useState, useCallback, useEffect } from 'react';
import { useRouteStore } from '../../../../store/useRouteStore';
import { useRouteMutation } from '../../../../services/routing/mutation';
import { processOptimizationResults } from '../OptimizationResults';
import type { RoutePoint } from '../types';
import type { RoutingResponse } from '../../../../services/routing/response-types';

interface CalculationState {
  isOptimizing: boolean;
  isRouteCalculating: boolean;
  error: string | null;
}

export function useRouteCalculation() {
  const [state, setState] = useState<CalculationState>({
    isOptimizing: false,
    isRouteCalculating: false,
    error: null
  });

  // Auto-clear error messages after 5 seconds
  useEffect(() =>
  {
    if (state.error)
    {
      const timer = setTimeout(() =>
      {
        setState(prev => ({ ...prev, error: null }));
      }, 5000); // 5 seconds delay

      // Cleanup function to prevent memory leaks
      return () => clearTimeout(timer);
    }
  }, [state.error]);

  const {
    getStartPoint,
    getEndPoint,
    getViaPoints,
    reorderPoints,
    setRouteResults,
    routeConfig: {
      optimizationType,
      isReturnTrip,
      avoidFeatures,
      routingMethod,
      trafficMode,
      trafficHistoryDate,
      showRoutesheet,
      transportMode,
      truckProfile
    },
    setSelectedPolyline
  } = useRouteStore();

  const { mutate: calculateRoute, isPending } = useRouteMutation();

  const handleComputeRoute = useCallback(() => {
    if (state.isRouteCalculating) return;

    setState(prev => ({
      ...prev,
      isRouteCalculating: true,
      error: null
    }));
    useRouteStore.getState().setIsCalculating(true);

    const startPoint = getStartPoint();
    const endPoint = getEndPoint();

    if (!startPoint?.lat || !startPoint?.lng || !endPoint?.lat || !endPoint?.lng ||
      startPoint.status === 'empty' || endPoint.status === 'empty') {
      setState(prev => ({
        ...prev,
        isRouteCalculating: false,
        error: 'Please select both start and end points'
      }));
      return;
    }

    const points = [startPoint, ...getViaPoints(), endPoint];
    const validPoints = points.filter(p => p.status === 'set') as RoutePoint[];

    // Enable optimization if type is set and we have via points
    const isOptimizationEnabled = optimizationType !== 'none' && validPoints.length > 2;
    if (isOptimizationEnabled) {
      setState(prev => ({ ...prev, isOptimizing: true }));
    }

    const currentConfig = {
      optimizationType,
      isReturnTrip,
      avoidFeatures,
      routingMethod,
      trafficMode,
      trafficHistoryDate,
      showRoutesheet,
      transportMode,
      truckProfile
    };

    calculateRoute(
      {
        start: {
          coordinateSat: {
            lon: validPoints[0].lng!,
            lat: validPoints[0].lat!
          }
        },
        end: {
          coordinateSat: {
            lon: validPoints[validPoints.length - 1].lng!,
            lat: validPoints[validPoints.length - 1].lat!
          }
        },
        viaPoints: validPoints.slice(1, -1).map(point => ({
          coordinateSat: {
            lon: point.lng!,
            lat: point.lat!
          }
        })),
        isReturnTrip,
        optimizationType: isOptimizationEnabled ? optimizationType : 'none',
        avoidFeatures,
        routingMethod,
        trafficMode,
        trafficHistoryDate,
        showRoutesheet,
        transportMode,
        truckProfile
      },
      {
        onSuccess: (response: RoutingResponse) => {
          if (!response.routingRoutes?.length) {
            setState(prev => ({
              ...prev,
              error: 'No routes found for the given points'
            }));
            return;
          }

          if (isOptimizationEnabled) {
            // Get reordered points based on optimization type
            const reorderedPoints = processOptimizationResults(
              response,
              validPoints,
              optimizationType
            );

            // Update store with reordered points
            const orderedIds = reorderedPoints.map(p => p.id);
            reorderPoints(orderedIds);
          }

          // Convert response to RouteResults format
          const routeResults = {
            routes: response.routingRoutes.map(route => ({
              length: route.length,
              duration: route.duration,
              averageSpeed: route.averageSpeed,
              trafficDelay: route.trafficDelay,
              polyline: route.polyline,
              routingInstructions: route.routingInstructions,
              tollInfo: route.tollInfo,
              waypoints: route.waypoints || [],
            })),
            usedDestinations: response.usedDestinations,
            usedConfig: {
              ...currentConfig,
              truckProfile: transportMode === 'TRUCK' ? truckProfile : null
            }
          };

          // Set route results and selected polyline
          setRouteResults(routeResults);
          setSelectedPolyline(routeResults.routes[0].polyline);
        },
        onError: (error: Error) => {
          console.error('Route computation error:', error);
          setState(prev => ({
            ...prev,
            error: error.message || 'Failed to compute route'
          }));
        },
        onSettled: () => {
          setState(prev => ({
            ...prev,
            isOptimizing: false,
            isRouteCalculating: false
          }));

          useRouteStore.getState().setIsCalculating(false);
        }
      }
    );
  }, [
    state.isRouteCalculating,
    getStartPoint,
    getEndPoint,
    getViaPoints,
    setRouteResults,
    reorderPoints,
    optimizationType,
    isReturnTrip,
    avoidFeatures,
    routingMethod,
    trafficMode,
    trafficHistoryDate,
    showRoutesheet,
    transportMode,
    truckProfile,
    calculateRoute,
    setSelectedPolyline
  ]);

  return {
    state,
    handleComputeRoute,
    isPending
  };
}

export type UseRouteCalculation = ReturnType<typeof useRouteCalculation>;
