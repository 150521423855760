import { memo, useState, useCallback } from "react";
import { Icon } from "@iconify/react";
import { TrafficMode } from "../routingUtilities/types";
import { TrafficDateModal } from "./TrafficDateModal";

const trafficModes: Record<TrafficMode, { icon: string; tooltip: string }> = {
  none: {
    icon: "tabler:traffic-lights-off",
    tooltip: "Pas de trafic"
  },
  live: {
    icon: "emojione-monotone:vertical-traffic-light",
    tooltip: "Trafic en direct"
  },
  historical: {
    icon: "carbon:update-now",
    tooltip: "Statistiques historiques"
  }
};

interface TrafficOptionsProps {
  selectedMode?: TrafficMode;
  onModeChange?: (mode: TrafficMode) => void;
  trafficHistoryDate: Date | null;
  onTrafficHistoryDateChange?: (date: Date | null) => void;
}

export const TrafficOptions = memo(function TrafficOptions({
  selectedMode = 'live',
  onModeChange,
  trafficHistoryDate,
  onTrafficHistoryDateChange
}: TrafficOptionsProps) {
  const [showDateModal, setShowDateModal] = useState(false);

  const handleModeChange = useCallback((mode: TrafficMode) => {
    onModeChange?.(mode);

    // If switching to historical mode and no date set, set to today
    if (mode === 'historical' && !trafficHistoryDate) {
      onTrafficHistoryDateChange?.(new Date());
    }
  }, [onModeChange, trafficHistoryDate, onTrafficHistoryDateChange]);

  const handleDateChange = useCallback((date: Date | null) => {
    onTrafficHistoryDateChange?.(date);
    setShowDateModal(false);
  }, [onTrafficHistoryDateChange]);

  const getTooltipText = useCallback((mode: TrafficMode) => {
    if (mode === 'historical' && trafficHistoryDate) {
      return `${trafficModes[mode].tooltip} - ${trafficHistoryDate.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${trafficHistoryDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    }
    return trafficModes[mode].tooltip;
  }, [trafficHistoryDate]);

  return (
    <div>
      <h4 className="text-xs font-medium text-gray-500 mb-2">Trafic</h4>
      <div className="flex gap-2">
        {Object.entries(trafficModes).map(([mode, { icon }]) => (
          <div key={mode} className="flex items-center">
            <button
              onClick={() => handleModeChange(mode as TrafficMode)}
              title={getTooltipText(mode as TrafficMode)}
              className={`p-3 rounded-md transition-all hover:scale-110 ${
                mode === selectedMode
                  ? "bg-blue-600 text-white shadow-md shadow-blue-500/20"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              <Icon icon={icon} className="text-2xl" />
            </button>

            {/* Settings icon for historical traffic */}
            {mode === 'historical' && selectedMode === 'historical' && (
              <button
                onClick={() => setShowDateModal(true)}
                className="ml-1 p-2 rounded-md transition-all hover:scale-110 bg-gray-100 text-gray-700 hover:bg-gray-200"
                title={`Configurer la date et l'heure du trafic historique${trafficHistoryDate ? ` (${trafficHistoryDate.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${trafficHistoryDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })})` : ''}`}
              >
                <Icon icon="mdi:calendar" className="text-lg" />
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Date picker modal */}
      {showDateModal && (
        <TrafficDateModal
          initialDate={trafficHistoryDate}
          onSave={handleDateChange}
          onClose={() => setShowDateModal(false)}
        />
      )}
    </div>
  );
});
