import type { RoutePoint } from '../../routingUtilities/types';
import { formatCoordinates, formatDistance, formatDuration } from '../utils/formatters';
import { POINT_COLORS } from '../../routingUtilities/colors';
import type { UsedDestination } from '../../../../services/routing/response-types';

// Define a simplified route type based on what we need
interface RouteData
{
    length: number;
    duration: number;
}

export const createRoutePointsHTML = (
    startPoint: RoutePoint | null,
    endPoint: RoutePoint | null,
    viaPoints: RoutePoint[],
    usedDestinations?: UsedDestination[],
    route?: RouteData,
    startIndex: number = 1 // Default to 1 if not specified
): HTMLDivElement =>
{
    // Calculate actual time and distance for each point using usedDestinations data
    const calculatePointInfo = (index: number, totalPoints: number, type: 'start' | 'end' | 'via') =>
    {
        if (!usedDestinations || !route)
        {
            // Fallback to estimated values if no actual data is available
            if (type === 'start')
            {
                return {
                    distance: formatDistance(0),
                    time: formatDuration(0)
                };
            } else if (type === 'end')
            {
                return {
                    distance: formatDistance(route?.length || 0),
                    time: formatDuration(route?.duration || 0)
                };
            } else
            {
                // For via points, estimate based on position
                const position = index / (totalPoints + 1);
                const estimatedDistance = Math.round((route?.length || 0) * position);
                const estimatedTime = Math.round((route?.duration || 0) * position);

                return {
                    distance: formatDistance(estimatedDistance),
                    time: formatDuration(estimatedTime)
                };
            }
        }

        // Find the corresponding usedDestination based on type and index
        let usedDestination: UsedDestination | undefined;

        if (type === 'start')
        {
            // Start point is typically the first usedDestination
            usedDestination = usedDestinations.find(d => d.inputOrder === 0);
            return {
                distance: formatDistance(0),
                time: formatDuration(0)
            };
        } else if (type === 'end')
        {
            // End point is typically the last usedDestination
            usedDestination = usedDestinations.find(d => d.inputOrder === usedDestinations.length - 1);
            return {
                distance: formatDistance(route?.length || 0),
                time: formatDuration(route?.duration || 0)
            };
        } else
        {
            // For via points, find by inputOrder
            usedDestination = usedDestinations.find(d => d.inputOrder === index);

            if (usedDestination)
            {
                return {
                    distance: formatDistance(usedDestination.length || 0),
                    time: formatDuration(usedDestination.duration || 0)
                };
            } else
            {
                // Fallback if no matching usedDestination is found
                const position = index / (totalPoints + 1);
                const estimatedDistance = Math.round((route?.length || 0) * position);
                const estimatedTime = Math.round((route?.duration || 0) * position);

                return {
                    distance: formatDistance(estimatedDistance),
                    time: formatDuration(estimatedTime)
                };
            }
        }
    };

    // Generate HTML for a single point
    const createPointHTML = (point: RoutePoint, index: number, type: 'start' | 'end' | 'via', totalViaPoints: number) =>
    {
        // Define icon and colors based on point type
        let icon = '';
        let bgColor = '';
        let textColor = '';
        let label = '';

        if (type === 'start')
        {
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon>
            </svg>`;
            bgColor = POINT_COLORS.start + '33'; // Adding transparency
            textColor = POINT_COLORS.start;
            label = 'Départ';
        } else if (type === 'end')
        {
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="8 12 12 16 16 12"></polyline>
                <line x1="12" y1="8" x2="12" y2="16"></line>
            </svg>`;
            bgColor = POINT_COLORS.end + '33'; // Adding transparency
            textColor = POINT_COLORS.end;
            label = 'Arrivée';
        } else
        {
            // Get via point color based on index (mod by color array length to avoid out of bounds)
            const colorIndex = (index - 1) % POINT_COLORS.via.length;
            const viaColor = POINT_COLORS.via[colorIndex];
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
    <text x="12" y="16" font-size="14" font-weight="bold" text-anchor="middle" fill="currentColor">${index}</text>
</svg>`;
            bgColor = viaColor + '33'; // Adding transparency
            textColor = viaColor;
            label = `Via ${index}`;
        }

        // Get actual info for all points
        const pointInfo = calculatePointInfo(index, totalViaPoints, type);

        return `
            <div style="display: flex; margin-bottom: ${type === 'end' ? '0' : '10px'}; position: relative;">
                ${type !== 'end' ? `
                <div style="position: absolute; top: 30px; bottom: 0; left: 15px; width: 2px; background: #cbd5e1; z-index: 1;"></div>
                ` : ''}

                <div style="width: 30px; height: 30px; background: ${bgColor}; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: ${textColor}; margin-right: 10px; z-index: 2; flex-shrink: 0;">
                    ${icon}
                </div>

                <div style="flex: 1; background: #f1f5f9; border-radius: 6px; padding: 8px; border-left: 3px solid ${textColor};">
                    <div style="font-size: 12px; color: #0369a1; margin-bottom: 2px; font-weight: 500;">
                        ${label}
                    </div>
                    <div style="font-size: 14px; font-weight: bold; color: #0f172a; margin-bottom: 4px;">
                        ${point.location?.place || 'Emplacement non défini'}
                    </div>

                    <div style="display: flex; flex-wrap: wrap; gap: 6px; align-items: center;">
                        ${point.lat && point.lng ? `
                        <div style="font-size: 11px; color: #0369a1; font-family: monospace; background: #e0f2fe; padding: 2px 6px; border-radius: 4px; display: inline-block; font-weight: 500;">
                            ${formatCoordinates(point.lat, point.lng)}
                        </div>
                        ` : ''}

                        <div style="display: flex; gap: 6px;">
                            <div style="font-size: 11px; color: #0369a1; background: #e0f2fe; padding: 2px 6px; border-radius: 4px; display: flex; align-items: center; font-weight: 500;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin-right: 3px;">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 6 12 12 16 14"></polyline>
                                </svg>
                                ${pointInfo.time}
                            </div>
                            <div style="font-size: 11px; color: #0369a1; background: #e0f2fe; padding: 2px 6px; border-radius: 4px; display: flex; align-items: center; font-weight: 500;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" style="margin-right: 3px;">
                                    <polyline points="18 9 21 12 18 15"></polyline>
                                    <polyline points="6 15 3 12 6 9"></polyline>
                                    <line x1="3" y1="12" x2="21" y2="12"></line>
                                </svg>
                                ${pointInfo.distance}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    // Generate HTML for all points
    let pointsHTML = '';
    const totalViaPoints = viaPoints.length;

    // Add start point if provided
    if (startPoint)
    {
        pointsHTML += createPointHTML(startPoint, 0, 'start', totalViaPoints);
    }

    // Add via points
    viaPoints.forEach((point, index) =>
    {
        // Use startIndex + index for consistent numbering across pages
        const viaIndex = startIndex + index;
        pointsHTML += createPointHTML(point, viaIndex, 'via', totalViaPoints);
    });

    // Add end point if provided
    if (endPoint)
    {
        pointsHTML += createPointHTML(endPoint, 0, 'end', totalViaPoints);
    }

    const html = `
        <div style="display: flex; flex-direction: column; padding: 10px; background: white; border-radius: 8px; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); width: 100%;">
            <div style="display: flex; flex-direction: column;">
                ${pointsHTML}
            </div>
        </div>
    `;

    const div = document.createElement('div');
    div.innerHTML = html;
    return div;
};