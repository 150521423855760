import { useState, useCallback, useRef, useEffect } from 'react';
import { useAutocompleteMutation } from '../../../services/geocoding-autocomplete/mutation';
import { UseMutationResult } from '@tanstack/react-query';
import type { AutocompleteElem, AutocompleteResponse, AutocompleteError } from '../../../services/geocoding-autocomplete/response-types';
import type { AutocompleteMutationProps } from '../../../services/geocoding-autocomplete/mutation';
import type { Coordinate } from '../../../services/common/types';
import { useAppStore } from '../../../store/useAppStore';

interface AutoCompleteProps {
  onLocationSelect: (location: AutocompleteElem | null) => void;
  mapCenter: Coordinate;
  disableLocation?: boolean;
  initialLocation?: AutocompleteElem | null;
  onBlur?: () => void;
  disabled?: boolean;
  onLoadingChange?: (isLoading: boolean) => void;
}

export const AutoComplete = ({
  onLocationSelect,
  mapCenter,
  disableLocation,
  initialLocation,
  onBlur,
  disabled = false,
  onLoadingChange
}: AutoCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState(initialLocation?.place || '');
  const [isFocused, setIsFocused] = useState(false);
  const [useLocation, setUseLocation] = useState(false);
  const [userLocation, setUserLocation] = useState<Coordinate | null>(null);
  const [results, setResults] = useState<AutocompleteElem[]>([]);
  const searchTimeoutRef = useRef<number | null>(null);
  const currentLocationRef = useRef<AutocompleteElem | null>(initialLocation || null);
  const isDirtyRef = useRef(false);
  const isGeocodingModule = useAppStore(state => state.activeModule === null);

  useEffect(() => {
    if (initialLocation?.place) {
      currentLocationRef.current = initialLocation;
      // Only update text if not focused (user not typing)
      if (!isFocused) {
        setSearchText(initialLocation.place);
      }
    } else {
      // Clear text if location is cleared and not currently being edited
      if (!isFocused) {
        setSearchText('');
        currentLocationRef.current = null;
      }
    }
  }, [initialLocation, isFocused]);

  const { mutate: searchLocation, isPending } = useAutocompleteMutation() as UseMutationResult<
    AutocompleteResponse,
    AutocompleteError,
    AutocompleteMutationProps
  >;

  useEffect(() => {
    onLoadingChange?.(isPending);
  }, [isPending, onLoadingChange]);

  useEffect(() => {
    let mounted = true;

    if (useLocation && !userLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (mounted) {
            setUserLocation({
              lat: position.coords.latitude,
              lon: position.coords.longitude
            });
          }
        },
        (error) => {
          if (mounted) {
            console.error('Error getting location:', error);
            setUseLocation(false);
          }
        }
      );
    }

    return () => {
      mounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocation]);

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        window.clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const debouncedSearch = useCallback((text: string) => {
    if (searchTimeoutRef.current) {
      window.clearTimeout(searchTimeoutRef.current);
    }

    if (text.length < 2) {
      setResults([]);
      return;
    }

    searchTimeoutRef.current = window.setTimeout(() => {
      searchLocation(
        {
          searchText: text,
          coordinate: useLocation ? userLocation ?? mapCenter : mapCenter
        },
        {
          onSuccess: (data) => {
            setResults(data.items);
          },
          onError: (error) => {
            console.error('Search error:', error);
            setResults([]);
          }
        }
      );
    }, 300);
  }, [searchLocation, userLocation, useLocation, mapCenter]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const text = e.target.value;
    setSearchText(text);
    isDirtyRef.current = true;

    if (text === '') {
      // Only clear location if the text was cleared by typing,
      // not by pressing backspace on an already empty input
      if (searchText !== '') {
        onLocationSelect(null);
        currentLocationRef.current = null;
      }
    } else {
      debouncedSearch(text);
    }
  }, [debouncedSearch, onLocationSelect, disabled]);

  const handleResultClick = useCallback((result: AutocompleteElem) => {
    try {
      if (!result?.place) {
        throw new Error("Invalid location selected");
      }

      setSearchText(result.place);
      setResults([]);
      currentLocationRef.current = result;
      isDirtyRef.current = false;
      onLocationSelect(result);
    } catch (error) {
      console.error('Error handling location selection:', error);
      setResults([]);
    }
  }, [onLocationSelect]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsFocused(false);
      setResults([]);

      if (isDirtyRef.current) {
        // Only restore previous value if dirty and a location was selected
        if (currentLocationRef.current?.place) {
          setSearchText(currentLocationRef.current.place);
        }
        isDirtyRef.current = false;
        onBlur?.();
      }
    }, 200);
  }, [onBlur]);

  const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left: number; width: number }>({
    top: 0,
    left: 0,
    width: 0
  });

  const updateDropdownPosition = useCallback(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + 8,
        left: rect.left,
        width: rect.width
      });
    }
  }, []);

  // Update position when input is focused or window is scrolled/resized
  useEffect(() => {
    if (isFocused) {
      updateDropdownPosition();
      window.addEventListener('scroll', updateDropdownPosition, true);
      window.addEventListener('resize', updateDropdownPosition);

      return () => {
        window.removeEventListener('scroll', updateDropdownPosition, true);
        window.removeEventListener('resize', updateDropdownPosition);
      };
    }
  }, [isFocused, updateDropdownPosition]);

  const handleFocus = useCallback(() => {
    if (!disabled) {
      setIsFocused(true);
      // Only set dirty if there's existing text
      if (searchText) {
        isDirtyRef.current = true;
      }
    }
  }, [disabled, searchText]);

  return (
    <div className="relative w-full">
      <input
        ref={inputRef}
        type="text"
        value={searchText}
        onChange={handleSearchChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="Search location..."
        className={`
          w-full px-4 pr-11 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent
          ${disabled ? 'opacity-75' : ''}
        `}
      />

      {isFocused && !disableLocation && isGeocodingModule && (
        <div className="absolute w-full p-2 mt-1 bg-white border rounded-lg shadow-lg">
          <label className="flex items-center space-x-2 text-sm text-gray-600">
            <input
              type="checkbox"
              checked={useLocation}
              onChange={(e) => setUseLocation(e.target.checked)}
              className="rounded text-blue-500 focus:ring-blue-500"
            />
            <span>Use current location to improve search results</span>
          </label>
        </div>
      )}

      {results.length > 0 && isFocused && (
        <div
          className="fixed bg-white border rounded-lg shadow-lg z-[9999] max-h-[40vh] flex flex-col"
          style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            width: `${dropdownPosition.width}px`
          }}
        >
          <div className="overflow-y-auto">
            {results.map((result, index) => (
              <div
                key={`${result.place}-${index}`}
                className="px-4 py-2 flex items-center justify-between hover:bg-gray-100 cursor-pointer"
                onClick={() => handleResultClick(result)}
              >
                <div>
                  <div className="font-medium">{result.place}</div>
                  {result.addressLabel && (
                    <div className="text-sm text-gray-600">{result.addressLabel}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isPending && (
        <div className="absolute right-3 top-2.5">
          <div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};
