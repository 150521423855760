import { memo } from "react";
import { Icon } from "@iconify/react";
import type { AvoidFeatures } from "../../../services/routing/request-types";

const avoidIcons: Record<keyof AvoidFeatures, { icon: string; tooltip: string }> = {
  tolls: {
    icon: "mdi:cash-multiple",
    tooltip: "Éviter les péages"
  },
  motorways: {
    icon: "mdi:highway",
    tooltip: "Éviter les autoroutes"
  },
  ferries: {
    icon: "mdi:ferry",
    tooltip: "Éviter les ferries"
  },
  unpavedRoads: {
    icon: "mdi:road-variant",
    tooltip: "Éviter les routes non pavées"
  },
  borderCrossings: {
    icon: "mdi:passport",
    tooltip: "Éviter les passages frontières"
  }
};

interface AvoidFeatureOptionsProps {
  avoidFeatures: AvoidFeatures;
  onAvoidFeatureChange: (feature: keyof AvoidFeatures, value: boolean) => void;
}

export const AvoidFeatureOptions = memo(function AvoidFeatureOptions({
  avoidFeatures,
  onAvoidFeatureChange
}: AvoidFeatureOptionsProps) {
  return (
    <div>
      <h4 className="text-xs font-medium text-gray-500 mb-2">Restrictions</h4>
      <div className="flex gap-2">
        {(Object.entries(avoidFeatures) as [keyof AvoidFeatures, boolean][]).map(([key, checked]) => (
          <button
            key={key}
            onClick={() => onAvoidFeatureChange(key, !avoidFeatures[key])}
            title={avoidIcons[key].tooltip}
            className={`p-3 rounded-md transition-all hover:scale-110 ${
              checked
                ? "bg-blue-600 text-white shadow-md shadow-blue-500/20"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            <Icon icon={avoidIcons[key].icon} className="text-2xl" />
          </button>
        ))}
      </div>
    </div>
  );
});
