import { createContext, useContext, useState, useRef, useCallback } from 'react';
import type { ReactNode } from 'react';
import { useRouteStore } from '../../../store/useRouteStore';
import type { RoutePoint } from '../routingUtilities/types';

interface RouteDragContextType {
  draggedId: string | null;
  draggedOverId: string | null;
  isCalculating: boolean;
  handleDragStart: (id: string) => void;
  handleDragEnd: () => void;
  handleDragOver: (id: string) => void;
  handleDrop: (targetId: string) => void;
}

const RouteDragContext = createContext<RouteDragContextType | null>(null);

interface RouteDragProviderProps {
  children: ReactNode;
  isCalculating: boolean;
}

export function RouteDragProvider({ children, isCalculating }: RouteDragProviderProps) {
  const [draggedOverId, setDraggedOverId] = useState<string | null>(null);
  const draggedId = useRef<string | null>(null);
  const { getStartPoint, getEndPoint, getViaPoints, reorderPoints } = useRouteStore();

  const getAllPoints = useCallback((): RoutePoint[] => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();
    const viaPoints = getViaPoints();
    return [startPoint, ...viaPoints, endPoint].filter((p): p is RoutePoint => p !== undefined);
  }, [getStartPoint, getEndPoint, getViaPoints]);

  const handleDragStart = useCallback((id: string) => {
    if (isCalculating) return;
    draggedId.current = id;
  }, [isCalculating]);

  const handleDragEnd = useCallback(() => {
    draggedId.current = null;
    setDraggedOverId(null);
  }, []);

  const handleDragOver = useCallback((id: string) => {
    if (draggedId.current === id) return;
    setDraggedOverId(id);
  }, []);

  const handleDrop = useCallback((targetId: string) => {
    const sourceId = draggedId.current;
    if (!sourceId || sourceId === targetId || isCalculating) return;

    const allPoints = getAllPoints();
    const orderedIds = allPoints.map(p => p.id);
    const fromIndex = orderedIds.indexOf(sourceId);
    const toIndex = orderedIds.indexOf(targetId);

    if (fromIndex === -1 || toIndex === -1) return;

    orderedIds.splice(toIndex, 0, orderedIds.splice(fromIndex, 1)[0]);
    reorderPoints(orderedIds);

    draggedId.current = null;
    setDraggedOverId(null);
  }, [getAllPoints, reorderPoints, isCalculating]);

  const value = {
    draggedId: draggedId.current,
    draggedOverId,
    isCalculating,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDrop
  };

  return (
    <RouteDragContext.Provider value={value}>
      {children}
    </RouteDragContext.Provider>
  );
}

export const useRouteDrag = () => {
  const context = useContext(RouteDragContext);
  if (!context) {
    throw new Error('useRouteDrag must be used within a RouteDragProvider');
  }
  return context;
};
