import { useAuth } from '../../auth/hooks/useAuth';

export function LogoutButton() {
  const { logout } = useAuth();

  return (
    <div className="absolute bottom-5 left-5 z-[1000]">
      <button
        onClick={logout}
        className="w-14 h-14 bg-white shadow-lg flex items-center justify-center hover:bg-gray-50 rounded-full"
        title="Déconnexion"
      >
        <svg width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#dc2626" strokeWidth="2.5">
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" strokeLinecap="round" strokeLinejoin="round"/>
          <polyline points="16 17 21 12 16 7" strokeLinecap="round" strokeLinejoin="round"/>
          <line x1="21" y1="12" x2="9" y2="12" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </div>
  );
}
