import Map from '../map/Map';

function MapHome() {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <Map />
    </div>
  );
}

export default MapHome;
