import { useRouteStore } from '../../../store/useRouteStore';
import { memo } from 'react';
import { OptimizationType } from '../routingUtilities/types';

interface RouteActionsProps
{
  onAddViaPoint: () => void;
  onToggleOptions: () => void;
  onToggleReturnTrip: () => void;
  isReturnTrip: boolean;
  // isLoading: boolean;
  canCompute: boolean;
  canAddVia: boolean;
  onCompute: () => void;
  optimizationType: OptimizationType;
  showOptions?: boolean;
}

export const RouteActions = memo(function RouteActions({
  onAddViaPoint,
  onToggleOptions,
  onToggleReturnTrip,
  isReturnTrip,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // isLoading,
  canCompute,
  canAddVia,
  onCompute,
  optimizationType,
  showOptions = false
}: RouteActionsProps)
{
  const canSimulate = useRouteStore(state => state.canSimulate());
  const isSimulating = useRouteStore(state => state.isSimulating);
  const playSimulation = useRouteStore(state => state.playSimulation);
  const stopSimulation = useRouteStore(state => state.stopSimulation);
  const isCalculating = useRouteStore(state => state.isCalculating);

  const handleSimulationClick = () =>
  {
    if (isSimulating)
    {
      stopSimulation();
    } else
    {
      playSimulation();
    }
  };

  return (
    <div className="flex justify-between items-center mt-6">
      <div className="flex gap-2">
        <button
          onClick={onAddViaPoint}
          className={`p-2 rounded-full ${!canAddVia
            || isCalculating
            ? 'bg-gray-100 cursor-not-allowed opacity-50'
            : 'bg-blue-100 hover:bg-blue-200'
            }`}
          disabled={!canAddVia || isCalculating}
          title={canAddVia ? "Ajouter un point intermédiaire" : "Définissez d'abord les points de départ et d'arrivée"}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={!canAddVia ? "#9ca3af" : "#1e3a8a"} strokeWidth="2">
            <path d="M12 5v14M5 12h14" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <button
          onClick={onToggleOptions}
          className={`p-2 rounded-full transition-all duration-300 ${isCalculating ? 'bg-gray-100 cursor-not-allowed opacity-50' : showOptions ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-blue-100 hover:bg-blue-200'}`}
          disabled={isCalculating}
          title="Options"
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={showOptions ? "#ffffff" : "#1e3a8a"} strokeWidth="2" className={`transition-transform duration-300 ${showOptions ? 'rotate-90' : ''}`}>
            <circle cx="12" cy="12" r="3" />
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
          </svg>
        </button>
        <button
          onClick={onToggleReturnTrip}
          disabled={optimizationType !== 'none'}
          className={`p-2 rounded-full ${isReturnTrip ? 'bg-blue-500 hover:bg-blue-600' : 'bg-blue-100 hover:bg-blue-200'}
            ${optimizationType !== 'none' ? 'opacity-50 cursor-not-allowed' : ''}`}
          title="Aller-retour"
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={isReturnTrip ? "white" : "#1e3a8a"} strokeWidth="2">
            <path d="M7 16V4M7 4L3 8M7 4L11 8M17 8V20M17 20L21 16M17 20L13 16" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <button
          onClick={handleSimulationClick}
          disabled={!canSimulate || isCalculating}
          className={`p-2 rounded-full ${!canSimulate
            || isCalculating
            ? 'bg-gray-100 cursor-not-allowed opacity-50'
            : isSimulating
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-blue-100 hover:bg-blue-200'
            }`}
          title={isSimulating ? "Arrêter la simulation" : "Simuler l'itinéraire"}
        >
          {isSimulating ? (
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
              <rect x="6" y="4" width="4" height="16" strokeLinecap="round" strokeLinejoin="round" />
              <rect x="14" y="4" width="4" height="16" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ) : (
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={!canSimulate ? "#9ca3af" : "#1e3a8a"} strokeWidth="2">
              <polygon points="5,3 19,12 5,21" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          )}
        </button>
      </div>
      <button
        onClick={onCompute}
        disabled={!canCompute || isCalculating}
        className={`bg-blue-600 text-white px-4 py-2 rounded-lg ${!canCompute || isCalculating
          ? 'opacity-50 cursor-not-allowed'
          : 'hover:bg-blue-700'
          }`}
      >
        {isCalculating ? (
          <div className="flex items-center space-x-2">
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span></span>
          </div>
        ) : "Calculer"}
      </button>
    </div>
  );
});
