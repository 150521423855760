import L from 'leaflet';

const colors = {
  start: '#22c55e', // green
  via: '#3b82f6',   // blue
  end: '#ef4444',   // red
};

type PointType = 'start' | 'via' | 'end';

export const getPointIcon = (type: PointType, index: number, customColor?: string, useNumberedEndMarker = false) =>
{
  // For start points, use the start flag
  if (type === 'start') {
    const html = `
      <div class="flag-wrapper">
        <img src="/start-flag.png" alt="Start flag" />
      </div>
    `;
    return L.divIcon({
      html,
      className: `marker-icon marker-${type}`,
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });
  }

  // For end points, use either the end flag or a numbered marker
  if (type === 'end' && !useNumberedEndMarker)
  {
    const html = `
      <div class="flag-wrapper">
        <img src="/end-flag.png" alt="End flag" />
      </div>
    `;
    return L.divIcon({
      html,
      className: `marker-icon marker-${type}`,
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });
  }

  // For via points and numbered end points, use the pin with numbers
  const number = type === 'end' ? index.toString() : index.toString();
  const color = customColor || colors[type];

  const html = `
    <div class="marker-pin" data-number="${number}" style="background-color: ${color};"></div>
  `;

  return L.divIcon({
    className: `marker-icon marker-${type}`,
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    html
  });
};
