import { useEffect, useState } from 'react';

interface ErrorMessageProps {
  message: string | null;
}

export function ErrorMessage({ message }: ErrorMessageProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() =>
  {
    if (!message) return;

    // Make the error message visible
    setIsVisible(true);

    // No need to clean up as the component will unmount when message becomes null
  }, [message]);

  if (!message) return null;

  return (
    <div
      className={`mb-4 bg-red-50 text-red-700 rounded-lg overflow-hidden shadow-md transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className="p-3 flex items-center">
        <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path>
        </svg>
        <span>{message}</span>
      </div>
      <div className="h-1 bg-red-600 error-progress-bar" />
    </div>
  );
}
