import { useMutation } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { AutocompleteRequest } from './request-types';
import { AutocompleteResponse, AutocompleteError } from './response-types';
import { Coordinate } from '../common/types';
import { getCurrentGeoserver } from '../common/config';

const AUTOCOMPLETE_ENDPOINT = 'service/geocoding/autocomplete/1.0';

export interface AutocompleteMutationProps {
  searchText: string;
  coordinate: Coordinate;
  language?: string;
}

export const useAutocompleteMutation = () => {
  return useMutation<AutocompleteResponse, AutocompleteError, AutocompleteMutationProps>({
    mutationKey: ['autocomplete', AUTOCOMPLETE_ENDPOINT],
    meta: {
      endpoint: AUTOCOMPLETE_ENDPOINT
    },
    mutationFn: async ({
      searchText,
      coordinate,
      language
    }) => {
      try {
        // Log request in development or debug mode
        if (import.meta.env.DEV)
        {
          // Always use the full URL for logging
          const apiBaseUrl = import.meta.env.VITE_APP_BASE_API || 'https://bemap-preprod.benomad.com/bgis';
          console.log(`Autocomplete request to: ${apiBaseUrl}/${AUTOCOMPLETE_ENDPOINT}`, { searchText, coordinate });
        }

        // Get geoserver based on priority (herehlp -> nominatim -> default)
        const geoserver = getCurrentGeoserver();

        // Construct request payload
        const request: AutocompleteRequest = {
          place: searchText,
          addressDetails: true,
          coordinate,
          geoserver,
          language
        };

        // Make API request
        const response = await apiClient.post<AutocompleteResponse>({
          url: AUTOCOMPLETE_ENDPOINT,
          data: request
        });

        return response;
      } catch (error) {
        // Enhanced error logging
        console.error('Autocomplete mutation error:', {
          message: error instanceof Error ? error.message : 'Unknown error',
          searchText,
          endpoint: AUTOCOMPLETE_ENDPOINT
        });

        // Handle specific geoserver errors
        if (error instanceof Error) {
          if (error.message.includes('No geoservers available') ||
              error.message.includes('No default geoserver')) {
            throw new Error('Please log in again to access geocoding services');
          }
          throw new Error(`Geocoding error: ${error.message}`);
        }
        throw new Error('An error occurred while searching locations');
      }
    }
  });
};
