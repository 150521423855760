import { ReactNode, useState } from 'react';

interface RoutePointDisplayProps {
  icon: ReactNode;
  label: string;
  address: string;
  cordinates: string;
  duration?: number;
  distance?: number;
  hideCoordinates?: boolean;
  compact?: boolean;
  lowContrast?: boolean;
}

export const RoutePointDisplay = ({
  icon,
  address,
  cordinates,
  duration,
  distance,
  hideCoordinates = false,
  compact = false,
  lowContrast = false
}: RoutePointDisplayProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');

  const formatDuration = (seconds?: number): string => {
    if (seconds === undefined || seconds < 0) return 'N/A';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);

    if (hours > 0) {
      return `${hours}h ${minutes}min`;
    }
    return `${minutes}min`;
  };

  const formatDistance = (meters?: number): string => {
    if (meters === undefined || meters < 0) return 'N/A';

    if (meters >= 1000) {
      return `${(meters / 1000).toFixed(1)}km`;
    }
    return `${Math.round(meters)}m`;
  };

  const copyCoordinates = () => {
    navigator.clipboard.writeText(cordinates).then(
      () => {
        setCopyMessage('Copied!');
        setTimeout(() => setCopyMessage(''), 2000);
      },
      () => {
        setCopyMessage('Failed to copy');
      }
    );
  };

  // Format coordinates for display (less precision)
  const shortCoordinates = (() => {
    const [lat, lng] = cordinates.split(',').map(str => parseFloat(str.trim()));
    if (isNaN(lat) || isNaN(lng)) return '';
    return `${lat.toFixed(3)}, ${lng.toFixed(3)}`;
  })();

  const borderColor = lowContrast ? 'border-gray-100' : 'border-gray-200';
  const hoverBorderColor = lowContrast ? 'hover:border-gray-200' : 'hover:border-gray-300';
  const textColor = lowContrast ? 'text-gray-700' : 'text-gray-900';

  return (
    <div className={`flex items-start space-x-3 ${compact ? 'p-2' : 'p-3'} rounded-lg transition-colors hover:bg-gray-50 border ${borderColor} ${hoverBorderColor}`}>
      {icon}
      <div className="flex-1 min-w-0">
        <div className={`font-medium ${textColor} ${compact ? 'mb-1 text-sm' : 'mb-2'}`}>{address}</div>

        <div className={`flex flex-wrap ${compact ? 'gap-2' : 'gap-4'} text-sm`}>
          {/* Duration info with icon */}
          {duration !== undefined && duration >= 0 && (
            <div className="flex items-center space-x-2">
              <div className={`${compact ? 'w-6 h-6' : 'w-7 h-7'} rounded-full bg-blue-100 flex items-center justify-center border border-blue-200`}>
                <svg className={`${compact ? 'w-3 h-3' : 'w-3.5 h-3.5'} text-blue-700`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <circle cx="12" cy="12" r="10" />
                  <polyline points="12 6 12 12 16 14" />
                </svg>
              </div>
              <span className="font-medium text-gray-800">{formatDuration(duration)}</span>
            </div>
          )}

          {/* Distance info with icon */}
          {distance !== undefined && distance >= 0 && (
            <div className="flex items-center space-x-2">
              <div className={`${compact ? 'w-6 h-6' : 'w-7 h-7'} rounded-full bg-green-100 flex items-center justify-center border border-green-200`}>
                <svg className={`${compact ? 'w-3 h-3' : 'w-3.5 h-3.5'} text-green-700`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M17 7l-10 10M8 7h9v9" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <span className="font-medium text-gray-800">{formatDistance(distance)}</span>
            </div>
          )}

          {/* Location pin with coordinates */}
          {!hideCoordinates && (
            <div className="relative inline-flex items-center">
              <button
                className="flex items-center space-x-2 text-gray-700 hover:text-blue-700 transition-colors"
                onClick={copyCoordinates}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <div className={`${compact ? 'w-6 h-6' : 'w-7 h-7'} rounded-full bg-purple-100 flex items-center justify-center border border-purple-200`}>
                  <svg className={`${compact ? 'w-3 h-3' : 'w-3.5 h-3.5'} text-purple-700`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <span className="font-medium">{shortCoordinates}</span>
              </button>

              {/* Tooltip */}
              {showTooltip && (
                <div className="absolute bottom-full left-0 mb-2 p-2.5 bg-gray-800 text-white text-xs rounded-md shadow-lg whitespace-nowrap z-50">
                  {cordinates}
                  <div className="text-xs opacity-75 mt-1">Click to copy</div>
                  <div className="absolute left-3 bottom-0 transform translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
                </div>
              )}

              {/* Copy notification */}
              {copyMessage && (
                <div className="absolute top-0 right-0 mt-6 p-1.5 bg-green-600 text-white text-xs rounded-md shadow-lg whitespace-nowrap animate-fade-in-out">
                  {copyMessage}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
